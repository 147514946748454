/* eslint-disable import/no-cycle */
// import { sendLogs } from "../services/externeServices";

import config from "config";
import Cookies from "js-cookie";
import routesList from "routes/routesList";

const tokenName = "token-medeo";
// const userToken = "user-medeo";

export const getToken = () => {
  return Cookies.get(tokenName) || null;
};
export const getTokenTest = () => {
  return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IlVTUjE1OTQ4ODh4blRWMTkzMDQxODciLCJpYXQiOjE2NDYwMzkyODAsImV4cCI6MTY1MzgxNTI4MH0.x5ZkXMEKqv7ntyZYmRiv6aK2jMM_J43VB11PV0mCpSs";
};
// export const login = (token, user) => {
// send logs
// sendLogs(null);

// localStorage.setItem(tokenName, token);
// localStorage.setItem(userToken, JSON.stringify(user));
// };

export const logout = () => {
  // send logs
  // sendLogs(getToken());

  // const from = window.location.pathname;
  // document.cookie = `${tokenName}=;domain=${config.app.domain}; path=/`;
  Cookies.remove(tokenName, { path: '/', domain: config.app.domain });
  window.location.replace(routesList.signin);
};

export const isLogin = () => {
  if (Cookies.get(tokenName)) {
    return true;
  }
  return false;
};

// export const getUser = () => {
//   return JSON.parse(localStorage.getItem(userToken)) || null;
// };
