import { Layout, ServerError } from "components";

const InternalServer = ({ path = '/' }) => {
  return (
    <Layout hasContainer name="internal-server">
      <ServerError path={path} />
    </Layout>
  );
};

export default InternalServer;
