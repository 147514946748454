import config from "config";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const EventCover = ({ thumbnailImage, isUpcoming, date }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const openEvents = () => {
    history.push("/events");
  };
  return (
    <div className={`hero ${isUpcoming ? "hero__overlay" : ""}`}>
      <div className="hero-wrapper">
        <img
          src={`${config.app.cdnUrl}/${thumbnailImage}`}
          alt=""
          className="hero__bg"
        />
        {isUpcoming && (
          <div className="container">
            <div className="hero__content">
              <div className="hero__calender">
                <img src="/img/icons/icn_calendar.svg" alt="" />
              </div>
              <h3 className="hero__category">
                {t("this event is coming up on")}
              </h3>
              <h4 className="hero__title">
                {moment(date).format("MMMM DD, YYYY")}
              </h4>
              <button className="btn btn-primary rounded-pill">
                <span className="" onClick={openEvents}>
                  {t("check other events")}
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EventCover;
