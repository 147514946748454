import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const InternetError = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const currentPath = history.location.pathname;
  const currentSearch = history.location.search;
  return (
    <div className="no-result-container">
      <div className="no-results">
        <h3>{t("Connectez-vous à Internet")}</h3>
        <div className="no-results__text">
          <p>{t("Vous n'êtes pas connecté. Vérifiez votre connexion.")}</p>
        </div>
        <div className="no-results__cta">
          <a
            href={currentPath + currentSearch}
            type="submit"
            className="btn btn-primary rounded-pill"
          >
            {t("retry")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default InternetError;
