const config = {
  app: {
    apiUrl: "https://api.medeo-dz.com/api/v3",
    apiUrlV1: "https://api.medeo-dz.com/api/v1",
    cdnUrl:   "https://scale-medeo-content-production.s3.eu-west-1.amazonaws.com",
    liveUrl: 'https://live.medeo.factorydigitale.io/',
 domain: '.medeo-dz.com',
    // domain: ".medeo.factorydigitale.io",
  },
  defaultLanguage: 'en',
 medeoUrl: 'https://www.medeo-dz.com',
//	medeoUrl: 'https://site.medeo.factorydigitale.io',
};

export default config;
