import { useTranslation } from "react-i18next";
const Sort = ({ active, handleSort }) => {
  const { t } = useTranslation();

  return (
    <div className="search-results__sort">
      <div className="dropdown">
        <div
          className="dropdown-toggle"
          id="contentSort"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {t("sort by")}
        </div>
        <div
          className="dropdown-menu dropdown-menu-right"
          aria-labelledby="contentSort"
        >
          <a
            className={`dropdown-item ${
              active === "publishing_date" ? "active" : ""
            }`}
            onClick={(e) => {
              e.preventDefault();
              handleSort("publishing_date");
            }}
            href="/"
          >
            {t("recent")}
          </a>
          <a
            className={`dropdown-item ${active === "nb_views" ? "active" : ""}`}
            onClick={(e) => {
              e.preventDefault();
              handleSort("nb_views");
            }}
            href="/"
          >
            {t("number of views")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default Sort;
