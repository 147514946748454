import { useTranslation } from "react-i18next";

/* eslint-disable jsx-a11y/heading-has-content */
const ShimmerCourseDetailsTab = () => {
  const { t } = useTranslation();
  return (
    <div
      className="shimmer-course-details__description tab-pane fade show active"
      id="description"
    >
      <h1 className="shimmer-course-details__title mb-3"></h1>
      <p className="shimmer-course-details__text-description m-0 mb-2"></p>
      <p className="shimmer-course-details__text-description m-0"></p>
      <div className="shimmer-tags">
        <div className="shimmer-tags__items p-0">
          <span className="shimmer-badges__title me-3">{t("tags")} :</span>
          <div className="shimmer-badge rounded-pill"></div>
          <div className="shimmer-badge rounded-pill"></div>
        </div>
      </div>
    </div>
  );
};

export default ShimmerCourseDetailsTab;
