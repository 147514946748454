import moment from "moment";
import { t } from "i18next";

import i18n from "../i18n";

// exemple : 01:03:15 => 63.25
function parseDurationToMinutes(duration) {
  try {
    const durationInstance = moment.duration(duration);
    return durationInstance.asMinutes();
  } catch {
    return 0;
  }
}

export function parseDurationToSeconds(time) {
  try {
    return moment.duration(time).asSeconds();
  } catch {
    return 0;
  }
}

export function parseDurationToInterval(duration) {
  const minutes = parseDurationToMinutes(duration);
  let interval = "";
  if (minutes < 10) {
    interval = `<10 ${t("minutes")}`;
  } else if (minutes >= 10 && minutes <= 20) {
    interval = `10-20 ${t("minutes")}`;
  } else if (minutes > 20) {
    interval = `>20 ${t("minutes")}`;
  }
  return interval;
}

export function parseNameToInitials(string) {
  if (string) {
    const names = string.split(" ");
    let initials = `${names[0]?.substring(0, 1).toUpperCase()}.`;

    if (names.length > 1) {
      initials += names[names?.length - 1]?.substring(0, 1).toUpperCase();
    }
    return initials;
  }
  return null;
}

export function getNameInitials({ first_name, last_name }) {
  if (first_name) {
    let initials = `${first_name[0]?.toUpperCase()}.`;

    if (last_name?.length > 1) {
      initials += last_name[0]?.toUpperCase();
    }
    return initials;
  }
  return null;
}

export function startWithVowel(str) {
  if (str?.length > 1) {
    return /[aeiouyAEIOUY]/.test(str[0]);
  }
}
export function getTimeFromNow(date) {
  moment.locale(i18n.language);
  const fromNow = moment(date).fromNow();
  return fromNow.charAt(0).toUpperCase() + fromNow.slice(1);
}
