import { Store } from "react-notifications-component";

import parameters from "config/parameters";

const updateUser = (data) => {
  localStorage.setItem("user-medeo", JSON.stringify(data));
};

function displayNotificationToast(message, type) {
  const id = Date.now();

  Store.addNotification({
    id,
    message,
    type,
    isMobile: true,
    insert: "top",
    container: "bottom-right",
    animationIn: ["animate__animated", "animate__fadeIn"],
    animationOut: ["animate__animated", "animate__fadeOut"],
    // animationIn: ["animated", "fadeIn"],
    // animationOut: ["animated", "fadeOut"],
    // content: (
    //   // eslint-disable-next-line react/react-in-jsx-scope
    //   <NotificationToast id={id} message={message} type={type} />
    // ),
    dismiss: {
      duration: parameters.toastNotifDuration,
      onScreen: false,
      pauseOnHover: true,
      waitForAnimation: false,
      showIcon: true,
      click: true,
      touch: true,
    },
  });
}
export { displayNotificationToast, updateUser };
