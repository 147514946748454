import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";

import { ShimmerContentCard, ShimmerEvent } from "components/Shimmers";
import {
  ContentCard,
  FilterBar,
  Layout,
  ShimmerWrapper,
  ServerError,
  InternetError,
  NoResult,
  Tab,
} from "components";
import EventCard from "pages/Home/components/Events/components/EventCard";
import { formatFilter, lengthFilter } from "data";

const Favorites = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { contents, events } = useSelector((state) => state.favorites);

  const { therapArea } = useSelector((state) => state.referential);

  const therapFilter = {
    namespace: "therapArea",
    title: t("therapeutic area"),
    options: therapArea?.data?.map(({ playlist_id, playlist_name }) => ({
      name: playlist_name,
      value: playlist_id,
    })),
  };

  const initialFilterValues = {
    therapArea: null,
    format: null,
    length: null,
  };
  const eventsInitialFilterValues = {
    therapArea: null,
  };
  const tabs = [
    {
      id: "content",
      title: t("educational resources"),
    },
    {
      id: "events",
      title: t("events"),
    },
  ];
  const [eventsFilterValues, SetEventsFilterValues] = useState(
    eventsInitialFilterValues
  );
  const eventsInitialFilter = eventsFilterValues?.therapArea === null;

  const [contentFilterValues, setContentFilterValues] =
    useState(initialFilterValues);

  const contentIsInitialFilter =
    contentFilterValues?.therapArea === null &&
    contentFilterValues?.format === null &&
    contentFilterValues?.length === null;

  const dispatchFavoriteContent = useCallback(
    ({ loadMore }) =>
      dispatch({
        type: "favorites/GET_FAVORITE_CONTENTS",
        payload: {
          format: contentFilterValues?.format,
          duration: contentFilterValues?.length,
          therapeutic: contentFilterValues?.therapArea,
          loadMore,
        },
      }),
    [dispatch, contentFilterValues]
  );

  const dispatchFavoriteEvents = useCallback(
    ({ loadMore }) =>
      dispatch({
        type: "favorites/GET_FAVORITE_EVENTS",
        payload: {
          therapeutic: eventsFilterValues?.therapArea,
          loadMore,
        },
      }),
    [dispatch, eventsFilterValues]
  );

  useEffect(() => {
    dispatchFavoriteContent({ loadMore: false });
  }, [contentFilterValues, dispatchFavoriteContent]);

  useEffect(() => {
    dispatchFavoriteEvents({ loadMore: false });
  }, [eventsFilterValues, dispatchFavoriteEvents]);

  useEffect(() => {
    dispatch({ type: "referential/GET_THERAP_AREA" });
    return () => {
      dispatch({ type: "favorites/RESET" });
    };
  }, [dispatch]);

  const handleFilter = ({ nativeEvent: e }, namespace, value) => {
    e.preventDefault();
    if (!contents?.loading && !events?.loading) {
      if (namespace) {
        if (contentFilterValues[namespace] !== value)
          setContentFilterValues((prevState) => ({
            ...prevState,
            [namespace]: value,
          }));
      } else {
        setContentFilterValues(initialFilterValues);
      }
    }
  };

  const eventsHandleFilter = ({ nativeEvent: e }, namespace, value) => {
    e.preventDefault();
    if (!events?.loading) {
      if (namespace) {
        if (eventsFilterValues[namespace] !== value)
          SetEventsFilterValues((prevState) => ({
            ...prevState,
            [namespace]: value,
          }));
      } else {
        SetEventsFilterValues(eventsInitialFilterValues);
      }
    }
  };

  const contentHasNextPage = contents?.page <= contents?.maxPage;

  const contentHandleLoadMore = () => {
    if (contentHasNextPage) {
      dispatchFavoriteContent({ loadMore: true });
    }
  };

  const eventsHasNextPage = events?.page <= events?.maxPage;

  const eventsHandleLoadMore = () => {
    if (eventsHasNextPage) {
      dispatchFavoriteEvents({ loadMore: true });
    }
  };

  const contentsFilters = [therapFilter, formatFilter, lengthFilter];
  const eventsFilters = [therapFilter];

  return (
    <Layout name="favorites">
      {contents?.internetError ? (
        <InternetError />
      ) : contents?.error ? (
        <ServerError />
      ) : (
        <>
          <Tab
            tabs={tabs}
            tabClass="tab-custom--search"
            TabLayout={TabLayout}
            ChildrenLayout={ChildrenLayout}
          >
            <div
              className="tab-pane fade active show"
              role="tabpanel"
              aria-labelledby="courses-tab"
              id="content"
            >
              {!contents?.loading &&
              !contents?.error &&
              contentIsInitialFilter &&
              contents?.data?.length === 0 ? (
                <>
                  <div className="divider"></div>
                  <NoResult textMessage="no educational resources in your favorites" />
                </>
              ) : (
                <>
                  <FilterBar
                    filters={contentsFilters}
                    handleFilter={handleFilter}
                    filterValues={contentFilterValues}
                    id="contentId"
                  />
                  <div className="divider"></div>
                  <ShimmerWrapper
                    loading={contents.loading}
                    shimmerRender={<ShimmerContentCard />}
                    ShimmerLayout={({ children }) => (
                      <div className="courses-grid">
                        <div className="row">{children}</div>
                      </div>
                    )}
                    large={contents.data.length === 0}
                  >
                    {contents.data.length > 0 ? (
                      <InfiniteScroll
                        dataLength={contents.data.length} //This is important field to render the next data
                        next={contentHandleLoadMore}
                        hasMore={contentHasNextPage}
                        style={{ overflowX: "hidden", overflowY: "hidden" }}
                      >
                        <div className="courses-grid">
                          <div className="row">
                            {contents.data?.map(
                              ({
                                content_id,
                                __t,
                                interval_duration,
                                content_name,
                                description,
                                therapeuticArea,
                                thumbnail,
                                added_to_favorite,
                                percentage_viewed,
                                publishing_date,
                              }) => (
                                <div
                                  key={content_id}
                                  className="col-sm-6 col-lg-4 col-xl-3"
                                >
                                  <ContentCard
                                    content_id={content_id}
                                    isVideo={__t === "VideoAdvent"}
                                    intervalDuration={interval_duration}
                                    name={content_name}
                                    description={description}
                                    therapeuticArea={therapeuticArea}
                                    thumbnail={thumbnail}
                                    isFavorite={added_to_favorite}
                                    percentageViewed={percentage_viewed}
                                    publishing_date={publishing_date}
                                  />
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </InfiniteScroll>
                    ) : (
                      !contents.loading && <NoResult />
                    )}
                  </ShimmerWrapper>
                </>
              )}
            </div>

            <div
              className="tab-pane fade"
              role="tabpanel"
              aria-labelledby="events-tab"
              id="events"
            >
              {!events?.loading &&
              !events?.error &&
              eventsInitialFilter &&
              events?.data?.length === 0 ? (
                <>
                  <div className="divider"></div>
                  <NoResult textMessage="no events in your favorites" />
                </>
              ) : (
                <>
                  {!(
                    events.loading &&
                    !events.error &&
                    eventsInitialFilter &&
                    events.data.length === 0
                  ) && (
                    <>
                      <FilterBar
                        filters={eventsFilters}
                        handleFilter={eventsHandleFilter}
                        filterValues={eventsFilterValues}
                        id="eventId"
                      />
                    </>
                  )}
                  <div className="divider"></div>
                  <ShimmerWrapper
                    loading={events?.loading}
                    shimmerRender={<ShimmerEvent type="vertical" />}
                    ShimmerLayout={({ children }) => (
                      <div className="courses-grid">
                        <div className="row">{children}</div>
                      </div>
                    )}
                    large={events?.data?.length === 0}
                  >
                    {events?.data?.length > 0 ? (
                      <InfiniteScroll
                        dataLength={events?.data?.length} //This is important field to render the next data
                        next={eventsHandleLoadMore}
                        hasMore={eventsHasNextPage}
                        style={{ overflowX: "hidden", overflowY: "hidden" }}
                      >
                        <div className="courses-grid">
                          <div className="row">
                            {events?.data?.map(
                              ({
                                thumbnail_image,
                                start_date,
                                end_date,
                                playlist_name,
                                playlist_id,
                                therapeutic_area,
                                description,
                                status,
                                has_live,
                                live,
                                added_to_favorite,
                              }) => (
                                <div
                                  key={playlist_id}
                                  className="col-sm-6 col-lg-4 col-xl-3"
                                >
                                  <EventCard
                                    id={playlist_id}
                                    thumbnailImage={thumbnail_image}
                                    date={start_date}
                                    endDate={end_date}
                                    title={playlist_name}
                                    therapArea={therapeutic_area?.playlist_name}
                                    description={description}
                                    isUpcoming={status === 1}
                                    hasLive={has_live}
                                    inProgress={status === 0}
                                    nbViews={live?.nb_watchers}
                                    displayType="vertical"
                                    isFavorite={added_to_favorite}
                                  />
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </InfiniteScroll>
                    ) : (
                      !events?.loading && <NoResult />
                    )}
                  </ShimmerWrapper>
                </>
              )}
            </div>
          </Tab>
        </>
      )}
    </Layout>
  );
};

const TabLayout = ({ children }) => {
  return (
    <div className="tab tab--search">
      <div className="container">
        <div className="row">
          <div className="col-xl-10">
            <div className="tab__inner">{children}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ChildrenLayout = ({ children }) => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <h1 className="page-title">{t("my favorites")}</h1>
      {children}
    </div>
  );
};

export default Favorites;
