import { useTranslation } from "react-i18next";

const NoResult = ({ searchText, textMessage }) => {
  const { t } = useTranslation();
  return (
    <div className="no-result-container">
      <div className="no-results">
        <div className="no-results__img-container">
          <img
            src={require("assets/img/icons/icn_no_result.svg").default}
            alt="No results"
          />
        </div>
        <div className="no-results__text">
          {searchText ? (
            <>
              <p className="mb-1">
                {t("no results for")}{" "}
                <span className="fw-bold">{searchText}</span>
              </p>
              <p>
                {t("check for spelling mistakes or look for something else")}
              </p>
            </>
          ) : (
            <p className="mb-1">
              {t(textMessage)}
              <span className="fw-bold">{searchText}</span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

NoResult.defaultProps = {
  textMessage: "data not found",
};

export default NoResult;
