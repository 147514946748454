/* eslint-disable jsx-a11y/heading-has-content */
const ShimmerEvents = () => {
  return (
    <>
      <div className='event-card event-card--premium shimmer-event-card shimmer-event-card--premium'>
        <div className="shimmer-event-card__thumb shimmer-event-card__thumb--premium"></div>
        <div className="shimmer-event-card__body--premium">
          <div className="shimmer-event-card__date"></div>
          <h2 className="shimmer-event-card__title"></h2>
          <p className="shimmer-event-card__description"></p>
          <p className="shimmer-event-card__description"></p>
          <div className="shimmer-event-card__badges">
            <div className="shimmer-badge rounded-pill"></div>
          </div>
        </div>
      </div>
      <div className="events-list events-list--column">
        <div className='event-card shimmer-event-card'>
          <div className="shimmer-event-card__thumb"></div>
          <div className="event-card__body">
            <div className="shimmer-event-card__date"></div>
            <h2 className="shimmer-event-card__title"></h2>
            <p className="shimmer-event-card__description"></p>
            <p className="shimmer-event-card__description"></p>
            <div className="shimmer-event-card__badges">
              <div className="shimmer-badge rounded-pill"></div>
            </div>
          </div>
        </div>
        <div className='event-card shimmer-event-card'>
          <div className="shimmer-event-card__thumb"></div>
          <div className="event-card__body">
            <div className="shimmer-event-card__date"></div>
            <h2 className="shimmer-event-card__title"></h2>
            <p className="shimmer-event-card__description"></p>
            <p className="shimmer-event-card__description"></p>
            <div className="shimmer-event-card__badges">
              <div className="shimmer-badge rounded-pill"></div>
            </div>
          </div>
        </div>
        <div className='event-card shimmer-event-card'>
          <div className="shimmer-event-card__thumb"></div>
          <div className="event-card__body">
            <div className="shimmer-event-card__date"></div>
            <h2 className="shimmer-event-card__title"></h2>
            <p className="shimmer-event-card__description"></p>
            <p className="shimmer-event-card__description"></p>
            <div className="shimmer-event-card__badges">
              <div className="shimmer-badge rounded-pill"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShimmerEvents;