const ShimmerVideoActions = () => {
  return (
    <div className="video-actions">
      <div className="shimmer-video-actions__item"></div>
      <div className="shimmer-video-actions__item"></div>
      {/* <div className="shimmer-video-actions__item"></div> */}
    </div>
  );
};

export default ShimmerVideoActions;
