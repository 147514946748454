import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";

import {
  ContentCard,
  FilterBar,
  Layout,
  ShimmerWrapper,
  ServerError,
  NoResult,
  InternetError,
} from "components";
import { ShimmerContentCard } from "components/Shimmers";

import { formatFilter } from "data";
import { parseDurationToSeconds } from "utils/functions";
import { sources } from "data/eventsLog";

const Courses = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { courses } = useSelector((state) => state.courses);
  const { data, loading, page, maxPage, error, internetError } = courses;
  const { therapArea } = useSelector((state) => state.referential);

  const filters = [
    {
      namespace: "therapArea",
      title: t("therapeutic area"),
      options: therapArea?.data?.map(({ playlist_id, playlist_name }) => ({
        name: playlist_name,
        value: playlist_id,
      })),
    },
    formatFilter,
  ];
  const initialFilterValues = {
    therapArea: null,
    format: null,
  };

  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const isInitialFilter =
    filterValues?.therapArea === null && filterValues?.format === null;
  const dispatchCourses = useCallback(
    ({ loadMore }) =>
      dispatch({
        type: "courses/GET_COURSES",
        payload: {
          type: filterValues?.format,
          therapeutic: filterValues?.therapArea,
          loadMore,
        },
      }),
    [dispatch, filterValues]
  );

  useEffect(() => {
    dispatchCourses({ loadMore: false });
  }, [filterValues, dispatchCourses]);

  useEffect(() => {
    dispatch({ type: "referential/GET_THERAP_AREA" });
    return () => {
      dispatch({ type: "courses/RESET" });
    };
  }, [dispatch]);

  const handleFilter = ({ nativeEvent: e }, namespace, value) => {
    e.preventDefault();
    if (!loading) {
      if (namespace) {
        if (filterValues[namespace] !== value)
          setFilterValues((prevState) => ({
            ...prevState,
            [namespace]: value,
          }));
      } else {
        setFilterValues(initialFilterValues);
      }
    }
  };

  const hasNextPage = page <= maxPage;
  const handleLoadMore = () => {
    if (hasNextPage) {
      dispatchCourses({ loadMore: true });
    }
  };

  return (
    <Layout name="courses" hasContainer>
      {internetError ? (
        <InternetError />
      ) : error ? (
        <ServerError />
      ) : (
        <>
          <h1 className="page-title">{t("educational ressources")}</h1>
          {!loading && !error && isInitialFilter && data?.length === 0 ? (
            <>
              <div className="divider"></div>
              <NoResult />
            </>
          ) : (
            <>
              {!(
                loading &&
                !error &&
                isInitialFilter &&
                data?.length === 0
              ) && (
                <FilterBar
                  filters={filters}
                  handleFilter={handleFilter}
                  filterValues={filterValues}
                />
              )}
              <div className="divider"></div>
              <ShimmerWrapper
                loading={loading}
                shimmerRender={<ShimmerContentCard />}
                ShimmerLayout={({ children }) => (
                  <div className="courses-grid">
                    <div className="row">{children}</div>
                  </div>
                )}
                large={data?.length === 0}
              >
                {data?.length > 0 ? (
                  <InfiniteScroll
                    dataLength={data?.length} //This is important field to render the next data
                    next={handleLoadMore}
                    hasMore={hasNextPage}
                    style={{ overflowX: "hidden", overflowY: "hidden" }}
                  >
                    <div className="courses-grid">
                      <div className="row">
                        {data?.map(
                          ({
                            content_id,
                            __t,
                            interval_duration,
                            content_name,
                            description,
                            therapeuticArea,
                            thumbnail,
                            percentage_viewed,
                            added_to_favorite,
                            pages_number,
                            channel,
                            tags,
                            nb_views,
                            speciality,
                            duration,
                            promomat,
                            publishing_date,
                          }) => {
                            const infoContent = {
                              speciality: speciality?.name,
                              tags: tags,
                              nbViews: nb_views,
                              channelName: channel?.playlist_name,
                              areaName: therapeuticArea?.playlist_name,
                              percentageViewed: parseInt(
                                percentage_viewed || 0
                              ),
                              promomat,
                            };
                            const isVideo = __t === "VideoAdvent";
                            if (isVideo) {
                              infoContent.duration =
                                parseDurationToSeconds(duration);
                            } else {
                              infoContent.pagesNumber = pages_number;
                            }
                            return (
                              <div
                                key={content_id}
                                className="col-sm-6 col-lg-4 col-xl-3"
                              >
                                <ContentCard
                                  content_id={content_id}
                                  isVideo={isVideo}
                                  intervalDuration={interval_duration}
                                  name={content_name}
                                  description={description}
                                  therapeuticArea={therapeuticArea}
                                  thumbnail={thumbnail}
                                  isFavorite={added_to_favorite}
                                  percentageViewed={percentage_viewed}
                                  infoContent={infoContent}
                                  source={sources.coursesPage}
                                  playlistId={therapeuticArea?.playlist_id}
                                  publishing_date={publishing_date}
                                />
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  </InfiniteScroll>
                ) : (
                  !loading && <NoResult />
                )}
              </ShimmerWrapper>
            </>
          )}
        </>
      )}
    </Layout>
  );
};

export default Courses;
