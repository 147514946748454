const common = {
  filters: "Filters",
  "filter by": "Filter by",
  "therapeutic area": "Therapeutic Area",
  format: "Format",
  "educational ressources": "Educational ressources",
  type: "Type",
  events: "Events",
  "clear all filters": "Clear all filters",
  "add to favorites": "Add to favorites",
  "remove from my favorites": "Remove from my favorites",
  saved: "Saved",
  video: "Video",
  pdf: "PDF",
  minutes: "minutes",
  "my favorites": "My Favorites",
  "consult content": "Consult {{name}}’s content",
  page: "Page",
  "see more scientific courses": "See more scientific courses",
  "expert opinion": "Expert Opinion",
  description: "Description",
  "supporting material": "Supporting material",
  tags: "Tags",
  courses: "Educational Resources",
  "course added to favorites with success": "Content added to your favorites",
  "course removed from favorites with success":
    "Content removed from your favorites",
  "event added to favorites with success": "Event added to your favorites",
  "event removed from favorites with success":
    "Event removed from your favorites",
  "error message: operation failed": "Operation Failed",
  "therapeutic areas": "Therapeutic areas",
  search: "search",
  "no results for ": "No results for ",
  "check for spelling mistakes or look for something else.":
    "Check for spelling mistakes or look for something else.",
  "sort by": "Sort by",
  recent: "Recent",
  "number of views": "Number of Views",
  upcoming: "Upcoming",
  "return to home": "Return to home",
  retry: "Retry",
  "no results for": "No results for",
  "check for spelling mistakes or look for something else":
    "Check for spelling mistakes or look for something else.",
  "data not found": "Data not found",
  "this therapeutic area does not exist":
    "This therapeutic area does not exist",
  "this expert profile does not exist": "This expert profile does not exist",
  "this course does not exist": "This course does not exist",
  "no content available": "No content available",
  started: "Started educational resources",
  "discover our latest courses": "Discover our latest educational resources",
  "hear from members of the ADVENT Global expert panel and faculty":
    "Hear from members of the ADVENT Global Expert Panel and Faculty",
  "past and future ADVENT meetings and events":
    "Past and future ADVENT meetings and events",
  "upcoming event": "Upcoming event",
  "educational resources": "Educational Resources",
  "no educational resources in your favorites":
    "No educational resources in your favorites",
  "no events in your favorites": "No events in your favorites",
  "dr - ": "Dr - ",
  live: "Live",
  "ongoing events": "Ongoing events",
  "upcoming events": "Upcoming events",
  "passed events": "Passed events",
  "discover advent program": "Discover Advent program",
  "advent program 1": `<span className="bold-text">
    ADVENT is a medical education program
    </span>
    designed to advance the understanding of
    <span className="bold-text">Type 2 inflammation.</span>`,
  "advent program 2": `<span className="bold-text">
    ADVENT is developed in collaboration with over 50 global
    experts
    </span>
    and links the latest
    <span className="bold-text">
    science of Type 2 inflammation
    </span>
    with clinical management to improve patient care.`,
  "this event is coming up on": "This event is coming up on",
  "check other events": "Check other events",
  "related contents": "Related contents",
  biography: "Biography",
  "view the rest of this content": "View the rest of this content",
  "shares strategies for ": "shares strategies for ",
  "watch video": "Watch video",
  "vous êtes sur le point de quitter la platforme éducative advent vers medeo une plateforme promotionnelle.":
    "You are leaving Advent, an educational platform, for Medeo, a promotional platform.",
  ok: "Ok",
  pr: "Pr",
  "Nouveau mot de passe": "New password",
  "Mise à jour réussie.": "Update successful.",
  private: "Private",
  Titre: "Title",
  public: "Public",
  Mme: "Mrs",
  Mr: "Mr",
  Pr: "Pr",
  Dr: "Dr",
  Prénom: "First name",
  Nom: "Last name",
  Specialité: "Specialty",
  Secteur: "Sector",
  Wilaya: "Region",
  Mobile: "Mobile",
  Modifier: "Modify",
  "go back": "Go back",
  category: "{{category}} category",
};

export default common;
