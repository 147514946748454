import FilterItem from "./FilterItem";

const Filter = ({
  handleFilter,
  handleFilterClear,
  title,
  namespace,
  value,
  options,
  id,
}) => {
  const selected = options.find((option) => option.value === value);
  return (
    <div
      className={`dropdown filter-item ${
        value && namespace !== "type" ? "filter-item--active" : ""
      }`}
    >
      <button
        className="btn dropdown-toggle"
        type="button"
        id={id}
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {selected?.name || title}
        <div
          className="filter-item__icon"
          onClick={(e) => {
            if (value) handleFilterClear(e, namespace);
          }}
        ></div>
      </button>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li>
          <a className="dropdown-item disabled" href="/">
            {title}
          </a>
        </li>
        {options?.map(({ name, value: optionValue }) => (
          <FilterItem
            key={optionValue}
            handleFilter={handleFilter}
            namespace={namespace}
            name={name}
            value={optionValue}
            isActive={value === optionValue}
          />
        ))}
      </ul>
    </div>
  );
};

export default Filter;
