import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useCallback } from "react";
import { Layout, FilterBar, ServerError, InternetError } from "components";
import { Section } from "./components";

const Events = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const events = useSelector((state) => state.events);
  const { ongoingEvents, upcomingEvents, passedEvents, internetError } = events;
  const { therapArea } = useSelector((state) => state.referential);
  const globalError =
    ongoingEvents?.error && upcomingEvents?.error && passedEvents?.error;
  const initialFilterValues = {
    therapArea: null,
  };
  const [filterValues, setFilterValues] = useState(initialFilterValues);

  const isInitialFilter = filterValues?.therapArea === null;
  const therapAreaFilter = {
    namespace: "therapArea",
    title: t("therapeutic area"),
    options: therapArea?.data?.map(({ playlist_id, playlist_name }) => ({
      name: playlist_name,
      value: playlist_id,
    })),
  };

  const dispatchOngoingEvents = useCallback(() => {
    dispatch({ type: "events/GET_ONGOING_EVENTS" });
  }, [dispatch]);
  const dispatchUpcomingEvents = useCallback(() => {
    dispatch({ type: "events/GET_UPCOMING_EVENTS" });
  }, [dispatch]);
  const dispatchPassedEvents = useCallback(
    ({ loadMore }) => {
      dispatch({
        type: "events/GET_PASSED_EVENTS",
        payload: {
          loadMore,
          therapeutic: filterValues?.therapArea,
        },
      });
    },
    [dispatch, filterValues]
  );
  useEffect(() => {
    dispatchOngoingEvents();
    dispatchUpcomingEvents();
  }, [dispatchOngoingEvents, dispatchUpcomingEvents]);

  useEffect(() => {
    dispatchPassedEvents({ loadMore: false });
  }, [filterValues, dispatchPassedEvents]);

  useEffect(() => {
    dispatch({ type: "referential/GET_THERAP_AREA" });
    return () => {
      dispatch({ type: "events/RESET" });
    };
  }, [dispatch]);
  const filters = [therapAreaFilter];

  const handleFilter = ({ nativeEvent: e }, namespace, value) => {
    e.preventDefault();
    if (namespace) {
      if (filterValues[namespace] !== value)
        setFilterValues((prevState) => ({ ...prevState, [namespace]: value }));
    } else {
      setFilterValues({
        therapArea: "",
        format: "",
      });
    }
  };

  const hasNextPage = passedEvents?.page <= passedEvents?.maxPage;
  const handleLoadMore = () => {
    if (hasNextPage) {
      dispatchPassedEvents({ loadMore: true });
    }
  };
  const PassedEventsFilters = () =>
    ((!passedEvents?.loading && passedEvents?.data?.length > 0) ||
      (Object.values(filterValues).some((value) => value !== null) &&
        passedEvents?.data?.length === 0)) && (
      <div className="row">
        <div className="col-xl-10">
          <FilterBar
            filters={filters}
            filterValues={filterValues}
            handleFilter={handleFilter}
          />
        </div>
      </div>
    );
  return (
    <Layout name="events">
      {internetError ? (
        <InternetError />
      ) : globalError ? (
        <ServerError />
      ) : (
        <>
          <Section
            id="ongoing-events"
            title={t("ongoing events")}
            events={ongoingEvents}
            displayType="vertical"
          />
          <Section
            id="upcoming-events"
            title={t("upcoming events")}
            events={upcomingEvents}
            displayType="banner"
          />
          <Section
            id="passed-events"
            title={t("passed events")}
            events={passedEvents}
            displayType="vertical"
            filters={<PassedEventsFilters />}
            isInitialFilter={isInitialFilter}
            hasNextPage={hasNextPage}
            handleLoadMore={handleLoadMore}
          />
        </>
      )}
    </Layout>
  );
};

export default Events;
