/* eslint-disable import/no-anonymous-default-export */
import test from "./test";
import common from "./common";
import settings from "./settings";
import errors from "./errors";

export default {
  ...test,
  ...common,
  ...settings,
  ...errors,
};
