const ShimmerDetailContentCard = () => {
  return (
    <div className="shimmer-video">
      <div className="shimmer-video__thumbnail"></div>
      <div className="shimmer-video__title"></div>
      <div className="shimmer-video__title"></div>
      <div className="shimmer-video__doctor-name"></div>
    </div>
  );
};

export default ShimmerDetailContentCard;
