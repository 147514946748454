import config from "../config";

const { medeoUrl } = config;

const routesList = {
  home: "/",
  signin: `${medeoUrl}/signin`,
  courses: "/courses",
  events: "/events",
  expertsPanel: "/panel",
  expertPanelDetails: "/panel/expert",
  favorites: "/favorites",
  settings: `/settings`,
  medeo: `${medeoUrl}`,
  expertProfile: "/expert",
  search: "/search",
  therapArea: "/therap",
  internalServer: "/500",
  notFound: "/404",
  privacyPolicy: "/privacy",
  termsOfUse: "/terms",
  contact: "/contact",
};

export default routesList;
