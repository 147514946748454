import { ShimmerWrapper, NoResult, Swiper } from "components";
import EventCard from "pages/Home/components/Events/components/EventCard";
import InfiniteScroll from "react-infinite-scroll-component";
import { ShimmerEvent } from "components/Shimmers";

const Section = ({
  id,
  title,
  events,
  displayType,
  filters,
  hasNextPage = false,
  handleLoadMore,
  isInitialFilter,
}) => {
  // const getShimmer=(id)=>{
  //   switch (id) {
  //     case "":
  //       return (

  //       );
  // }
  if (
    (isInitialFilter || id !== "passed-events") &&
    !events?.loading &&
    !events?.error &&
    events?.data?.length === 0
  ) {
    return <></>;
  }

  
  return (
    <section id={id} className="section section--courses-row">
      <div className="container">
        <h2 className="section__title">
          {title || id?.charAt(0).toUpperCase() + id?.slice(1)}
        </h2>
        {filters}

        <ShimmerWrapper
          loading={events?.loading || events?.error}
          shimmerRender={<ShimmerEvent type={displayType} />}
          ShimmerLayout={({ children }) => (
            <div
              id={`${id === "upcoming-events" && "upcoming-events-swiper"}`}
              className={`courses-grid ${id === "ongoing-events"
                  ? "courses-row"
                  : id === "upcoming-events"
                    ? "upcoming-events__inner"
                    : ""
                }`}
            >
              <div
                className={`${(id === "ongoing-events" || id === "passed-events") && "row"
                  }`}
              >
                {children}
              </div>
            </div>
          )}
          unique={displayType === "banner"}
          large={id === "passed-events" && events?.data?.length === 0}
        >
          <Container id={id} events={events} handleLoadMore={handleLoadMore} hasNextPage={hasNextPage}>
            {events?.data.map(
              ({
                thumbnail_image,
                start_date,
                end_date,
                playlist_name,
                playlist_id,
                therapeutic_area,
                description,
                status,
                has_live,
                live,
                added_to_favorite,
              }) => (
                <EventCard
                  key={playlist_id}
                  id={playlist_id}
                  thumbnailImage={thumbnail_image}
                  date={start_date}
                  endDate={end_date}
                  title={playlist_name}
                  therapArea={therapeutic_area?.playlist_name}
                  description={description}
                  isUpcoming={status === 1}
                  hasLive={has_live}
                  inProgress={status === 0}
                  nbViews={live?.nb_watchers}
                  displayType={displayType}
                  isFavorite={added_to_favorite}
                />
              )
            )}
          </Container>
        </ShimmerWrapper>
      </div>
    </section>
  );
};

const Container = ({ id, children, events, handleLoadMore, hasNextPage }) => {
  if (id === "passed-events") {
    return events?.data?.length > 0 ? (
      <InfiniteScroll
        dataLength={events?.data?.length}
        next={handleLoadMore}
        hasMore={hasNextPage}
        style={{ overflowX: "hidden", overflowY: "hidden" }}
      >
        <div className="courses-grid">
          <div className="row">
            {children.map((child, i) => (
              <div className="col-sm-6 col-lg-4 col-xl-3" key={i}>
                {child}
              </div>
            ))}
          </div>
        </div>
      </InfiniteScroll>
    ) : (
      !events?.loading && !events?.error && <NoResult />
    );
  }
  return !events?.loading && (
    <div className="courses-row">
      <div className="courses-row__inner">
        <Swiper>{children}</Swiper>
      </div>
    </div>
  );
};

export default Section;
