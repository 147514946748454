/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import FullScreenReader from "./FullScreenReader";
import { actionNames } from "data/eventsLog";
import { addContentLogs } from "utils/logs";
import config from "config";
import Loader from "components/Loader";
import GoBackButton from "./GoBackButton";

const PdfReader = ({ content, source }) => {
  const pertentage = parseInt(content.percentage_viewed ?? 0);
  const currentPage =
    pertentage < 100
      ? Math.round(
          (parseInt(content.percentage_viewed ?? 0) * content.pages_number) /
            100
        )
      : 0;
  const [fullScreen, setFullScreen] = useState(false);
  const history = useHistory();
  const { from, sourcePage } = history.location.state ?? { from: "/" };

  const [hasRead, setHasRead] = useState(false);
  const [events, setEvents] = useState({
    started: {
      sent: false,
    },
    viewd: {
      data: null,
    },
    finished: {
      sent: false,
    },
  });
  const viewdContent = useRef(false);
  const [pdf, setPdf] = useState({
    loading: true,
    pdfDoc: null,
    pageNum: currentPage || 1,
    pageCount: null,
    pageRendering: false,
    pageNumPending: null,
  });

  const url = `${config.app.cdnUrl}/${content.pdf_path}`;
  const {
    percentage_viewed,
    content_id: contentId,
    content_name: name,
  } = content;

  const renderPage = (num) => {
    const canvas = document.getElementById("the-canvas");
    const ctx = canvas.getContext("2d");
    setPdf((prevState) => ({
      ...prevState,
      pageRendering: true,
    }));

    pdf.pdfDoc.getPage(num).then(function (page) {
      const viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      };
      const renderTask = page.render(renderContext);

      renderTask.promise.then(function () {
        let pagePending =
          pdf.pageNumPending == null
            ? {}
            : {
                pageNumPending: null,
                pageNum: pdf.pageNumPending,
              };
        setPdf((prevState) => ({
          ...prevState,
          ...pagePending,
          pageRendering: false,
        }));
      });
    });
  };

  const loadPdfDoc = () => {
    const pdfjsLib = window["pdfjs-dist/build/pdf"];
    pdfjsLib.GlobalWorkerOptions.workerSrc =
      "//mozilla.github.io/pdf.js/legacy/build/pdf.worker.js";

    pdfjsLib.getDocument(url).promise.then(function (pdfDoc_) {
      setPdf((prevState) => ({
        ...prevState,
        loading: false,
        pdfDoc: pdfDoc_,
      }));
    });
  };

  useEffect(() => {
    loadPdfDoc();
    return () => (viewdContent.current = true);
  }, []);

  useEffect(() => {
    if (pdf.pdfDoc !== null && !fullScreen) {
      renderPage(pdf.pageNum);
      if (pdf.pageNum === 2 || pdf.pdfDoc.numPages === 1) {
        onStartReading();
      }
    }
    if (pdf.pageNum > 1 && !hasRead) {
      setHasRead(true);
    }
    if (pdf.pdfDoc && pdf.pageNum === pdf.pdfDoc.numPages) {
      if (pdf.pdfDoc.numPages === 1) {
        setTimeout(() => {
          onFinishedReading();
        }, 3000);
      } else {
        onFinishedReading();
      }
    }
  }, [pdf.pageNum, pdf.pdfDoc, fullScreen]);

  useEffect(() => {
    if (pdf.pdfDoc) {
      let percentageViewedInt = parseInt(percentage_viewed || 0);
      if (percentageViewedInt === 100) {
        percentageViewedInt = 0;
      }
      const pourcentage = (pdf.pageNum / pdf.pdfDoc.numPages) * 100;

      if (hasRead) {
        const infosContent = {
          speciality: content.speciality?.name,
          tags: content.tags,
          nbViews: content.nb_views,
          channelName: content.channel?.playlist_name,
          areaName: content.therapeuticArea?.playlist_name,
          pagesNumber: pdf.pdfDoc.numPages,
          from: from ?? sourcePage,
          promomat: content.promomat,
        };

        if (pourcentage >= percentageViewedInt) {
          setEvents((prevState) => ({
            ...prevState,
            viewd: {
              sent: false,
              data: {
                time: Number(pourcentage)?.toFixed(0) || -1,
                timeStarted: percentageViewedInt || -1,
                infosContent,
              },
            },
          }));
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasRead, pdf.pageNum]);

  useEffect(() => {
    return () => {
      if (events.viewd.data !== null && viewdContent.current) {
        addContentLogs(
          contentId,
          name,
          source,
          actionNames.content.contentViewed,
          events.viewd.data.time,
          events.viewd.data.timeStarted,
          events.viewd.data.infosContent
        );
      }
    };
  }, [events.viewd.data]);

  const queueRenderPage = (num) => {
    if (pdf.pageRendering) {
      setPdf((prevState) => ({
        ...prevState,
        pageNumPending: num,
      }));
    } else {
      setPdf((prevState) => ({
        ...prevState,
        pageNum: num,
      }));
    }
  };

  const onPrevPage = () => {
    if (pdf.pageNum <= 1) {
      return;
    }
    const pageNum = pdf.pageNum - 1;
    queueRenderPage(pageNum);
  };

  const onNextPage = () => {
    if (pdf.pageNum >= pdf.pdfDoc.numPages) {
      return;
    }
    const pageNum = pdf.pageNum + 1;
    queueRenderPage(pageNum);
  };

  const openFullScreen = () => {
    setFullScreen(true);
  };

  const closeFullScreen = () => {
    setFullScreen(false);
  };

  const onFullscreenPageChange = (num) => {
    queueRenderPage(num);
  };

  const onFinishedReading = () => {
    if (!events.finished.sent) {
      const infosContent = {
        speciality: content.speciality?.name,
        tags: content.tags,
        nbViews: content.nb_views,
        channelName: content.channel?.playlist_name,
        areaName: content.therapeuticArea?.playlist_name,
        pagesNumber: pdf.pdfDoc.numPages,
        from: from ?? sourcePage,
        promomat: content.promomat,
      };
      // save in logs
      addContentLogs(
        contentId,
        name,
        source,
        actionNames.content.contentFinished,
        100,
        0,
        infosContent
      );
      setEvents((prevState) => ({
        ...prevState,
        finished: {
          sent: true,
        },
      }));
    }
  };

  const onStartReading = () => {
    if (!events.started.sent) {
      setHasRead(true);
      const percentageViewedInt = parseInt(content.percentage_viewed || 0);
      const pourcentage = (pdf.pageNum / pdf.pdfDoc.numPages) * 100;
      const infosContent = {
        speciality: content.speciality?.name,
        tags: content.tags,
        nbViews: content.nb_views,
        channelName: content.channel?.playlist_name,
        areaName: content.therapeuticArea?.playlist_name,
        pagesNumber: pdf.pdfDoc.numPages,
        from: from ?? sourcePage,
        promomat: content.promomat,
      };
      // save in logs
      addContentLogs(
        contentId,
        name,
        source,
        actionNames.content.contentStarted,
        pourcentage >= percentageViewedInt
          ? Number(pourcentage).toFixed(0) || 0
          : percentageViewedInt,
        0,
        infosContent
      );
      setEvents((prevState) => ({
        ...prevState,
        started: {
          sent: true,
        },
      }));
    }
  };
  const closePage = () => {
    history.push(sourcePage ?? "/");
  };
  return (
    <div className="pdf-player">
      <GoBackButton closePage={closePage} />
      {pdf.loading ? (
        <Loader />
      ) : fullScreen ? (
        <FullScreenReader
          pdfDoc={pdf.pdfDoc}
          handleClose={closeFullScreen}
          currentPage={pdf.pageNum}
          onPageChange={onFullscreenPageChange}
          onStartReading={onStartReading}
          alreadyStarted={events.started.sent}
        />
      ) : (
        <>
          <div className="pdf-player__container">
            {pdf.pdfDoc?.numPages > 1 && (
              <button
                className="pdf-player__arrow pdf-player__arrow--prev info"
                disabled={pdf.pageNum === 1}
                onClick={onPrevPage}
              >
                <img
                  src={
                    require("./../../assets/img/icons/icn_chevron_left.svg")
                      .default
                  }
                  alt="Go to previous page"
                />
                <span className="info-wrapper">Previous page</span>
              </button>
            )}
            <div className="pdf-player__canvas-container">
              <canvas id="the-canvas"></canvas>
            </div>
            {pdf.pdfDoc?.numPages > 1 && (
              <button
                className="pdf-player__arrow pdf-player__arrow--next info"
                disabled={pdf.pageNum === pdf.pdfDoc?.numPages}
                onClick={onNextPage}
                id="next"
              >
                <img
                  src={
                    require("./../../assets/img/icons/icn_chevron_right.svg")
                      .default
                  }
                  alt="Go to next page"
                />
                <span className="info-wrapper">Next page</span>
              </button>
            )}
          </div>
          <div className="pdf-player__pagination">
            <span className="pdf-player__pages-nbr">
              Page {pdf.pageNum}/{pdf.pdfDoc?.numPages}{" "}
            </span>
            <button
              className=" info pdf-player__extend-btn"
              onClick={openFullScreen}
            >
              <img
                src={require("./../../assets/img/icons/icn_extend.svg").default}
                alt="Extend"
              />
              <span className="info-wrapper">Full scren view</span>
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default PdfReader;
