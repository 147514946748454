import { Route } from "react-router-dom";

import { isLogin } from "utils/login";
import routesList from "routes/routesList";

const AuthLayout = ({ children }) => {
  const logged = isLogin();
  const from = window.location.pathname;

  if (!logged) {
    return (
      <Route
        render={() =>
          (window.location = `${routesList.signin}${
            from !== "/" ? "?redirect=" + from : ""
          }`)
        }
      />
    );
  }
  return children;
};

export default AuthLayout;
