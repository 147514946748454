import config from "config";
import { parseNameToInitials } from "utils/functions";

const NavigationItem = ({ testimonialThumbnail, name }) => {
  const initials = parseNameToInitials(name);

  return (
    <div className="swiper-slide">
      <div className="expert-item ">
        <div className="expert-item__img-wrapper">
          <div className="expert-item__img">
            {testimonialThumbnail ? (
              <img
                src={`${config.app.cdnUrl}/${testimonialThumbnail?.file_path}`}
                alt=""
              />
            ) : (
              <span>{initials}</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const Navigation = ({ data }) => {
  return (
    <div className="testimonials-navigation">
      <div id="experts-carousel" className="swiper">
        <div className="swiper-wrapper">
          {data?.map(({ testimonial_thumbnail, doctor }, index) => (
            <NavigationItem
              key={index}
              testimonialThumbnail={testimonial_thumbnail}
              name={doctor?.first_name + " " + doctor?.last_name}
            />
          ))}
        </div>
      </div>
      <div className="swiper-button-next"></div>
      <div className="swiper-button-prev"></div>
    </div>
  );
};

export default Navigation;
