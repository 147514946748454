export const actionNames = {
  content: {
    contentStarted: "content_started",
    contentViewed: "content_viewed",
    contentFinished: "content_finished",
    contentFaved: "content_faved",
  },
};

export const sources = {
  homePage: "home",
  coursesPage: "courses",
  userFavorites: "user_favorites",
  singleContent: "single_content",
  singleEvent: "single_event",
  expertProfile: "expert_profile",
  therapeuticArea: "therapeutic_area",
  search: "search",
};
