import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";

import {
  ContentCard,
  Layout,
  ShimmerWrapper,
  ServerError,
  InternetError,
  NoResult,
} from "components";
import { ShimmerContentCard, ShimmerAreaCover } from "components/Shimmers";
import AreaCover from "./components/AreaCover";
import { sources } from "data/eventsLog";
import { parseDurationToSeconds } from "utils/functions";

const TherapArea = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { therapAreaContents, therapAreaDetail, internetError } = useSelector(
    (state) => state.therap
  );
  const { id } = useParams();

  const dispatchTherapAreaContents = useCallback(
    ({ loadMore }) => {
      dispatch({
        type: "therap/GET_THERAP_AREA_CONTENTS",
        payload: {
          id,
          loadMore,
        },
      });
    },
    [dispatch, id]
  );

  const dispatchTherapAreaDetail = useCallback(() => {
    dispatch({ type: "therap/GET_THERAP_AREA_DETAIL", payload: { id } });
  }, [dispatch, id]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, [400]);
    dispatchTherapAreaContents({ loadMore: false });
    dispatchTherapAreaDetail();
    return () => {
      dispatch({ type: "therap/RESET" });
    };
  }, [dispatchTherapAreaContents, dispatchTherapAreaDetail, dispatch]);

  const hasNextPage = therapAreaContents?.page <= therapAreaContents?.maxPage;

  const handleLoadMore = () => {
    if (hasNextPage) {
      dispatchTherapAreaContents({ loadMore: true });
    }
  };

  const error = therapAreaContents?.error || therapAreaDetail?.error;
  const loading =
    (therapAreaContents?.loading || therapAreaDetail?.loading) && !error;
  const notFound = therapAreaContents?.notFound || therapAreaDetail?.notFound;
  return notFound ? (
    <Redirect
      to={{
        pathname: "/404",
        state: { message: t("this therapeutic area does not exist") },
      }}
    />
  ) : (
    <Layout
      name="therapeutic-area"
      hasContainer
      headerComponents={[
        <ShimmerWrapper
          loading={loading}
          shimmerRender={<ShimmerAreaCover />}
          unique
        >
          {!loading && !error && !internetError && (
            <AreaCover
              title={therapAreaDetail?.data?.playlist_name}
              cover={therapAreaDetail?.data?.cover_id}
              size={therapAreaDetail?.data?.nb_contents}
            />
          )}
        </ShimmerWrapper>,
      ]}
    >
      {internetError ? (
        <InternetError />
      ) : error ? (
        <ServerError />
      ) : (
        <ShimmerWrapper
          loading={loading}
          shimmerRender={<ShimmerContentCard />}
          ShimmerLayout={({ children }) => (
            <div className="courses-grid">
              <div className="row">{children}</div>
            </div>
          )}
          large={therapAreaContents?.data?.length === 0}
        >
          {therapAreaContents?.data?.length > 0 ? (
            <InfiniteScroll
              dataLength={therapAreaContents?.data?.length} //This is important field to render the next data
              next={handleLoadMore}
              hasMore={hasNextPage}
              style={{ overflowX: "hidden", overflowY: "hidden" }}
            >
              <div className="courses-grid">
                <div className="row">
                  {therapAreaContents?.data.map(
                    ({
                      content_id,
                      content_name,
                      __t,
                      interval_duration,
                      description,
                      therapeuticArea,
                      thumbnail,
                      added_to_favorite,
                      percentage_viewed,
                      pages_number,
                      channel,
                      tags,
                      nb_views,
                      speciality,
                      duration,
                      promomat,
                      publishing_date,
                    }) => {
                      const infoContent = {
                        speciality: speciality?.name,
                        tags: tags,
                        nbViews: nb_views,
                        channelName: channel?.playlist_name,
                        areaName: therapeuticArea?.playlist_name,
                        percentageViewed: parseInt(percentage_viewed || 0),
                        promomat,
                      };
                      const isVideo = __t === "VideoAdvent";
                      if (isVideo) {
                        infoContent.duration = parseDurationToSeconds(duration);
                      } else {
                        infoContent.pagesNumber = pages_number;
                      }
                      return (
                        <div
                          key={content_id}
                          className="col-sm-6 col-lg-4 col-xl-3"
                        >
                          <ContentCard
                            content_id={content_id}
                            isVideo={__t === "VideoAdvent"}
                            intervalDuration={interval_duration}
                            name={content_name}
                            description={description}
                            therapeuticArea={therapeuticArea}
                            thumbnail={thumbnail}
                            isFavorite={added_to_favorite}
                            percentageViewed={percentage_viewed}
                            infoContent={infoContent}
                            source={sources.therapeuticArea}
                            playlistId={therapeuticArea?.playlist_id}
                            publishing_date={publishing_date}
                          />
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </InfiniteScroll>
          ) : (
            !loading && <NoResult />
          )}
        </ShimmerWrapper>
      )}
    </Layout>
  );
};

export default TherapArea;
