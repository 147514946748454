/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import * as qs from "query-string";
import { useTranslation } from "react-i18next";

import routesList from "routes/routesList";
import { Layout } from "components";
import {
  coursesTab,
  expertsTab,
  eventsTab,
  therapAreaTab,
  searchTypes,
} from "data";
import { TabSearch, TabContent } from "./components";
import { formatFilter, lengthFilter } from "data";

const Search = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const tabs = [coursesTab, therapAreaTab, expertsTab, eventsTab];
  const { therapArea } = useSelector((state) => state.referential);
  const search = useSelector((state) => state.search);

  const therapAreaFilter = {
    namespace: "therapArea",
    title: t("therapeutic area"),
    options: therapArea?.data?.map(({ playlist_id, playlist_name }) => ({
      name: playlist_name,
      value: playlist_id,
    })),
  };
  const filters = (namespace) => {
    // eslint-disable-next-line default-case
    switch (namespace) {
      case "experts":
        return [];
      case "therapeuticAreas":
        return [];
      case "courses":
        return [therapAreaFilter, formatFilter, lengthFilter];
      case "events":
        return [therapAreaFilter];
    }
  };
  const queries = qs.parse(location.search);
  const { q } = queries;

  let history = useHistory();
  const [selected, setSelected] = useState("courses");
  const handleSelected = (selected) => {
    setSelected(selected);
  };

  useEffect(() => {
    dispatch({ type: "referential/GET_THERAP_AREA" });
    return () => {
      dispatch({ type: "search/RESET" });
    };
  }, [dispatch]);

  useEffect(() => {
    history.push(
      `${routesList.search}?q=${q || ""}&type=${searchTypes[selected]}`
    );
  }, [selected, q]);

  return (
    <Layout
      name="search-results"
      hasContainer
      tabComponent={
        <TabSearch
          tabs={tabs}
          handleSelected={handleSelected}
          selectedItem={selected}
          searchValue={q}
        />
      }
      q={q}
    >
      <div className="tab-content" id="myTabContent">
        {tabs.map(({ namespace }, index) => (
          <TabContent
            key={index}
            id={namespace}
            searchValue={q}
            isActive={namespace === selected}
            data={search[namespace]}
            filters={filters(namespace)}
          />
        ))}
      </div>
    </Layout>
  );
};

export default Search;
