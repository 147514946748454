import { useTranslation } from "react-i18next";

import config from "config";

const AreaCover = ({ title, size, cover }) => {
  const { t } = useTranslation();
  return (
    <div className="container">
      <div className="doctor-profile-hero">
        <img
          className="doctor-profile-hero__img"
          src={`${config.app.cdnUrl}/${cover}`}
          alt="courerture"
        />
        <div className="doctor-profile-info">
          <div className="doctor-profile-info__inner">
            <div className="doctor-profile-info__info">
              <div className="doctor-profile-info__detail">
                <h1 className="doctor-profile-info__name">{title}</h1>
                <span className="doctor-profile-info__videos-nbr">
                  {size} {t('courses')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AreaCover;
