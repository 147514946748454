import { api } from "services/apiInstances";
const initialState = {
  eventDetails: {
    data: {},
    error: false,
    internetError: false,
    notFound: false,
    loading: true,
  },
};

const eventDetails = {
  namespace: "eventDetails",
  state: { ...initialState },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *GET_EVENT_DETAILS({ payload }, { select, put, call }) {
      const state = yield select((state) => state.eventDetails);
      const { eventDetails } = state;
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            eventDetails: {
              ...eventDetails,
              internetError: true,
            },
          },
        });
      } else {
        yield put({
          type: "setState",
          payload: {
            eventDetails: {
              data: {},
              loading: true,
              error: false,
              notFound: false,
            },
          },
        });

        try {
          const response = yield call(api.getEventDetails, payload.id);
          if (response?.status === 200) {
            // non empty list
            yield put({
              type: "setState",
              payload: {
                eventDetails: {
                  data: response.data.event,
                  loading: false,
                  error: false,
                  notFound: false,
                },
              },
            });
          }
        } catch (error) {
          const errorField =
            error?.response?.status === 404 ? "notFound" : "error";
          yield put({
            type: "setState",
            payload: {
              eventDetails: {
                data: {},
                loading: false,
                [errorField]: true,
              },
            },
          });
        }
      }
    },
    *UPDATE_FAVORITE({ payload }, { select, put }) {
      const state = yield select((state) => state.eventDetails);
      const { eventDetails } = state;
      const { id, added_to_favorite } = payload;
      const { data } = eventDetails;
      if (data?.contents?.length > 0) {
        data.contents = eventDetails.data?.contents?.map((content) => {
          if (content.content_id === id)
            return { ...content, added_to_favorite };
          return content;
        });
      }
      if (data.playlist_id === id) {
        data.added_to_favorite = added_to_favorite;
      }
      yield put({
        type: "setState",
        payload: {
          eventDetails: {
            loading: false,
            error: false,
            data,
          },
        },
      });
    },
    *LEAVE_LIVE({ payload }, { call }) {
      const { id } = payload;
      try {
        yield call(api.leaveLive, id);
      } catch (error) {
        console.log(error);
      }
    },
    *RESET_EVENT(_, { put }) {
      yield put({
        type: "setState",
        payload: {
          eventDetails: initialState.eventDetails,
        },
      });
    },
    *RESET(_, { put }) {
      yield put({
        type: "resetState",
      });
    },
  },
};

export default eventDetails;
