import { useTranslation } from "react-i18next";

const Biography = ({ biography }) => {
  const { t } = useTranslation();

  return (
    <>
      <h2 className="section__title">{t("biography")}</h2>
      <p className="expert-profile__bio">{biography}</p>
    </>
  );
};

export default Biography;
