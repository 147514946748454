import { useEffect } from "react";

import { Layout } from "components";

const TermsOfUse = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, [400]);
  }, []);
  return (
    <Layout
      name="cgu"
      noNav
      headerComponents={[
        <header className="auth__heading text-center">
          <a className="logo logo--center" href="/">
            <img
              src={require("assets/img/logo_advent.svg").default}
              alt="Advent"
            />
          </a>

          <p>
            Bienvenue sur Advent, la plateforme dédiée à l’information médicale
            à destination des professionnels de la santé.
          </p>
        </header>,
      ]}
    >
      <div className="cgu__content">
        <h1 className="cgu__heading-primary">Conditions d’utilisation</h1>
        <p>
          Les présentes Conditions d’Utilisation définissent les termes et
          conditions d’utilisation régissant l’utilisation de la plateforme{" "}
          <a className="cgu__link" href="/" target="_blank">
            {" "}
            Advent{" "}
          </a>{" "}
          (Ci-après la « Plateforme ») qui s’appliquent à l’utilisation de tous
          les éléments de la Plateforme, y compris tous les contenus,
          caractéristiques, fonctionnalités, programmes et services fournis dans
          ou via la Plateforme.
        </p>
        <p>
          Les présentes Conditions sont applicables à l'ensemble des
          utilisateurs la Plateforme (ci-après désigné par « Vous » ou l’«
          Utilisateur ») . Par votre accès et utilisation de la Plateforme, vous
          acceptez sans limitation, l'ensemble des clauses des présentes
          Conditions, et vous vous engagez à les respecter.
        </p>
        <p>
          Sanofi-Algérie spa (Ci-après désigné « Sanofi »), se réserve le droit
          de modifier les présentes Conditions d'Utilisation, à tout moment et
          sans préavis, des dispositions ou des conditions nouvelles ou
          supplémentaires applicables à votre utilisation de la Plateforme. Les
          dites modifications et conditions supplémentaires prendront effet
          immédiatement et seront intégrées aux présentes Conditions
          d'Utilisation. Vous devez prendre périodiquement connaissance des
          Conditions pour examiner les mises à jour. Votre utilisation de la
          Plateforme par la suite sera considérée comme une acceptation tacite
          et sans réserve de ces Conditions d’Utilisation.
        </p>
        <p>
          En accédant à la Plateforme, vous reconnaissez être informé de
          la Politique de confidentialité et de protection des données pratiquée
          par Sanofi et accessible sur la Plateforme. Vous déclarez ne pas vous
          opposer à cette Politique de confidentialité et de protection des
          données mise en œuvre par Sanofi.
        </p>
        <p>
          Dans le cas où vous n’acceptez pas tout ou partie des présentes
          Conditions d’Utilisation, il Vous est demandé de renoncer à tout usage
          du Site.
        </p>
        <h2 className="cgu__heading-secondary">
          1. Périmètre de la Plateforme
        </h2>
        <p>
          L’objectif de ce Site est de diffuser des contenus vidéo de diverses
          manifestations scientifiques, il est conçu à des fins pédagogiques et
          contient des informations (articles, illustrations, outils et autres
          ressources, etc.) d’ordre médical pour permettre aux Professionnels de
          la santé inscrits de disposer d’une plateforme innovante pour eux,
          pour les soutenir avec les informations qu'ils souhaitent recevoir. Et
          fournir aux Professionnels de santé un contenu scientifique précieux à
          valeur ajoutée.
        </p>
        <h2 className="cgu__heading-secondary">
          2. Utilisation de la Plateforme
        </h2>
        <p>
          L’utilisation de la Plateforme est réservée aux seuls Professionnels
          de santé inscrits et situés sur le territoire algérien. La Plateforme
          ayant été développée et conçue conformément à la réglementation
          algérienne.
        </p>
        <p>
          Tout utilisateur doit se conformer aux dispositions des présentes
          Conditions d'Utilisation.
        </p>
        <h3 className="cgu__heading-tertiary">2.1. Contenu de la Plateforme</h3>
        <p>
          <strong>La Plateforme</strong> a pour vocation à vous fournir des
          informations médicales entrant dans les aires thérapeutiques de
          Sanofi. Toutefois, elle peut contenir des informations relatives à la
          santé mais elles ne sont fournies que dans l'objectif défini par les
          présentes Conditions et ne peuvent en aucun cas se substituer d’un
          autre avis que d'un Professionnel de santé.
        </p>
        <p>
          Le contenu de la Plateforme, y compris les conseils et
          recommandations, est mis à disposition aux fins de l’information et
          est destiné à servir d'aide pédagogique générale et n’a pas pour
          objectif de remplacer les avis, diagnostiques ou traitements médicaux
          professionnels qualifiés connaissant votre cas particulier. Demandez
          toujours l'avis de votre médecin ou d'un autre professionnel de la
          santé qualifié concernant toute condition médicale et avant de
          commencer tout nouveau traitement. Votre utilisation de la Plateforme
          est soumise aux clauses de non-responsabilité et aux mises en garde
          supplémentaires qui peuvent apparaître sur la Plateforme.
        </p>
        <p>
          L’information contenue dans cette Plateforme ne doit pas être utilisée
          pour diagnostiquer une maladie ou un problème physique, ou pour
          prescrire ou utiliser des médicaments présentés sur la Plateforme.
          Vous devez toujours consulter votre médecin ou pharmacien.
        </p>
        <p>
          La Plateforme n’a pas pour vocation de répondre aux questions d’ordre
          médical du public. Il n’a pas davantage pour objectif à remplacer la
          relation qui existe entre le patient et son Professionnel de santé ou
          encore de se substituer à son avis médical.
        </p>
        <p>
          En toutes hypothèses, chaque Professionnel de santé utilisateur de la
          Plateforme demeure intégralement et exclusivement responsable des
          actes et des décisions relevant de son activité professionnelle et
          notamment, de la prescription, de la délivrance, de la dispensation,
          et du contrôle de la prescription des traitements et des informations
          relatives à ses patients conformément aux obligations légales,
          réglementaires et déontologiques qui président à la poursuite de son
          activité professionnelle.
        </p>
        <p>
          Les informations fournies sur la Plateforme ne constituent pas une
          invitation pour prescrire ou acheter des produits médicaux offerts par
          Sanofi ou ses filiales.
        </p>
        <p>
          Les informations, y compris les informations financières ou les
          informations relatives à l'activité du Groupe Sanofi, pouvant figurer
          sur le Site ne doivent pas être interprétées comme une invitation à
          investir. Elles ne doivent pas non plus être interprétées comme étant
          du démarchage ou une offre publique, et ne constituent pas non plus
          une offre de souscription, d'achat ou d'échange d'actions Sanofi ou
          d'autres titres émis par le Groupe Sanofi et / ou par ses filiales.
        </p>
        <p>
          Les opinions professionnelles concernant certains domaines
          particuliers relatifs au contenu du Site peuvent être publiées sur le
          Site. De telles informations représentent les opinions des experts
          eux-mêmes et ne reflètent pas nécessairement les opinions de Sanofi.
          Ces experts ne sont pas des employés de Sanofi. Sanofi n’est pas
          responsable de l’exactitude ou de l’exhaustivité des informations ou
          opinions présentées dans ces documents.
        </p>
        <h3 className="cgu__heading-tertiary">2.2. Utilisation du contenu</h3>
        <p>
          Vous pouvez consulter la Plateforme, imprimer des éléments contenus
          dans la Plateforme, ou encore utiliser toutes les fonctionnalités
          depuis la Plateforme uniquement à usage personnel.
        </p>
        <p>
          En conséquence, vous ne pouvez utiliser les éléments de la Plateforme
          à d'autres fins, notamment pour une utilisation publique et/ou
          commerciale sans avoir obtenu l'accord préalable et écrit de Sanofi.
        </p>
        <p>
          la Plateforme est fournie exclusivement pour une utilisation
          personnelles. Ainsi, il vous est interdit de procéder à une quelconque
          copie de tout ou partie du contenu de la Plateforme ou d'autoriser une
          tierce personne à utiliser la Plateforme.
        </p>
        <p>
          De même, Vous vous engagez à ne pas télécharger, afficher, envoyer ou
          transmettre de quelque façon que ce soit n'importe quel contenu.
        </p>
        <p>
          Nous n'assumons aucune responsabilité pour toute conséquence liée
          directement ou indirectement à toute action ou inaction que vous
          entreprenez sur la base des informations, des services ou de tout
          autre matériel sur la Plateforme. Alors que nous nous efforçons de
          garder les informations sur la Plateforme exactes, complètes et à
          jour, nous ne donnons aucune garantie et ne serons pas responsables de
          tout dommage ou perte liée à l'exactitude, l'exhaustivité ou
          l'actualité des informations partagées sur la Plateforme.
        </p>
        <h2 className="cgu__heading-secondary">3. Confidentialité</h2>
        <p>
          La Plateforme n’est pas conçue pour gérer des informations
          confidentielles que Vous pouvez soumettre. À l’exception des données
          personnelles (veuillez-vous reporter à la Politique de confidentialité
          sur la Plateforme), toute information sur quelque support que ce soit
          - documents, données, graphiques, questions, recommandations,
          concepts, remarques ou autre - que Vous partagez sur la Plateforme ne
          sera en aucun cas considérée de nature confidentielle. Par conséquent,
          la simple transmission par Vous nous autorise d’utiliser, reproduire,
          distribuer, modifier ou transmettre cette information afin de traiter
          Votre demande.
        </p>
        <p>
          Les noms d’utilisateurs et les mots de passe (Les “Données d’Accès”)
          fournis par Vous sur la Plateforme, vous sont personnels et ne seront
          partagés avec aucune autre personnes. Vous êtes pleinement responsable
          de l’utilisation et de la confidentialité de vos Données d’Accès.{" "}
        </p>
        <p>
          L’Utilisateur s'engage, de manière générale, à prendre toutes mesures
          utiles pour assurer la parfaite confidentialité Données d’Accès et
          s'engage à ne pas communiquer, céder ou mettre à la disposition, d'un
          tiers.
        </p>
        <h2 className="cgu__heading-secondary">4. Liens</h2>
        <p>
          La Plateforme est susceptible de contenir des liens hypertextes vers
          d’autres plateformes, sites Internet ou applications gérés par des
          tiers.
        </p>
        <p>
          Il est important de prendre en considération les éléments ci-après
          listés, en votre qualité d’Utilisateur de la Plateforme et par Votre
          accès aux sites et/ou application appartenant à un tiers à partir d'un
          lien figurant dans la Plateforme:
        </p>
        <ul>
          <li>
            Sanofi ne peut aucunement procéder à la vérification régulière de la
            qualité des sites liés, ni aucun moyen de contrôler le contenu.
          </li>
          <li>
            Sanofi ne saurait ainsi être tenu responsable ni du contenu de ces
            sites, ni des services ou services proposés sur ces sites.
          </li>
          <li>
            L’existence d'un lien à partir de la Plateforme vers le site ou
            l'application d'un tiers ne signifie pas que Sanofi approuve ou
            cautionnes-en aucune manière le site ou l'application en question
            ainsi que son utilisation.
          </li>
        </ul>
        <p>
          En outre, un résumé et / ou un lien vers un site de tiers n'implique
          pas que Sanofi approuve le site ou les produits ou services sur ces
          sites de tiers. Elle ne garantit pas l'exactitude de tout contenu dans
          un tel site tiers et ne sera pas responsable des dommages ou
          préjudices résultant de l'accès ou de l'impossibilité d'accéder à ces
          sites Ces liens demeurent totalement indépendants de Sanofi.
        </p>
        <p>
          De plus, il vous incombe de prendre les précautions nécessaires pour
          prévenir toute contamination du site, en particulier par un ou
          plusieurs virus, Cheval de Troie ou autre “parasite”.
        </p>
        <p>
          Les sites externes peuvent contenir des liens hypertextes renvoyant
          vers la Plateforme. Ces liens hypertextes ne devront être installés
          sans l’accord préalable et exprès de Sanofi. Dans tous les cas, Sanofi
          n’est en aucun cas responsable de l’indisponibilité de tels liens
          externes.
        </p>
        <p>
          En toute hypothèse, Sanofi Algérie ne peut être tenu responsable quant
          à la disponibilité des sites ou applications tiers et Sanofi Algérie
          ne contrôle, ni ne vérifie ou cautionne, le contenu, les publicités,
          les produits ou autres matériaux disponibles sur ces sites ou
          applications.
        </p>

        <h2 className="cgu__heading-secondary">
          5. Limitation de responsabilité
        </h2>
        <p>
          Sanofi veille à prendre toutes les mesures raisonnables afin de
          s’assurer que les informations publiées sur la Plateforme sont exactes
          et à jour. Sanofi se réserve le droit à tout moment de corriger le
          contenu, sans préavis. Cependant, Sanofi ne peut garantir que toute
          information mise à disposition sur la Plateforme soit exacte,
          correcte, à jour et exhaustive.
        </p>
        <p>
          La Plateforme est fournie « en l'état ». Sanofi Algérie ne fait aucune
          déclaration et n'accorde aucune garantie, quelle qu'elle soit, quant à
          (i) l'existence ou l'utilisation de la Plateforme, et/ou (ii) toute
          information ou interprétation de toute information stockée sur la
          Plateforme, générée par ou reçue par le biais de cette dernière.
        </p>
        <p>
          Par la présente, Sanofi rejette et exclut toute responsabilité et/ou
          toute garantie, expresses ou implicites, créées par la loi, par
          contrat ou autre, incluant mais sans s'y limiter, toutes garanties de
          qualité marchande ou de conformité à un usage spécifique, ou de
          non-contrefaçon d'éléments appartenant à un tiers. Sanofi mettra à
          jour la Plateforme au temps voulu. Sanofi ne garantit pas que la
          Plateforme soit exempte de bugs, d'inexactitudes ou d'erreurs, et ne
          garantit pas non plus qu'elle soit exempte de virus ou d'autres
          éléments nuisibles qui pourraient endommager votre appareil.
        </p>
        <p>
          En conséquence, dans les conditions permises par la loi, Sanofi
          décline toute responsabilité:
        </p>
        <ul>
          <li>
            Pour toute imprécision, inexactitude ou omission relatives aux
            informations disponibles dans la Plateforme.
          </li>
          <li>
            Pour tout dommage résultant d'une intrusion frauduleuse par un tiers
            ayant entraîné une modification des informations ou éléments
            disponibles dans la Plateforme.
          </li>
          <li>
            Et plus généralement, pour tout dommage, direct ou indirect,
            spéciaux, consécutifs ou accessoires, quelle que soit sa cause,
            origine, nature ou conséquences, quand bien même Sanofi ai eu
            connaissance de la possibilité de tels dommages causés en raison de
            (i) l'accès ou de l'impossibilité d'accéder à la Plateforme, (ii)
            l'utilisation de la Plateforme, incluant toutes détériorations ou
            virus qui pourraient infecter votre ordinateur, votre appareil
            mobile ou d'autres biens, et / ou (iii) du crédit accordé à une
            quelconque information provenant directement ou indirectement de la
            Plateforme.
          </li>
        </ul>
        <p>
          Enfin, il est de votre responsabilité de prendre toutes les
          précautions pour éviter toute contamination de la Plateforme,
          notamment mais pas uniquement par des « virus », « cheval de Troie »
          ou autres« parasites ».
        </p>
        <h2 className="cgu__heading-secondary">
          6. Disponibilité de l'application la platforme
        </h2>
        <p>
          Vous reconnaissez que (i) il est techniquement impossible de fournir
          une Plateforme exempt de tout défaut et que Sanofi ne peut s'y
          engager, (ii) que ces éventuels défauts peuvent rendre indisponible
          temporairement la Plateforme, et que (iii) le fonctionnement de la
          Plateforme peut être affecté par des événements et / ou des éléments
          que Sanofi ne contrôle pas, tels que, par exemple, des moyens de
          communication entre Vous et Sanofi, et Sanofi et d'autres réseaux.
          Sanofi et / ou ses fournisseurs peuvent à tout moment, modifier ou
          supprimer, temporairement ou de façon permanente, tout ou partie de la
          Plateforme, y compris pour effectuer des travaux de maintenance et /
          ou effectuer des améliorations et / ou des changements dans la
          Plateforme. Sanofi n'est pas responsable de ces modifications,
          suspensions ou interruptions ni de leurs conséquences.
        </p>
        <h2 className="cgu__heading-secondary">
          7. Dispositions au de droit propriété intellectuelle
        </h2>
        <p>La Plateformeest la propriété de Sanofi.</p>
        <p>
          La Plateforme ainsi que l'ensemble des droits de propriété
          intellectuelle associés, tels que les copyrights, les droits d'auteur,
          les droits relatifs aux brevets, bases de données, marques, dessins,
          logos, savoir-faire, ainsi que les informations confidentielles et
          tous les autres droits de propriété intellectuelle (déposés ou non),
          associés à la Plateforme, sont protégés par la législation en vigueur
          et appartiennent à Sanofi (ou font l'objet d'un droit d'utilisation
          conféré à Sanofi).
        </p>
        <p>
          Sanofi vous consent une licence limitée, non-exclusive, incessible,
          personnelle pour télécharger gratuitement, accéder et utiliser la
          Plateforme conformément aux dispositions contenues dans les présentes
          Conditions d'Utilisation. Cette licence vous est accordée pendant
          toute la durée de protection des droits de propriété intellectuelle
          associés à la Plateforme, et ce partout dans le monde, dans l'unique
          but de pouvoir utiliser la Plateforme.
        </p>
        <p>
          En complément de la licence accordée au paragraphe précédent, la
          Plateforme et les droits de propriété intellectuelle associés ne
          peuvent être reproduits, utilisés ou transférés sans l'accord
          préalable et écrit de Sanofi, ou, dans l'hypothèse où ces droits
          proviennent de tiers, de l'accord préalable et écrit de leurs
          propriétaires respectifs.
        </p>
        <p>
          Aucun des éléments de la Plateforme ne pourra être copié, reproduit,
          modifié, republié, téléchargé, altéré, transmis ou distribué d'une
          quelconque manière, sur quelque support que ce soit, en partie ou en
          totalité, sans le consentement préalable et écrit de Sanofi, ou, si
          les droits sur lesdits éléments sont détenus par des tiers, le
          consentement de leurs propriétaires respectifs, et sous réserve du
          respect des droits de propriété intellectuelle et autres droits qui
          sont mentionnés.
        </p>
        <p>
          Toute utilisation des composants contenus dans la Plateforme ne doit
          pas être déformée, modifiée ou altérée de quelque manière que ce soit.
          En outre, l'Utilisateur ne doit pas décompiler, procéder à de
          l'ingénierie inverse, désassembler la Plateforme ou désactiver une
          fonctionnalité qui pourrait limiter l'utilisation de la Plateforme.
        </p>
        <p>
          L'Utilisateur ne doit pas modifier, adapter, traduire, accorder une
          sous-licence, louer, prêter, vendre ou distribuer la Plateforme ni les
          droits de propriété intellectuelle contenues dans la Plateforme, ou
          créer des œuvres dérivées de la Plateforme ou une partie de celle-ci
          sans le consentement préalable et écrit de Sanofi.
        </p>
        <p>
          Sanofi se réserve le droit d'intenter des poursuites contre toute
          violation de ses droits de propriété intellectuelle.
        </p>
        <p>
          La déclaration suivante doit figurer sur tout copie autorisée de tout
          ou partie du contenu de la Plateforme Site: “COPYRIGHT SANOFI-AVENTIS
          ALGERIE 2022 - TOUS DROITS RÉSERVÉS”.
        </p>
        <h2 className="cgu__heading-secondary">8. Dispositions générales</h2>
        <p>
          8.1. Si une ou plusieurs dispositions dans les présentes Conditions
          d'utilisation devaient s'avérer invalides, illégales ou inexécutables,
          les dispositions restantes demeureront pleinement applicables et
          conserveront leurs effets.
        </p>
        <p>
          8.2. Cession. Sanofi peut, céder ou transférer à tout moment le Site,
          y compris les droits et obligations qui s'y rattachent.
        </p>
        <p>
          8.3. Communications électroniques. Sanofi pourra vous envoyer des
          informations relatives à votre compte (par exemple, les changements de
          mot de passe, les messages de confirmation, les notifications)
          uniquement par voie électronique, par exemple par l'envoi d'un
          courriel à l'adresse électronique que vous avez fournie lors de votre
          inscription.
        </p>
        <h2 className="cgu__heading-secondary">
          9. Droit applicable et règlements des litiges
        </h2>
        <p>
          La Plateforme ainsi que son contenu sont soumises aux dispositions du
          droit algérien au moment de l’adhésion à la Plateforme.
        </p>
        <p>
          Tout litige relatif à la Plateformeou à son contenu seront soumis
          exclusivement à la compétence des tribunaux d’Alger.
        </p>
        <h2 className="cgu__heading-secondary">Mentions Légales</h2>
        <p>
          <strong>
            La société Sanofi Aventis Algérie, société par actions
          </strong>{" "}
          au capital social de : 11 054 489 700 DA –Siège social Hydra Micro
          Zone d’activité Immeuble B lots 29 30 et 31-Alger. N° RC 98 B. 0005416
          –NIF 0998 16000 541627 –N° Article 16440112611
        </p>
      </div>
    </Layout>
  );
};

export default TermsOfUse;
