import { Layout, ServerError, InternetError, ShimmerWrapper } from "components";
import {
  Hero,
  Program,
  Section,
  Events,
  Testimonials,
  Categories,
} from "./components";
import { ShimmerHero } from "components/Shimmers";

import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { initSwiper } from "./utils";
import { parseDurationToSeconds } from "utils/functions";
import { sources } from "data/eventsLog";

const Home = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const home = useSelector((state) => state.home);

  const {
    airTherap,
    recommendedCourses,
    latestCourses,
    startedCourses,
    events,
    testimonials,
    favorites,
    internetError,
  } = home;
  useEffect(() => {
    initSwiper();
  });

  const dispatchAireTherap = useCallback(() => {
    dispatch({ type: "home/GET_AIR_THERAP" });
  }, [dispatch]);
  const dispatchRecommendedCourses = useCallback(() => {
    dispatch({ type: "home/GET_RECOMMENDED_COURSES" });
  }, [dispatch]);
  const dispatchLatestCourses = useCallback(() => {
    dispatch({ type: "home/GET_LATEST_COURSES" });
  }, [dispatch]);
  const dispatchStartedCourses = useCallback(() => {
    dispatch({ type: "home/GET_STARTED_COURSES" });
  }, [dispatch]);
  const dispatchEvents = useCallback(() => {
    dispatch({ type: "home/GET_EVENTS" });
  }, [dispatch]);
  const dispatchTestimonials = useCallback(() => {
    dispatch({ type: "home/GET_TESTIMONIALS" });
  }, [dispatch]);
  const dispatchFavorites = useCallback(() => {
    dispatch({ type: "home/GET_FAVORITES" });
  }, [dispatch]);

  useEffect(() => {
    dispatchAireTherap();
    dispatchRecommendedCourses();
    dispatchLatestCourses();
    dispatchStartedCourses();
    dispatchTestimonials();
    dispatchFavorites();
    dispatchEvents();
    return () => {
      dispatch({ type: "home/RESET" });
    };
  }, [
    dispatchAireTherap,
    dispatchRecommendedCourses,
    dispatchLatestCourses,
    dispatchFavorites,
    dispatchStartedCourses,
    dispatchTestimonials,
    dispatchEvents,
    dispatch,
  ]);

  const globalError =
    airTherap?.error &&
    recommendedCourses?.error &&
    latestCourses?.error &&
    favorites?.error &&
    startedCourses?.error &&
    testimonials?.error &&
    events?.error;
  const hero = recommendedCourses?.data?.[0];
  const heroInfoContent = {
    speciality: hero?.speciality?.name,
    tags: hero?.tags,
    nbViews: hero?.nb_views,
    channelName: hero?.channel?.playlist_name,
    areaName: hero?.therapeuticArea?.playlist_name,
    percentageViewed: parseInt(hero?.percentage_viewed || 0),
    promomat: hero?.promomat,
  };

  if (hero?.__t === "VideoAdvent") {
    heroInfoContent.duration = parseDurationToSeconds(hero?.duration);
  } else {
    heroInfoContent.pagesNumber = hero?.pages_number;
  }
  return (
    <Layout
      name="home"
      headerComponents={
        !globalError && !internetError
          ? [
              <ShimmerWrapper
                loading={
                  recommendedCourses?.loading || recommendedCourses?.error
                }
                shimmerRender={<ShimmerHero />}
                ShimmerLayout={({ children }) => (
                  <div className="hero">
                    <div className="container">
                      <div className="hero__content">{children}</div>
                    </div>
                  </div>
                )}
                unique
              >
                {!recommendedCourses.loading &&
                  recommendedCourses.data.length > 0 && (
                    <Hero
                      id={hero?.content_id}
                      title={hero?.content_name}
                      thumbnail={hero?.thumbnail}
                      doctor={hero?.channel}
                      description={hero?.description}
                      isFavorite={hero?.added_to_favorite}
                      infoContent={heroInfoContent}
                      source={sources.homePage}
                      playlistId={hero?.therapeuticArea?.playlist_id}
                    />
                  )}
              </ShimmerWrapper>,
            ]
          : []
      }
    >
      {internetError ? (
        <InternetError />
      ) : globalError ? (
        <ServerError />
      ) : (
        <>
          <Program />

          <Section
            id="recommandations"
            title={t("courses")}
            courses={recommendedCourses}
          />
          <Section id="started" title={t("started")} courses={startedCourses} />
          <Section
            id="discover"
            title={t("discover our latest courses")}
            courses={latestCourses}
          />

          <Events events={events} />

          <Testimonials testimonials={testimonials} />
          <Categories therapeuticAreas={airTherap} />

          <Section
            id="favorites"
            title={t("my favorites")}
            courses={favorites}
          />
        </>
      )}
    </Layout>
  );
};

export default Home;
