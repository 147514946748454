const breakpoints = {
  0: "",
  1: "d-none d-sm-block",
  2: "d-none d-lg-block",
  3: "d-none d-xl-block",
};

const ShimmerWrapper = ({
  loading,
  ShimmerLayout = ({ children }) => children,
  shimmerRender,
  large,
  unique,
  children,
}) => {
  const ShimmerLine = () =>
    Array.from(Array(4).keys()).map((_, index) => (
      <div
        key={index}
        className={`col-sm-6 col-lg-4 col-xl-3 ${breakpoints[index]}`}
      >
        {shimmerRender}
      </div>
    ));
  return (
    <>
      {children}
      {loading && (
        <ShimmerLayout>
          {!unique ? (
            <>
              <ShimmerLine />
              {large && <ShimmerLine />}
            </>
          ) : (
            shimmerRender
          )}
        </ShimmerLayout>
      )}
    </>
  );
};

export default ShimmerWrapper;
