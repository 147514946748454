import { api } from "services/apiInstances";
const initialState = {
  courseDetails: {
    data: {},
    error: false,
    notFound: false,
    loading: true,
  },
  similarCourses: {
    data: [],
    error: false,
    notFound: false,
    loading: true,
  },
  internetError: false,
};

const courseDetails = {
  namespace: "courseDetails",
  state: { ...initialState },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *GET_COURSE_DETAILS({ payload }, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        yield put({
          type: "setState",
          payload: {
            courseDetails: {
              data: {},
              loading: true,
              error: false,
              notFound: false,
            },
          },
        });

        try {
          const response = yield call(api.getContentDetails, payload.id);
          if (response?.status === 200) {
            // non empty list
            yield put({
              type: "setState",
              payload: {
                courseDetails: {
                  data: response.data.content,
                  loading: false,
                  error: false,
                  notFound: false,
                },
              },
            });
          }
        } catch (error) {
          const errorField =
            error?.response?.status === 404 ? "notFound" : "error";
          yield put({
            type: "setState",
            payload: {
              courseDetails: {
                data: {},
                loading: false,
                [errorField]: true,
              },
            },
          });
        }
      }
    },
    *UPDATE_FAVORITE({ payload }, { select, put }) {
      const state = yield select((state) => state.courseDetails);
      const { courseDetails, similarCourses } = state;
      const { content_id, added_to_favorite } = payload;

      const { data } = courseDetails;
      if (data.suite?.contents?.length > 0) {
        data.suite.contents = courseDetails.data.suite?.contents?.map(
          (content) => {
            if (content.content_id === content_id)
              return { ...content, added_to_favorite };
            return content;
          }
        );
      }
      if (similarCourses.data?.length > 0) {
        similarCourses.data = similarCourses.data.map((course) => {
          if (course.content_id === content_id)
            return { ...course, added_to_favorite };
          return course;
        });
      }
      if (data.content_id === content_id) {
        data.added_to_favorite = added_to_favorite;
      }
      yield put({
        type: "setState",
        payload: {
          courseDetails: {
            loading: false,
            error: false,
            data,
          },
        },
      });
    },
    *GET_SIMILAR_COURSES({ payload }, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        yield put({
          type: "setState",
          payload: {
            similarCourses: {
              data: [],
              loading: true,
              error: false,
              notFound: false,
            },
          },
        });

        try {
          const response = yield call(api.getSimilarCourses, payload.id);
          if (response?.status === 200) {
            // non empty list
            yield put({
              type: "setState",
              payload: {
                similarCourses: {
                  data: response.data.data,
                  loading: false,
                  error: false,
                  notFound: false,
                },
              },
            });
          }
        } catch (error) {
          const errorField =
            error?.response?.status === 404 ? "notFound" : "error";
          yield put({
            type: "setState",
            payload: {
              similarCourses: {
                data: [],
                loading: false,
                [errorField]: true,
              },
            },
          });
        }
      }
    },
    *RESET(_, { put }) {
      yield put({
        type: "resetState",
      });
    },
  },
};

export default courseDetails;
