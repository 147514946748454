import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import en from "./localization/EN-en";
import fr from "./localization/FR-fr";
import config from "./config";

const resources = {
  "en-US": { translation: en },
  en: { translation: en },
  "fr-FR": { translation: fr },
  fr: { translation: fr },
};
export const languageList = ["en-US", "en", "fr", "fr-FR"];

const lng = config.defaultLanguage;

i18n
  .use(XHR)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    // lng: languageSelected,
    keySeparator: false,
    lng,
    fallbackLng: {
      ar: ["ar"],
      "ar-AR": ["ar"],
      fr: ["fr"],
      "fr-FR": ["fr"],
      "de-CH": ["fr"],
      "zh-Hant": ["en"],
      es: ["fr"],
      default: [config.defaultLanguage],
    },
    supportedLngs: languageList,
    nonExplicitSupportedLngs: true,
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },
  });

export default i18n;
