import { useDispatch } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const AddRemoveFavorite = ({
  isFavorite,
  id,
  onSuccess,
  noBackground = false,
  type,
  addLogs,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { from, sourcePage } = history.location.state ?? { from: '/' };

  const resetLoading = () => setLoading(false);
  const handleFavorite = (action, id) => {
    setLoading(true);
    dispatch({
      type:
        action === "add"
          ? "favorites/ADD_FAVORITE"
          : "favorites/REMOVE_FAVORITE",
      payload: {
        id,
        type,
      },
      onSuccess,
      resetLoading,
    });
    if(action === "add" && type === "contents") {
      addLogs(from ?? sourcePage);
    }
  };
  return (
    <div
      className={`add-to-list ${noBackground ? "add-to-list--no-bg" : ""} ${
        isFavorite
          ? "add-to-list--clicked"
          : noBackground
          ? ""
          : "add-to-list--animated"
      }${loading ? " btn--is-loading" : ""}`}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        isFavorite ? handleFavorite("remove", id) : handleFavorite("add", id);
      }}
    >
      <div className="add-to-list__icon"></div>
      <div className="add-to-list__text">{t("add to favorites")}</div>
      <div className="add-to-list__text-hover">
        {t("remove from my favorites")}
      </div>
      <div className="add-to-list__text-clicked">{t("saved")}</div>
    </div>
  );
};

export default AddRemoveFavorite;
