import { ContentCard, ShimmerWrapper, Swiper } from "components";
import { ShimmerContentCard } from "components/Shimmers";
import { sources } from "data/eventsLog";
import { parseDurationToSeconds } from "utils/functions";

const Section = ({ id, title, courses }) => {
  const data =
    id === "recommandations" ? courses?.data?.slice(1) : courses?.data;
  if (!courses?.loading && !courses?.error && courses?.data?.length === 0) {
    return <></>;
  }
  return (
    <section id={id} className="section section--courses-row">
      <div className="container">
        {(courses?.loading || courses?.error || courses?.data?.length > 0) && (
          <>
            <h2 className="section__title">
              {title || id.charAt(0).toUpperCase() + id?.slice(1)}
            </h2>
            {id === "recommandations" && (
              <h3 className="section__subtitle">Recommended courses</h3>
            )}
          </>
        )}
        <ShimmerWrapper
          loading={courses?.loading || courses?.error}
          shimmerRender={<ShimmerContentCard />}
          ShimmerLayout={({ children }) => (
            <div className="courses-grid">
              <div className="row">{children}</div>
            </div>
          )}
        >
          {!courses?.loading && (
            <div className="courses-row">
              <div className="courses-row__inner">
                <Swiper>
                  {data.map(
                    ({
                      content_id,
                      __t,
                      interval_duration,
                      content_name,
                      description,
                      therapeuticArea,
                      thumbnail,
                      added_to_favorite,
                      percentage_viewed,
                      pages_number,
                      channel,
                      tags,
                      nb_views,
                      speciality,
                      duration,
                      promomat,
                      publishing_date,
                    }) => {
                      const infoContent = {
                        speciality: speciality?.name,
                        tags: tags,
                        nbViews: nb_views,
                        channelName: channel?.playlist_name,
                        areaName: therapeuticArea?.playlist_name,
                        percentageViewed: parseInt(percentage_viewed || 0),
                        promomat,
                      }
                      const isVideo = __t === "VideoAdvent";
                      if (isVideo) {
                        infoContent.duration = parseDurationToSeconds(duration);
                      } else {
                        infoContent.pagesNumber = pages_number;
                      }
                      return (
                        <div key={content_id} className="swiper-slide">
                          <ContentCard
                            content_id={content_id}
                            isVideo={isVideo}
                            intervalDuration={interval_duration}
                            name={content_name}
                            description={description}
                            therapeuticArea={therapeuticArea}
                            thumbnail={thumbnail}
                            isFavorite={added_to_favorite}
                            percentageViewed={percentage_viewed}
                            infoContent={infoContent}
                            source={sources.homePage}
                            playlistId={therapeuticArea?.playlist_id}
                            publishing_date={publishing_date}
                          />
                        </div>
                      )
                    }
                  )}
                </Swiper>
              </div>
            </div>
          )}
        </ShimmerWrapper>
      </div>
    </section>
  );
};

export default Section;
