import { Layout } from "components";
import { useTranslation } from "react-i18next";

const NotFound = ({ location }) => {
  const { t } = useTranslation();
  const defaultMessage = t('not found');
  const message = location.state ? location.state.message : defaultMessage;
  return (
    <Layout hasContainer name="not-found">
      <div className="no-result-container">
        <div className="no-results">
          <div className="no-results__status">
            <div>404</div>
          </div>
          <div className="no-results__text">
            <p>
              {message}
            </p>
          </div>
          <div className="no-results__cta">
            <a href="/" type="submit" className="btn btn-primary rounded-pill">{t('return to home')}</a>
          </div>
        </div>
      </div>
    </Layout>

  );
};

export default NotFound;
