export const therapAreaTab = {
  namespace: "therapeuticAreas",
  title: "Therapeutic Areas",
};
export const eventsTab = {
  namespace: "events",
  title: "Events",
};
export const coursesTab = {
  namespace: "courses",
  title: "Educational Resources",
};
export const expertsTab = {
  namespace: "experts",
  title: "Experts",
};
