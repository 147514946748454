import { t } from "i18next";

export const therapAreaFilter = {
  namespace: "therapArea",
  title: "Therapeutic Area",
  options: [
    { name: "Respiratory", value: "respiratory" },
    { name: "Dermatology", value: "dermatology" },
  ],
};

export const formatFilter = {
  namespace: "format",
  title: t("format"),
  options: [
    { name: t("video"), value: "video" },
    { name: t("pdf"), value: "pdf" },
  ],
};

export const typeFilter = {
  namespace: "type",
  title: t("type"),
  options: [
    { name: t("educational ressources"), value: "contents" },
    { name: t("events"), value: "events" },
  ],
};

export const lengthFilter = {
  namespace: "length",
  title: "Length",
  options: [
    { name: "Short (<5 minutes)", value: "short" },
    { name: "Medium (5-15 minutes)", value: "medium" },
    { name: "Long (>15 minutes)", value: "long" },
  ],
};
