/* eslint-disable jsx-a11y/heading-has-content */
const ShimmerHero = () => {
  return (
    <>
      <h1 className="shimmer-home-hero__title"></h1>
      <h2 className="shimmer-home-hero__category"></h2>
      <p className="shimmer-home-hero__doctor-name"></p>
      <div className="shimmer-home-hero__btns"></div>
    </>
  );
};

export default ShimmerHero;
