import axios from "axios";
import config from "../config";
import { getToken, logout } from "../utils/login";

const url = config.app.apiUrlV1;

// function that create the api service
const create = (baseURL = url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const api = axios.create({
    baseURL,
    headers,
  });

  api.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      return response;
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      const { status } = error?.response;
      if (status === 401) {
        logout();
      }
      return Promise.reject(error);
    }
  );
  api.interceptors.request.use((confi) => {
    const conf = confi;
    conf.headers.Authorization = getToken();
    return conf;
  });

  // Settings
  const getSettings = () => {
    return api.get(`/settings`);
  };
  const updateProfile = (data) => {
    return api.put(`/settings/profile`, data);
  };
  const updatePassword = (data) => {
    return api.put(`/settings/password`, data);
  };

  const getSpecialities = () => {
    return api.get("/referential/specialities");
  };
  const getProvinces = () => {
    return api.get("/referential/provinces");
  };

  // User
  const getCurrentUser = () => {
    return api.get("/settings/current_user");
  };

  return {
    getSettings,
    updateProfile,
    updatePassword,
    getCurrentUser,
    getSpecialities,
    getProvinces,
  };
};

const apiV1 = { create };

export default apiV1;
