/* eslint-disable import/no-anonymous-default-export */
import { api } from "services/apiInstances";
import parameters from "config/parameters";

const initialState = {
  ongoingEvents: {
    data: [],
    error: false,
    loading: true,
  },
  upcomingEvents: {
    data: [],
    error: false,
    loading: true,
  },
  passedEvents: {
    data: [],
    error: false,
    loading: true,
    page: 1,
    maxPage: 1,
  },
  internetError: false,
};
const events = {
  namespace: "events",
  state: { ...initialState },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    resetState() {
      return { ...initialState };
    },
  },
  effects: {
    *GET_ONGOING_EVENTS(_, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        const events = yield select((state) => state.events);
        const { ongoingEvents } = events;
        yield put({
          type: "setState",
          payload: {
            ongoingEvents: {
              ...ongoingEvents,
              loading: true,
            },
          },
        });

        try {
          const response = yield call(api.getOngoingEvents);
          if (response?.status === 200) {
            // non empty list
            yield put({
              type: "setState",
              payload: {
                ongoingEvents: {
                  ...ongoingEvents,
                  loading: false,
                  data: response.data.data,
                },
              },
            });
          }
        } catch (error) {
          yield put({
            type: "setState",
            payload: {
              ongoingEvents: {
                ...ongoingEvents,
                loading: false,
                error: true,
              },
            },
          });
        }
      }
    },
    *GET_UPCOMING_EVENTS(_, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        const events = yield select((state) => state.events);
        const { upcomingEvents } = events;
        yield put({
          type: "setState",
          payload: {
            upcomingEvents: {
              ...upcomingEvents,
              loading: true,
            },
          },
        });

        try {
          const response = yield call(api.getUpcomingEvents);
          if (response?.status === 200) {
            // non empty list
            yield put({
              type: "setState",
              payload: {
                upcomingEvents: {
                  ...upcomingEvents,
                  loading: false,
                  data: response.data.data,
                },
              },
            });
          }
        } catch (error) {
          yield put({
            type: "setState",
            payload: {
              upcomingEvents: {
                ...upcomingEvents,
                loading: false,
                error: true,
              },
            },
          });
        }
      }
    },
    *GET_PASSED_EVENTS({ payload }, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        const events = yield select((state) => state.events);
        const { passedEvents } = events;
        yield put({
          type: "setState",
          payload: {
            passedEvents: {
              ...(payload.loadMore ? passedEvents : initialState.passedEvents),
              loading: true,
              error: false,
            },
          },
        });

        const params = {
          pageNb: payload.loadMore ? passedEvents.page : 1,
          pageSize: parameters.pageSize,
          therapeutic: payload?.therapeutic,
        };

        try {
          const response = yield call(api.getPassedEvents, params);
          if (response?.status === 200) {
            const result = payload.loadMore
              ? {
                  page: passedEvents.page + 1,
                  data: [...passedEvents.data, ...response.data.data],
                }
              : {
                  page: 2,
                  data: response.data.data,
                };
            yield put({
              type: "setState",
              payload: {
                passedEvents: {
                  loading: false,
                  error: false,
                  maxPage: response.data.max_pages,
                  ...result,
                },
              },
            });
          }
        } catch (error) {
          yield put({
            type: "setState",
            payload: {
              passedEvents: {
                ...passedEvents,
                loading: false,
                error: true,
              },
            },
          });
        }
      }
    },
    *UPDATE_FAVORITE_EVENT({ payload }, { select, put, all }) {
      const state = yield select((state) => state.events);
      const { ongoingEvents, upcomingEvents, passedEvents } = state;
      const { event_id, added_to_favorite } = payload;

      const sectionsToUpdate = (sections, event_id) =>
        sections.filter((section) =>
          section.data.find((event) => event.playlist_id === event_id)
        );
      yield all(
        sectionsToUpdate(
          [ongoingEvents, upcomingEvents, passedEvents],
          event_id
        ).map((section) => {
          const course = section.data.find(
            (event) => event.playlist_id === event_id
          );
          course.added_to_favorite = added_to_favorite;
          return put({
            type: "setState",
            payload: {
              [section]: {
                loading: false,
                error: false,
                data: section.data.map((event) => {
                  if (event.playlist_id === event_id)
                    return { ...event, added_to_favorite };
                  return event;
                }),
              },
            },
          });
        })
      );
    },
    *RESET(_, { put }) {
      yield put({
        type: "resetState",
      });
    },
  },
};

export default events;
