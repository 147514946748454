/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable jsx-a11y/heading-has-content */
import routesList from "routes/routesList";

const ShimmerEvent = ({ type }) => {
  const isSearch = window.location.pathname === routesList.search;

  return (
    <div
      className={`${isSearch ? "event-card" : ""} shimmer-event-card ${
        type ? `shimmer-event-card--${type}` : ""
      } ${type === "banner" ? "event-card--banner" : ""}`}
    >
      <div
        className={`shimmer-event-card__thumb ${
          type === "banner" ? "event-card__thumb-container" : ""
        }`}
      ></div>
      <div className="event-card__body">
        {type && type !== "vertical" && (
          <div className="shimmer-event-card__date"></div>
        )}
        <h2 className="shimmer-event-card__title"></h2>
        <p className="shimmer-event-card__description"></p>
        <p className="shimmer-event-card__description"></p>
        <div className="shimmer-event-card__badges">
          <div className="shimmer-badge rounded-pill"></div>
        </div>
      </div>
    </div>
  );
};

export default ShimmerEvent;
