import moment from "moment";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

import routesList from "routes/routesList";

const Footer = () => {
  const { privacyPolicy, termsOfUse, contact } = routesList;
  const history = useHistory();
  const state = { from: history.location.pathname };
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__inner">
          <div className="footer__brand">
            <div className="footer__sanofi-logo mx-md-0">
              <img
                src={require("assets/img/Logo_Sanofi.svg").default}
                alt="/"
              />
            </div>
            <p>
              Advent is a medical educational platform by Sanofi and Regeneron,
              This Website is intended only for duly authenticated health care
              professionals in Algeria Sanofi Regeneron are committed to providing 
              resources to advance research in areas of unmet medical need among 
              patients with inflammatory and immunologic diseases.
            </p>
          </div>

          <p >
            MAT-DZ-2201392-V0.1-11/2022
          </p>

          <div className="footer__navcopy">
            <div className="footer__copy">
              <p className="m-0">
                Copyright Sanofi Algérie {moment().year()} - tous droits
                réservés
              </p>
            </div>


            <ul className="footer__nav">
              <li className="footer__item mr-4">
                <NavLink
                  to={{ pathname: privacyPolicy, state }}
                  className="footer__link text-decoration-none"
                >
                  Privacy Policy
                </NavLink>
              </li>
              <li className="footer__item mr-4">
                <NavLink
                  to={{ pathname: termsOfUse, state }}
                  className="footer__link text-decoration-none"
                >
                  Terms of use
                </NavLink>
              </li>
              <li className="footer__item">
                <NavLink
                  to={{ pathname: contact, state }}
                  className="footer__link text-decoration-none text-nowrap"
                >
                  Contact
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
