/* eslint-disable no-shadow */
import React from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik, ErrorMessage } from "formik";
import { t } from "i18next";
import regex from "utils/regex";
import titreHcp from "./titreHcp";

const PersonalInfoForm = ({
  currentData,
  specialities,
  provinces,
  loading,
  error,
  isFetchingEssentials,
  setShowModal,
}) => {
  const { t: _ } = useTranslation();
  const dispatch = useDispatch();

  const settings = useSelector((state) => state.settings);
  const { updating } = settings.personalInfo;

  const {
    title,
    last_name,
    first_name,
    speciality,
    sector,
    email,
    phone_number,
    province,
    is_doctor,
    bio,
  } = currentData;

  return (
    <div className="settings__section">
      <h2 className="settings__section-title">
        {_("Informations personnelles")}
      </h2>
      <Formik
        enableReinitialize
        initialValues={{
          title: title || "",
          last_name: last_name || "",
          first_name: first_name || "",
          speciality: speciality ? speciality.name : "",
          sector: sector ? sector.toLowerCase() : "",
          email: email || "",
          phone: phone_number || "",
          province: province ? province.name : "",
          is_doctor: is_doctor || true,
          bio: bio || "",
        }}
        validationSchema={Yup.object({
          last_name: Yup.string()
            .min(1, t("Nom doit être au bon format"))
            .max(40, t("Nom doit être au bon format"))
            .matches(regex.name, t("Nom doit être au bon format"))
            .required(t("Champ requis")),
          first_name: Yup.string()
            .min(1, t("Prénom doit être au bon format"))
            .max(40, t("Prénom doit être au bon format"))
            .matches(regex.name, t("Prénom doit être au bon format"))
            .required(t("Champ requis")),
          speciality: Yup.string().required(t("Champ requis")),
          sector: Yup.string()
            .oneOf(
              ["private", "public"],
              t("Prière de choisir votre secteur d'activité")
            )
            .required(t("Champ requis")),
          phone: Yup.string()
            .matches(regex.phone, t("Mobile doit être au bon format"))
            .required(t("Champ requis")),
          province: Yup.string().required(t("Champ requis")),
          title: Yup.string().required(t("Champ requis")),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          dispatch({
            type: "settings/UPDATE_PERSONAL_INFO",
            payload: { data: values, setShowModal },
          });
        }}
      >
        {(formik) => {
          return (
            <form onSubmit={formik.handleSubmit}>
              <div className="row mb-md-3">
                <div className=" col-md-6">
                  <div className="form-group ">
                    <select
                      id="title"
                      name="title"
                      disabled={updating || loading || error}
                      className={`form-select rounded-pill${
                        formik.errors.title && formik.touched.title
                          ? " is-invalid"
                          : ""
                      }`}
                      value={formik.values.title || ""}
                      {...formik.getFieldProps("title")}
                    >
                      <option value="">{_("Titre")}</option>
                      {titreHcp.map((titre) => (
                        <option value={titre} key={titre}>
                          {_(titre)}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="title">{_("Titre")}</label>
                    <ErrorMessage
                      name="title"
                      component="div"
                      className="ml-3 invalid-feedback"
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-md-2">
                <div className="col-md-6">
                  <div className="form-group ">
                    <input
                      type="text"
                      className={`form-control rounded-pill${
                        formik.errors.first_name && formik.touched.first_name
                          ? " is-invalid"
                          : ""
                      }`}
                      id="first_name"
                      name="first_name"
                      placeholder={_("Prénom")}
                      disabled={updating || loading || error}
                      {...formik.getFieldProps("first_name")}
                    />
                    <label htmlFor="first_name">{_("Prénom")}</label>

                    <ErrorMessage
                      name="first_name"
                      component="div"
                      className="ml-3 invalid-feedback"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <input
                      type="text"
                      className={`form-control rounded-pill${
                        formik.errors.last_name && formik.touched.last_name
                          ? " is-invalid"
                          : ""
                      }`}
                      id="last_name"
                      name="last_name"
                      placeholder={_("Nom")}
                      disabled={updating || loading || error}
                      {...formik.getFieldProps("last_name")}
                    />
                    <label htmlFor="last_name">{_("Nom")}</label>

                    <ErrorMessage
                      name="last_name"
                      component="div"
                      className="ml-3 invalid-feedback"
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-md-2">
                <div className="col-md-6">
                  <div className="form-group ">
                    <select
                      id="speciality"
                      name="speciality"
                      className={`form-select rounded-pill${
                        formik.errors.speciality && formik.touched.speciality
                          ? " is-invalid"
                          : ""
                      }`}
                      {...formik.getFieldProps("speciality")}
                      disabled={updating || loading || error}
                      value={formik.values.speciality || ""}
                    >
                      {/* <option value="">{_("Specialité")}</option> */}
                      {specialities &&
                        specialities.map((specialty) => (
                          <option value={specialty.name} key={specialty._id}>
                            {specialty.name}
                          </option>
                        ))}
                    </select>
                    <label htmlFor="speciality">{_("Specialité")}</label>
                    <ErrorMessage
                      name="speciality"
                      component="div"
                      className="ml-3 invalid-feedback"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <select
                      id="sector"
                      name="sector"
                      className={`form-select rounded-pill${
                        formik.errors.sector && formik.touched.sector
                          ? " is-invalid"
                          : ""
                      }`}
                      {...formik.getFieldProps("sector")}
                      disabled={updating || loading || error}
                      value={formik.values.sector || ""}
                    >
                      <option value="public">{_("public")}</option>
                      <option value="private">{_("private")}</option>
                      <option value="">{_("Secteur")}</option>
                    </select>
                    <label htmlFor="sector">{_("Secteur")}</label>
                    <ErrorMessage
                      name="sector"
                      component="div"
                      className="ml-3 invalid-feedback"
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-md-2">
                <div className="col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="form-control rounded-pill"
                      placeholder="Email"
                      {...formik.getFieldProps("email")}
                      disabled
                    />
                    <label htmlFor="email">Email</label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group ">
                    <input
                      type="text"
                      className={`form-control rounded-pill${
                        formik.errors.phone && formik.touched.phone
                          ? " is-invalid"
                          : ""
                      }`}
                      id="phone"
                      name="phone"
                      placeholder={_("Mobile")}
                      {...formik.getFieldProps("phone")}
                      disabled={updating || loading || error}
                    />
                    <label htmlFor="phone">{_("Mobile")}</label>

                    <ErrorMessage
                      name="phone"
                      component="div"
                      className="ml-3 invalid-feedback"
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-md-3">
                <div className="col-md-6">
                  <div className="form-group ">
                    <select
                      id="province"
                      name="province"
                      className={`form-select rounded-pill${
                        formik.errors.province && formik.touched.province
                          ? " is-invalid"
                          : ""
                      }`}
                      {...formik.getFieldProps("province")}
                      disabled={updating || loading || error}
                      value={formik.values.province || ""}
                    >
                      <option value="">{_("Wilaya")}</option>
                      {provinces &&
                        provinces.map((province) => (
                          <option value={province.name} key={province._id}>
                            {province.name}
                          </option>
                        ))}
                    </select>
                    <label htmlFor="province">{_("Wilaya")}</label>
                    <ErrorMessage
                      name="province"
                      component="div"
                      className="ml-3 invalid-feedback"
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-md-12">
                  <div className="form-floating">
                    <textarea
                      className="form-control"
                      placeholder="Biography"
                      id="bio"
                      style={{ height: 170 }}
                      {...formik.getFieldProps("bio")}
                      disabled={updating || loading || error}
                    ></textarea>
                    <label htmlFor="bio">Biography</label>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-center">
                <button
                  type="submit"
                  className={`btn btn-primary cta-rounded ${
                    updating || loading ? "btn--is-loading" : null
                  }`}
                  disabled={
                    isFetchingEssentials || loading || updating || error
                  }
                >
                  <span className="btn__text">{_("Modifier")}</span>
                  <span className="btn__spinner">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {_("Modifier")}
                    ...
                  </span>
                </button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default PersonalInfoForm;
