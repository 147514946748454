/* eslint-disable import/no-anonymous-default-export */
import { api } from "services/apiInstances";
import { clearData } from "utils/logs";


const logs = {
  namespace: "logs",
  state: {},
  reducers: {},
  effects: {
    *SEND_DATA(_, { select, put, call }) {
      let data = [];
      if (localStorage.getItem("logs"))
        data = JSON.parse(localStorage.getItem("logs"));
      if (data.length > 0) {
        const body = {
          logs: data,
        };
        try {
          const response = yield call(api.sendDataLogs, body);
          if (response?.status === 200) {
            clearData(data);
          }
        } catch (error) {
          console.log("error :>> ", error);
        }
      }
    },

  },
};

export default logs;
