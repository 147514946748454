import { api } from "services/apiInstances";
import parameters from "config/parameters";

const initialState = {
  playlistContents: {
    data: [],
    error: false,
    notFound: false,
    loading: true,
    page: 1,
    maxPage: 1,
  },
  playlistDetail: {
    data: {},
    error: false,
    notFound: false,
    loading: true,
  },
  internetError: false,
};
const expertProfileModel = {
  namespace: "expertProfile",
  state: { ...initialState },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    resetState() {
      return { ...initialState };
    },
  },
  effects: {
    *GET_PLAYLIST_CONTENTS({ payload }, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        const { playlistContents } = yield select(
          (state) => state.expertProfile
        );
        const params = {
          pageNb: payload.loadMore ? playlistContents.page : 1,
          pageSize: parameters.pageSize,
        };
        yield put({
          type: "setState",
          payload: {
            playlistContents: {
              ...playlistContents,
              data: payload.loadMore ? playlistContents.data : [],
              loading: true,
            },
          },
        });

        try {
          const response = yield call(
            api.getPlaylistContents,
            payload.id,
            params
          );
          if (response?.status === 200) {
            yield put({
              type: "setState",
              payload: {
                playlistContents: {
                  loading: false,
                  data: payload.loadMore
                    ? [...playlistContents.data, ...response.data.data]
                    : response.data.data,
                  maxPage: response.data.max_pages,
                  page: payload.loadMore ? playlistContents.page + 1 : 2,
                },
              },
            });
          }
        } catch (error) {
          const errorField =
            error?.response?.status === 404 ? "notFound" : "error";
          yield put({
            type: "setState",
            payload: {
              playlistContents: {
                ...playlistContents,
                loading: false,
                [errorField]: true,
              },
            },
          });
        }
      }
    },
    *GET_PLAYLIST_DETAIL({ payload }, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        const { playlistDetail } = yield select((state) => state.expertProfile);
        yield put({
          type: "setState",
          payload: {
            playlistDetail: {
              data: {},
              loading: true,
              error: false,
              notFound: false,
            },
          },
        });

        try {
          const response = yield call(() => api.getPlaylistDetail(payload.id));
          if (response?.status === 200) {
            // non empty list
            yield put({
              type: "setState",
              payload: {
                playlistDetail: {
                  loading: false,
                  data: response.data.data,
                },
              },
            });
          }
        } catch (error) {
          const errorField =
            error?.response?.status === 404 ? "notFound" : "error";
          yield put({
            type: "setState",
            payload: {
              playlistDetail: {
                ...playlistDetail,
                loading: false,
                [errorField]: true,
              },
            },
          });
        }
      }
    },
    *RESET(_, { put }) {
      yield put({
        type: "resetState",
      });
    },
  },
};

export default expertProfileModel;
