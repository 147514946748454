/* eslint-disable jsx-a11y/heading-has-content */
import React from "react";

const ExpertShimmer = () => {
  return (
    <div className="col-md-6 col-lg-3">
      <div className="shimmer-expert-item expert-item">
        <div className="shimmer-expert-item__img"></div>
        <div className="shimmer-expert-item__content mt-4">
          <h5 className="shimmer-expert-item__name"></h5>
          <div className="expert-item__info">
            <p className="shimmer-expert-item__info  m-0"></p>
            <div className="expert-item__point mx-3"></div>
            <p className="m-0 shimmer-expert-item__info"></p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertShimmer;
