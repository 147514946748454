import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import Expert from "./Expert";
import ExpertShimmer from "./ExpertShimmer";
import {
  ShimmerWrapper,
  ServerError,
  NoResult,
  InternetError,
} from "components";
const Experts = () => {
  const dispatch = useDispatch();
  const { experts } = useSelector((state) => state.experts);
  const { data, loading, page, maxPage, error, internetError } = experts;

  const hasNextPage = page <= maxPage;
  const handleLoadMore = () => {
    if (hasNextPage) {
      dispatch({
        type: "experts/GET_EXPERTS",
        payload: {
          loadMore: true,
        },
      });
    }
  };
  useEffect(() => {
    dispatch({
      type: "experts/GET_EXPERTS",
      payload: {
        loadMore: false,
      },
    });
    return () => {
      dispatch({ type: "experts/RESET" });
    };
  }, [dispatch]);

  return (
    <>
      {internetError ? (
        <InternetError />
      ) : error ? (
        <ServerError />
      ) : (
        <>
          {!loading && !error && data?.length === 0 ? (
            <>
              <div className="divider"></div>
              <NoResult />
            </>
          ) : (
            <>
              <ShimmerWrapper
                loading={loading}
                shimmerRender={<ExpertShimmer />}
                ShimmerLayout={({ children }) => (
                  <div className="row">{children}</div>
                )}
                large={data?.length === 0}
              >
                {data?.length > 0 ? (
                  <InfiniteScroll
                    dataLength={data?.length} //This is important field to render the next data
                    next={handleLoadMore}
                    hasMore={hasNextPage}
                    style={{ overflowX: "hidden", overflowY: "hidden" }}
                  >
                    <div className="row">
                      {data?.map(
                        ({
                          expert_id,
                          expert_name,
                          expert_therapeutic,
                          expert_titles,
                          expert_thumbnail,
                        }) => (
                          <Expert
                            key={expert_id}
                            id={expert_id}
                            name={expert_name}
                            degree={expert_titles?.toString()}
                            speciality={expert_therapeutic?.playlist_name}
                            photo={expert_thumbnail?.file_path}
                          />
                        )
                      )}
                    </div>
                  </InfiniteScroll>
                ) : (
                  !loading && <NoResult />
                )}
              </ShimmerWrapper>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Experts;
