import { Layout } from "components";
import Experts from "./components/Experts";
import Presentation from "./components/Presentation";

const Panel = () => {
  return (
    <Layout name="expert-panel" hasContainer>
      <Presentation />
      <Experts />
    </Layout>
  );
};

export default Panel;
