import { useTranslation } from "react-i18next";
import EventCard from "./components/EventCard";
import MainEvent from "./components/MainEvent";
import { ShimmerWrapper } from "components";
import { ShimmerEvents } from "components/Shimmers";

const Events = ({ events }) => {
  const { t } = useTranslation();
  const mainEvent = events?.data?.[0];
  if (!events?.loading && !events?.error && events?.data?.length === 0) {
    return <></>;
  }
  return (
    <section id="events" className="section section--events-list">
      <div className="container">
        {(events?.loading || events?.error || events?.data?.length > 0) && (
          <h2 className="section__title">
            {t("past and future ADVENT meetings and events")}
          </h2>
        )}
        <div className="events-list events-list--home">
          <ShimmerWrapper
            loading={events?.loading || events?.error}
            shimmerRender={<ShimmerEvents />}
            unique
          >
            {events?.data?.length > 0 && !events?.loading && (
              <>
                <MainEvent
                  id={mainEvent?.playlist_id}
                  thumbnailImage={mainEvent?.thumbnail_image}
                  date={mainEvent?.start_date}
                  title={mainEvent?.playlist_name}
                  therapeuticArea={mainEvent?.therapeutic_area?.playlist_name}
                  description={mainEvent?.description}
                  isUpcoming={mainEvent?.status === 1}
                  endDate={mainEvent?.end_date}
                  hasLive={mainEvent?.has_live}
                  inProgress={mainEvent?.status === 0}
                  nbViews={mainEvent?.live?.nb_watchers}
                  isFavorite={mainEvent?.added_to_favorite}
                />
                <div className="events-list events-list--column">
                  {events?.data
                    .slice(1, 4)
                    .map(
                      (
                        {
                          playlist_id,
                          thumbnail_image,
                          start_date,
                          end_date,
                          playlist_name,
                          therapeutic_area,
                          description,
                          status,
                          has_live,
                          live,
                          added_to_favorite,
                        },
                        i
                      ) => (
                        <EventCard
                          key={i}
                          id={playlist_id}
                          thumbnailImage={thumbnail_image}
                          date={start_date}
                          endDate={end_date}
                          title={playlist_name}
                          therapArea={therapeutic_area?.playlist_name}
                          description={description}
                          isUpcoming={status === 1}
                          hasLive={has_live}
                          inProgress={status === 0}
                          nbViews={live?.nb_watchers}
                          isFavorite={added_to_favorite}
                        />
                      )
                    )}
                </div>
              </>
            )}
          </ShimmerWrapper>
        </div>
      </div>
    </section>
  );
};
export default Events;
