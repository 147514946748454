import { useState } from "react";

const Tab = ({ tabs, children, TabLayout, ChildrenLayout, tabClass }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);
  const handleTabToggle = (id) => {
    setActiveTab(id);
  };
  return (
    <>
      <TabLayout>
        <ul
          className={`tab-custom tab-custom--dark nav nav-tabs ${tabClass}`}
          id="myTab"
          role="tablist"
        >
          {tabs.map(({ id, title }) => (
            <li
              key={id}
              className="nav-item"
              onClick={() => {
                handleTabToggle(id);
              }}
            >
              <a
                className={`nav-link ${activeTab === id && "active"}`}
                data-bs-toggle="tab"
                href={`#${id}`}
              >
                {title}
              </a>
            </li>
          ))}
        </ul>
      </TabLayout>
      <ChildrenLayout>
        <div className="tab-content" id="myTabContent">
          {children}
        </div>
      </ChildrenLayout>
    </>
  );
};

Tab.defaultProps = {
  tabClass: "",
  TabLayout: ({ children }) => {
    return children;
  },
  ChildrenLayout: ({ children }) => {
    return children;
  },
};

export default Tab;
