const common = {
  filters: "Filtres",
  "filter by": "Filtrer par",
  "therapeutic area": "Aire thérapeutique",
  format: "Format",
  "educational ressources": "Ressources pédagogiques",
  type: "Type",
  events: "Événements",
  "clear all filters": "Effacer tous les filtres",
  "add to favorites": "Ajouter aux favoris",
  "remove from my favorites": "Supprimer de mes favoris",
  saved: "Ajoutée",
  video: "Vidéo",
  pdf: "PDF",
  minutes: "minutes",
  "my favorites": "Mes Favoris",
  "consult content": "Consulter le contenu {{frPreposition}}{{name}}",
  page: "Page",
  "see more scientific courses": "Voir plus de cours scientifiques",
  "expert opinion": "Opinion d’Expert",
  description: "Description",
  "supporting material": "Matériau de support",
  tags: "Tags",
  courses: "cours",
  "course added to favorites with success": "Contenu ajouté à vos favoris",
  "course removed from favorites with success":
    "Contenu supprimé de vos favoris",
  "event added to favorites with success": "Événement ajouté à vos favoris",
  "event removed from favorites with success":
    "Événement supprimé de vos favoris",
  "error message: operation failed": "Opération échouée",
  "therapeutic areas": "Aires thérapeutiques",
  search: "recherche",
  "no results for ": "Aucun résultat pour ",
  "check for spelling mistakes or look for something else.":
    "Vérifiez les erreurs d'orthographe ou faîtes une autre recherche.",
  "sort by": "Filtrer par",
  recent: "Récent",
  "number of views": "Nombre de vues",
  upcoming: "À venir",
  "return to home": "Retour à l'accueil",
  retry: "Réessayer",
  "no results for": "Pas de resultats pour",
  "check for spelling mistakes or look for something else":
    "Vérifiez s'il y a des fautes d'orthographe ou recherchez autre chose.",
  "data not found": "Données introuvables",
  "this therapeutic area does not exist":
    "Cette aire thérapeutique n'existe pas",
  "this expert profile does not exist": "Ce profil expert n'existe pas",
  "this course does not exist": "Ce cours n'existe pas",
  "no content available": "Aucun contenu disponible",
  started: "Commencés",
  "discover our latest courses": "Découvrez nos dernières formations",
  "hear from members of the ADVENT Global expert panel and faculty":
    "Écoutez les membres du panel d'experts et de la faculté d'ADVENT Global",
  "past and future ADVENT meetings and events":
    "Réunions et événements ADVENT passés et futurs",
  "upcoming event": "Événement à venir",
  "educational resources": "Ressources éducatives",
  "no educational resources in your favorites":
    "Pas de contenus dans vos favoris",
  "no events in your favorites": "Pas d'événements dans vos favoris",
  "dr - ": "Dr - ",
  live: "Live",
  "ongoing events": "Événements en cours",
  "upcoming events": "Événements à venir",
  "passed events": "Événements passés",
  "discover advent program": "Découvrir le programme Advent",
  "advent program 1": `<span className="bold-text">
    ADVENT est un programme d'éducation médicale
    </span>
    conçu pour faire progresser la compréhension de
    <span className="bold-text">Inflammation de type 2.</span>`,
  "advent program 2": `<span className="bold-text">
    ADVENT est développé en collaboration avec plus de 50
    experts
    </span>
    et relie les derniers
    <span className="bold-text">
    science de l'inflammation de type 2
    </span>
    avec la gestion clinique pour améliorer les soins aux patients.`,
  "this event is coming up on": "Cet événement arrive le",
  "check other events": "Voir les autres événements",
  "related contents": "Contenus associés",
  biography: "Biographie",
  "view the rest of this content": "Voir le reste de ce contenu",
  "shares strategies for ": "partage des stratégies pour ",
  "watch video": "Voir la vidéo",
  "vous êtes sur le point de quitter la platforme éducative advent vers medeo une plateforme promotionnelle.":
    "Vous êtes sur le point de quitter la platforme éducative Advent vers Medeo une plateforme promotionnelle.",
  ok: "Ok",
  pr: "Pr",
  "Nouveau mot de passe": "Nouveau mot de passe",
  "Mise à jour réussie.": "Mise à jour réussie.",
  private: "Privé",
  public: "Public",
  Titre: "Titre",
  Mme: "Mme",
  Mr: "Mr",
  Pr: "Pr",
  Dr: "Dr",
  Prénom: "Prénom",
  Nom: "Nom",
  Specialité: "Specialité",
  Secteur: "Secteur",
  Wilaya: "Wilaya",
  Mobile: "Mobile",
  Modifier: "Modifier",
  "go back": "Retour",
  category: "Catégorie {{category}}",
};

export default common;
