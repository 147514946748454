import DetailContentCard from "./DetailContentCard";
import { Swiper } from "components";
import { parseDurationToSeconds } from 'utils/functions';

const MoreContents = ({ id, contents, isCurrentContent, onFavoriteChange, source }) => {
  const sortedContent = contents.sort((content1, content2) => content1.number - content2.number);
  const currentSlider = sortedContent.findIndex(content => content.content_id === id);
  return (
    <div className="video-row">
      <div className="video-row__inner">
        <Swiper currentSlider={currentSlider}>
          {sortedContent.map(({
            content_id,
            channel,
            added_to_favorite,
            content_name,
            therapeuticArea,
            interval_duration,
            thumbnail,
            __t,
            percentage_viewed,
            pages_number,
            tags,
            nb_views,
            speciality,
            duration,
            promomat,
            publishing_date,
          }) => {
            const infoContent = {
              speciality: speciality?.name,
              tags: tags,
              nbViews: nb_views,
              channelName: channel?.playlist_name,
              areaName: therapeuticArea?.playlist_name,
              percentageViewed: parseInt(percentage_viewed || 0),
              promomat,
            }
            const isVideo = __t === 'VideoAdvent';
            if (isVideo) {
              infoContent.duration = parseDurationToSeconds(duration);
            } else {
              infoContent.pagesNumber = pages_number;
            }
            return (
              <DetailContentCard
                from={`/courses/${id}`}
                key={content_id}
                content_id={content_id}
                name={content_name}
                intervalDuration={interval_duration}
                doctorName={`${channel.user_title}. ${channel.playlist_name}`}
                doctorId={channel.playlist_id}
                isVideo={isVideo}
                thumbnail={thumbnail}
                isPalying={isCurrentContent(content_id)}
                isFavorite={added_to_favorite}
                percentageViewed={percentage_viewed}
                onFavoriteChange={onFavoriteChange}
                infoContent={infoContent}
                source={source}
                playlistId={therapeuticArea?.playlist_id}
                currentContentDisplayed={id}
                publishing_date={publishing_date}
              />
            )
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default MoreContents;
