import { api } from "services/apiInstances";
const initialState = {
  airTherap: {
    data: [],
    error: false,
    loading: true,
  },
  recommendedCourses: {
    data: [],
    error: false,
    loading: true,
  },
  latestCourses: {
    data: [],
    error: false,
    loading: true,
  },
  startedCourses: {
    data: [],
    error: false,
    loading: true,
  },
  events: {
    data: [],
    error: false,
    loading: true,
  },
  testimonials: {
    data: [],
    error: false,
    loading: true,
  },
  favorites: {
    data: [],
    error: false,
    loading: true,
  },
  internetError: false,
};
const home = {
  namespace: "home",
  state: { ...initialState },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    resetState() {
      return { ...initialState };
    },
  },
  effects: {
    *GET_AIR_THERAP(_, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        const home = yield select((state) => state.home);
        const { airTherap } = home;
        yield put({
          type: "setState",
          payload: {
            airTherap: {
              ...airTherap,
              loading: true,
            },
          },
        });

        try {
          const response = yield call(api.getAirTherap);
          if (response?.status === 200) {
            // non empty list
            yield put({
              type: "setState",
              payload: {
                airTherap: {
                  ...airTherap,
                  loading: false,
                  data: response.data.data,
                },
              },
            });
          }
        } catch (error) {
          yield put({
            type: "setState",
            payload: {
              airTherap: {
                ...airTherap,
                loading: false,
                error: true,
              },
            },
          });
        }
      }
    },
    *GET_RECOMMENDED_COURSES(_, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        const home = yield select((state) => state.home);
        const { recommendedCourses } = home;
        yield put({
          type: "setState",
          payload: {
            recommendedCourses: {
              ...recommendedCourses,
              loading: true,
            },
          },
        });

        try {
          const response = yield call(api.getRecommendedCourses);
          if (response?.status === 200) {
            // non empty list
            yield put({
              type: "setState",
              payload: {
                recommendedCourses: {
                  ...recommendedCourses,
                  loading: false,
                  data: response.data.data,
                },
              },
            });
          }
        } catch (error) {
          yield put({
            type: "setState",
            payload: {
              recommendedCourses: {
                ...recommendedCourses,
                loading: false,
                error: true,
              },
            },
          });
        }
      }
    },
    *GET_LATEST_COURSES(_, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        const home = yield select((state) => state.home);
        const { latestCourses } = home;
        yield put({
          type: "setState",
          payload: {
            latestCourses: {
              ...latestCourses,
              loading: true,
            },
          },
        });

        try {
          const response = yield call(api.getLatestCourses);
          if (response?.status === 200) {
            // non empty list
            yield put({
              type: "setState",
              payload: {
                latestCourses: {
                  ...latestCourses,
                  loading: false,
                  data: response.data.data,
                },
              },
            });
          }
        } catch (error) {
          yield put({
            type: "setState",
            payload: {
              latestCourses: {
                ...latestCourses,
                loading: false,
                error: true,
              },
            },
          });
        }
      }
    },
    *GET_STARTED_COURSES(_, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        const home = yield select((state) => state.home);
        const { startedCourses } = home;
        yield put({
          type: "setState",
          payload: {
            startedCourses: {
              ...startedCourses,
              loading: true,
            },
          },
        });

        try {
          const response = yield call(api.getStartedCourses);
          if (response?.status === 200) {
            // non empty list
            yield put({
              type: "setState",
              payload: {
                startedCourses: {
                  ...startedCourses,
                  loading: false,
                  data: response.data.data,
                },
              },
            });
          }
        } catch (error) {
          yield put({
            type: "setState",
            payload: {
              startedCourses: {
                ...startedCourses,
                loading: false,
                error: true,
              },
            },
          });
        }
      }
    },
    *GET_EVENTS(_, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        const home = yield select((state) => state.home);
        const { events } = home;
        yield put({
          type: "setState",
          payload: {
            events: {
              ...events,
              loading: true,
            },
          },
        });

        try {
          const response = yield call(api.getEvents);
          if (response?.status === 200) {
            yield put({
              type: "setState",
              payload: {
                events: {
                  ...events,
                  loading: false,
                  data: response.data.data,
                },
              },
            });
          }
        } catch (error) {
          yield put({
            type: "setState",
            payload: {
              events: {
                ...events,
                loading: false,
                error: true,
              },
            },
          });
        }
      }
    },
    *GET_TESTIMONIALS(_, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        const home = yield select((state) => state.home);
        const { testimonials } = home;
        yield put({
          type: "setState",
          payload: {
            testimonials: {
              ...testimonials,
              loading: true,
            },
          },
        });

        try {
          const response = yield call(api.getTestimonials);
          if (response?.status === 200) {
            // non empty list
            yield put({
              type: "setState",
              payload: {
                testimonials: {
                  ...testimonials,
                  loading: false,
                  data: response.data.data,
                },
              },
            });
          }
        } catch (error) {
          yield put({
            type: "setState",
            payload: {
              testimonials: {
                ...testimonials,
                loading: false,
                error: true,
              },
            },
          });
        }
      }
    },
    *GET_FAVORITES(_, { select, put, call }) {
      const state = yield select((state) => state.home);
      const { favorites } = state;
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        yield put({
          type: "setState",
          payload: {
            favorites: {
              ...initialState.favorites,
              loading: true,
              error: false,
            },
          },
        });
        const action = api.getFavoriteEducationalRessources;
        /*payload.type === "educationalRessources"
            ? api.getFavoriteEducationalRessources
            : api.getFavoriteEvents;*/
        try {
          const response = yield call(action);
          if (response?.status === 200) {
            yield put({
              type: "setState",
              payload: {
                favorites: {
                  data: response.data.data,
                  loading: false,
                  error: false,
                },
              },
            });
          }
        } catch (error) {
          yield put({
            type: "setState",
            payload: {
              favorites: {
                ...favorites,
                loading: false,
                error: true,
              },
            },
          });
        }
      }
    },
    *UPDATE_FAVORITE({ payload }, { select, put, all }) {
      const state = yield select((state) => state.home);
      const { recommendedCourses, latestCourses, startedCourses, favorites } =
        state;
      const { content_id, added_to_favorite } = payload;

      const sectionsToUpdate = (sections, content_id) =>
        sections.filter((section) =>
          section.data.find((content) => content.content_id === content_id)
        );
      yield all(
        sectionsToUpdate(
          [recommendedCourses, latestCourses, startedCourses],
          content_id
        ).map((section) => {
          const course = section.data.find(
            (content) => content.content_id === content_id
          );
          course.added_to_favorite = added_to_favorite;
          return put({
            type: "setState",
            payload: {
              [section]: {
                loading: false,
                error: false,
                data: section.data.map((content) => {
                  if (content.content_id === content_id)
                    return { ...content, added_to_favorite };
                  return content;
                }),
              },
              favorites: {
                loading: false,
                error: false,
                data: added_to_favorite
                  ? [...favorites.data, course]
                  : favorites.data?.filter(
                      (content) => content.content_id !== content_id
                    ),
              },
            },
          });
        })
      );
    },
    *UPDATE_FAVORITE_EVENT({ payload }, { select, put }) {
      const state = yield select((state) => state.home);
      const { events } = state;
      const { event_id, added_to_favorite } = payload;

      yield put({
        type: "setState",
        payload: {
          events: {
            loading: false,
            error: false,
            data: events.data.map((event) => {
              if (event.playlist_id === event_id)
                return { ...event, added_to_favorite };
              return event;
            }),
          },
        },
      });
    },
    *RESET(_, { put }) {
      yield put({
        type: "resetState",
      });
    },
  },
};

export default home;
