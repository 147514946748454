import React from "react";
import { parseNameToInitials } from "utils/functions";

const DoctorCover = ({ name, title }) => {
  const initials = parseNameToInitials(name);
  return (
    <div className="container">
      <div className="doctor-profile-hero">
        <img
          className="doctor-profile-hero__img"
          src="/img/img-respiratory.jpg"
          alt="courerture"
        />
        <div className="doctor-profile-info">
          <div className="doctor-profile-info__inner">
            <div className="doctor-profile-info__avatar-box">
              <span>{initials}</span>
              {/* <img
                className="doctor-profile-info__avatar"
                src="/img/img-doctor-2.png"
                alt="doctor"
              /> */}
            </div>
            <div className="doctor-profile-info__info">
              <div className="doctor-profile-info__detail">
                <h1 className="doctor-profile-info__name">
                  {title}. {name}
                </h1>
                <span className="doctor-profile-info__videos-nbr"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorCover;
