import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Filter from "./Filter";

const FilterBar = ({ filters, filterValues, handleFilter, id }) => {
  const { t } = useTranslation();
  useEffect(() => {
    // Open filters dropdowns on mobile
    const filterDropdownToggleBtns = document.querySelectorAll(
      `.filters #${id}.dropdown-toggle`
    );
    filterDropdownToggleBtns.forEach((elm) => {
      elm.addEventListener("click", function () {
        const currentElm = this;
        currentElm.nextElementSibling.classList.toggle("dropdown-menu--open");

        filterDropdownToggleBtns.forEach((elm) => {
          if (elm !== currentElm) {
            elm.nextElementSibling.classList.remove("dropdown-menu--open");
          }
        });
      });
    });
  }, [id]);

  const handleFilterClear = (e, namespace) => {
    e.preventDefault();
    if (namespace !== "type") handleFilter(e, namespace, "");
  };

  const handleFilterReset = (e) => {
    e.preventDefault();
    handleFilter(e);
  };

  return (
    <div className="filters">
      <nav className="navbar navbar-dark navbar-expand-md  p-0">
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${id}`}
          aria-controls={id}
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
          {t("filters")}
        </button>
        <div className="collapse navbar-collapse" id={id}>
          <div className="filters__title">{t("filter by")}</div>
          <div className="filters__list">
            {filters.map(({ namespace, title, options }) => (
              <Filter
                key={namespace}
                namespace={namespace}
                title={title}
                value={filterValues[namespace]}
                handleFilter={handleFilter}
                handleFilterClear={handleFilterClear}
                options={options}
                id={id}
              />
            ))}
          </div>
          <div className="filters__clear">
            <button
              type="button"
              className="filters__clear-btn"
              onClick={handleFilterReset}
            >
              {t("clear all filters")}
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

FilterBar.defaultProps = {
  id: "dropdownMenuButton1",
};

export default FilterBar;
