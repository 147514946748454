import { api } from "services/apiInstances";

const initialState = {
  expertPanelDetails: {
    data: {},
    error: false,
    notFound: false,
    loading: true,
  },
  internetError: false,
};
const expertPanelDetails = {
  namespace: "expertPanelDetails",
  state: { ...initialState },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    resetState() {
      return { ...initialState };
    },
  },
  effects: {
    *GET_EXPERT_PANEL_DETAILS({ payload }, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        const { expertPanelDetails } = yield select(
          (state) => state.expertPanelDetails
        );
        yield put({
          type: "setState",
          payload: {
            expertPanelDetails: {
              data: {},
              loading: true,
              error: false,
              notFound: false,
            },
          },
        });

        try {
          const response = yield call(() =>
            api.getExpertPanelDetails(payload.id)
          );
          if (response?.status === 200) {
            // non empty list
            yield put({
              type: "setState",
              payload: {
                expertPanelDetails: {
                  loading: false,
                  data: response.data.data,
                },
              },
            });
          }
        } catch (error) {
          const errorField =
            error?.response?.status === 404 ? "notFound" : "error";
          yield put({
            type: "setState",
            payload: {
              expertPanelDetails: {
                ...expertPanelDetails,
                loading: false,
                [errorField]: true,
              },
            },
          });
        }
      }
    },
    *RESET(_, { put }) {
      yield put({
        type: "resetState",
      });
    },
  },
};

export default expertPanelDetails;
