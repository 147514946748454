const Presentation = () => {
  return (
    <div className="mb-5">
      <h1 className="page-title--lg">Advent global expert panel</h1>
      <p className="expert-panel__def">
        The <span className="expert-panel__uppercase">advent</span> program is
        being developed by Sanofi Genzyme and Regeneron in close collaboration
        with the
        <span className="expert-panel__uppercase">advent</span> Global Expert
        Panel, which consists of global experts in Type 2 inflammatory diseases.
      </p>
      <p>
        The <span className="expert-panel__uppercase">advent</span> Global
        Expert Panel :
      </p>
      <ul className="ps-3 expert-panel__nav">
        <li className="mb-1">
          <strong>
            Advise and validate the scientific content of the program.
          </strong>
        </li>
        <li>
          <strong>
            Serve as faculty presenters at the
            <span className="expert-panel__uppercase">advent</span> Forum and
            other live events.
          </strong>
        </li>
      </ul>
      <p>
        Find out more about the members of the Dermatology and Respiratory
        Global Expert Panel below.
      </p>
    </div>
  );
};

export default Presentation;
