import React from "react";
import config from "config";

const LivePlayer = ({
  user,
  email,
  meetingNumber,
  signature,
  closePage
}) => {

  let src = "";
  if (meetingNumber[0].toLowerCase() === "v") {
    src = `https://player.vimeo.com/video/${meetingNumber.substring(1)}`;
  } else {
    src = `${config.app.liveUrl}?user=${encodeURIComponent(user)}&email=${encodeURIComponent(email)}l&meetingNumber=${meetingNumber.substring(1)}&signature=${signature}`;
  }

  return (
    <div className="single-live__video-player-container">
      <div
        className="single-live__video-player video-player video-player--end embed-responsive"
      >
        <iframe
          style={{ border: "none" }}
          title="live"
          src={src}
          height="600px"
          width="100%"
          allowFullScreen
        />
        <div onClick={closePage} className="video-player__close close-icon">
          <div className="close-icon__line" />
          <div className="close-icon__line" />
        </div>
      </div>
    </div>
  );
};

export default LivePlayer;
