import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";

import { Layout } from "components";
import routesList from "routes/routesList";
const Contact = () => {
  const { privacyPolicy } = routesList;
  const history = useHistory();
  const state = { from: history.location.pathname };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, [400]);
  }, []);

  return (
    <Layout name="contact" hasContainer>
      <h1 className="page-title">Contact</h1>

      <p>
        Pour tout problème technique ou toute question relative à l'accès ou à
        l'utilisation de la plateforme advent, contactez-nous aux coordonnées
        suivantes :
      </p>
      <p>
        <a href="mailto:help@advent.dz" className="me-4">
          Mail: help@advent.dz
        </a>
      </p>
      <p>
        Si vous souhaitez rapporter un événement indésirable survenu chez un
        patient traité par un de nos produits, contactez-nous aux coordonnées
        suivantes :
      </p>
      <p className="mb-3">
        <a href="mailto:pharmacovigilance.dz@sanofi.com" className="me-4">
          Mail: pharmacovigilance.dz@sanofi.com
        </a>
        <a href="tel:0982-40-20-20" className="me-4">
          {" "}
          Tél: 0982 40 20 20
        </a>
        <span className="fax me-4">Fax: 023 53 11 63</span>
      </p>
      <p>
        Pour toute demande d’information médicale contactez-nous aux coordonnées
        suivantes :
      </p>
      <p>
        <a href="mailto:infomed-dz@sanofi.com" className="me-4">
          Mail: infomed-dz@sanofi.com
        </a>
        <a href="tel:0982-40-20-20" className="me-4">
          Tél: 0982 40 20 20
        </a>
      </p>
      <p>
        Pour toute information concernant la protection des données à caractère
        personnel, merci de consulter{" "}
        <NavLink to={{ pathname: privacyPolicy, state }}>
          la politique de confidentialité
        </NavLink>{" "}
        du site.
      </p>
    </Layout>
  );
};

export default Contact;
