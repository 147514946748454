import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { ReactNotifications } from "react-notifications-component";
// import "react-notifications-component/dist/theme.css";

import Routes from "routes";
import parameters from "config/parameters";

const App = () => {

  const dispatch = useDispatch();

  const dispatchDataLogs = useCallback(() => {
    if (dispatch) dispatch({ type: 'logs/SEND_DATA'});
  }, [dispatch]);

  useEffect(() => {
    const interval = setInterval(() => {
      dispatchDataLogs();
    }, parameters.eventsWaitings);
    return () => {
      clearInterval(interval);
    };
  }, [dispatchDataLogs]);
  return (
    <>
      <ReactNotifications isMobile />
      <Routes />
    </>
  );
}

export default App;
