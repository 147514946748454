import { api } from "services/apiInstances";
import parameters from "config/parameters";

const initialState = {
  therapAreaContents: {
    data: [],
    error: false,
    notFound: false,
    loading: true,
    page: 1,
    maxPage: 1,
  },
  therapAreaDetail: {
    data: {},
    error: false,
    notFound: false,
    loading: true,
  },
  internetError: false,
};
const therapModel = {
  namespace: "therap",
  state: { ...initialState },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *GET_THERAP_AREA_CONTENTS({ payload }, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        const { therapAreaContents } = yield select((state) => state.therap);

        yield put({
          type: "setState",
          payload: {
            therapAreaContents: {
              ...(payload.loadMore
                ? therapAreaContents
                : initialState.therapAreaContents),
              loading: true,
              error: false,
            },
          },
        });

        const params = {
          pageNb: payload.loadMore ? therapAreaContents.page : 1,
          pageSize: parameters.pageSize,
        };

        try {
          const response = yield call(
            api.getPlaylistContents,
            payload.id,
            params
          );
          if (response?.status === 200) {
            yield put({
              type: "setState",
              payload: {
                therapAreaContents: {
                  loading: false,
                  data: payload.loadMore
                    ? [...therapAreaContents.data, ...response.data.data]
                    : response.data.data,
                  maxPage: response.data.max_pages,
                  page: payload.loadMore ? therapAreaContents.page + 1 : 2,
                },
              },
            });
          }
        } catch (error) {
          const errorField =
            error?.response?.status === 404 ? "notFound" : "error";
          yield put({
            type: "setState",
            payload: {
              therapAreaContents: {
                ...therapAreaContents,
                loading: false,
                [errorField]: true,
              },
            },
          });
        }
      }
    },
    *GET_THERAP_AREA_DETAIL({ payload }, { select, put, call }) {
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            internetError: true,
          },
        });
      } else {
        const { therapAreaDetail } = yield select((state) => state.therap);
        yield put({
          type: "setState",
          payload: {
            therapAreaDetail: {
              data: {},
              loading: true,
              error: false,
            },
          },
        });

        try {
          const response = yield call(() => api.getPlaylistDetail(payload.id));
          if (response?.status === 200) {
            // non empty list
            yield put({
              type: "setState",
              payload: {
                therapAreaDetail: {
                  loading: false,
                  data: response.data.data,
                },
              },
            });
          }
        } catch (error) {
          const errorField =
            error?.response?.status === 404 ? "notFound" : "error";
          yield put({
            type: "setState",
            payload: {
              therapAreaDetail: {
                ...therapAreaDetail,
                loading: false,
                [errorField]: true,
              },
            },
          });
        }
      }
    },
    *UPDATE_FAVORITE({ payload }, { select, put }) {
      const state = yield select((state) => state.therap);
      const { therapAreaContents } = state;
      const { content_id, added_to_favorite } = payload;

      yield put({
        type: "setState",
        payload: {
          therapAreaContents: {
            loading: false,
            error: false,
            data: therapAreaContents.data.map((content) => {
              if (content.content_id === content_id)
                return { ...content, added_to_favorite };
              return content;
            }),
          },
        },
      });
    },
    *RESET(_, { put }) {
      yield put({
        type: "resetState",
      });
    },
  },
};

export default therapModel;
