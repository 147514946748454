import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import routesList from "routes/routesList";

const TherapAreaCard = ({ id, title, coursesNumber }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <article
      className="therapeutic-area-search"
      onClick={() => {
        history.push(`${routesList.therapArea}/${id}`);
      }}
    >
      <div className="therapeutic-area-search__thumb-container">
        <img src="./img/img-speciality-respiratory.jpg" alt="" />
      </div>
      <div className="therapeutic-area-search__info">
        <h2 className="therapeutic-area-search__title">{title}</h2>
        <div className="therapeutic-area-search__videos">
          {coursesNumber} {t("courses")}
        </div>
      </div>
    </article>
  );
};

export default TherapAreaCard;
