/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";

import { Sort, ExpertCard, TherapAreaCard, NoResult } from "./components";
import {
  FilterBar,
  ContentCard,
  ShimmerWrapper,
  InternetError,
  ServerError,
} from "components";
import EventCard from "pages/Home/components/Events/components/EventCard";
import {
  ShimmerContentCard,
  ShimmerSectionTitle,
  ShimmerTherapAreaSearch,
  ShimmerEvent,
  ShimmerExpert,
} from "components/Shimmers";
import { searchTypes } from "data";
import { sources } from "data/eventsLog";
import { parseDurationToSeconds } from "utils/functions";

const TabContent = ({ id, searchValue, isActive, data, filters }) => {
  const dispatch = useDispatch();
  const dispatchData = () => {
    if (isActive) {
      dispatch({
        type: `search/GET_RESULT_${id?.toUpperCase()}`,
        payload: {
          q: searchValue,
          sort,
          type: searchTypes[id],
          therapeutic: filterValues?.therapArea,
          duration: filterValues?.length,
          ctype: filterValues?.format,
        },
      });
    }
  };

  const resetDispatchData = () => {
    dispatch({
      type: "search/resetSpecificState",
      payload: { stateToReset: id },
    });
    dispatchData();
  };

  const initialFilterValues = {
    therapArea: null,
    format: null,
    length: null,
  };
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const handleFilter = ({ nativeEvent: e }, namespace, value) => {
    e.preventDefault();
    if (namespace) {
      if (filterValues[namespace] !== value)
        setFilterValues((prevState) => ({ ...prevState, [namespace]: value }));
    } else {
      setFilterValues({
        therapArea: "",
        format: "",
        length: "",
      });
    }
  };

  const [sort, setSort] = useState("publishing_date");
  const handleSort = (sort) => {
    setSort(sort);
  };

  useEffect(() => {
    if (Object.values(filterValues).some((value) => value !== null))
      resetDispatchData();
  }, [filterValues]);

  useEffect(() => {
    resetDispatchData();
  }, [sort, isActive]);

  useEffect(() => {
    setFilterValues(initialFilterValues);
    if (isActive) {
      dispatch({
        type: "search/resetSpecificState",
        payload: { stateToReset: id },
      });
      dispatch({
        type: `search/GET_RESULT_${id?.toUpperCase()}`,
        payload: {
          q: searchValue,
          type: searchTypes[id],
        },
      });
    }
  }, [searchValue]);

  const hasNextPage = data?.page <= data?.maxPage;

  const ChildComponent = (id, props) => {
    // eslint-disable-next-line default-case
    switch (id) {
      case "experts":
        return (
          <ExpertCard
            key={props?.playlist_id}
            id={props?.playlist_id}
            name={props?.playlist_name}
            speciality={props?.speciality}
          />
        );
      case "therapeuticAreas":
        return (
          <TherapAreaCard
            key={props?.playlist_id}
            id={props?.playlist_id}
            title={props.playlist_name}
            coursesNumber={props?.nb_contents}
          />
        );
      case "courses":
        const infoContent = {
          speciality: props?.speciality?.name,
          tags: props?.tags,
          nbViews: props?.nb_views,
          channelName: props?.channel?.playlist_name,
          areaName: props?.therapeuticArea?.playlist_name,
          percentageViewed: parseInt(props?.percentage_viewed || 0),
          promomat: props?.promomat,
        };
        const isVideo = props?.__t === "VideoAdvent";
        if (isVideo) {
          infoContent.duration = parseDurationToSeconds(props?.duration);
        } else {
          infoContent.pagesNumber = props?.pages_number;
        }
        return (
          <ContentCard
            key={props?.content_id}
            content_id={props?.content_id}
            isVideo={props?.__t === "VideoAdvent" ? true : false}
            duration={props?.duration}
            name={props?.content_name}
            description={props?.description}
            therapeuticArea={props?.therapeuticArea}
            thumbnail={props?.thumbnail}
            intervalDuration={props?.interval_duration}
            isFavorite={props?.added_to_favorite}
            infoContent={infoContent}
            source={sources.search}
            playlistId={props?.therapeuticArea?.playlist_id}
            publishing_date={props?.publishing_date}
            percentageViewed={props?.percentage_viewed}
          />
        );
      case "events":
        return (
          <EventCard
            key={props?.playlist_id}
            id={props?.playlist_id}
            title={props?.playlist_name}
            date={props?.date}
            description={props?.description}
            therapArea={props?.therapeutic_area?.playlist_name}
            thumbnailImage={props?.thumbnail_image}
            isUpcoming={props?.status === 1}
            hasLive={props?.has_live}
            inProgress={props?.status === 0}
            nbViews={props?.live?.nb_watchers || 0}
            isFavorite={props?.added_to_favorite}
          />
        );
    }
  };
  const ShimmerComponent = (id) => {
    // eslint-disable-next-line default-case
    switch (id) {
      case "experts":
        return (
          <>
            <ShimmerExpert />
            <ShimmerExpert />
            <ShimmerExpert />
          </>
        );
      case "therapeuticAreas":
        return (
          <>
            <ShimmerTherapAreaSearch />
            <ShimmerTherapAreaSearch />
            <ShimmerTherapAreaSearch />
          </>
        );
      case "courses":
        return (
          <>
            <ShimmerContentCard />
            <ShimmerContentCard />
            <ShimmerContentCard />
          </>
        );
      case "events":
        return (
          <>
            <ShimmerEvent />
            <ShimmerEvent />
            <ShimmerEvent />
          </>
        );
    }
  };
  return (
    <div
      className={`tab-pane fade ${isActive ? "show active" : ""}`}
      id={id}
      role="tabpanel"
      aria-labelledby={`${id}-tab`}
    >
      {data?.internetError ? (
        <InternetError />
      ) : data?.error ? (
        <ServerError />
      ) : (
        <>
          {filters?.length > 0 &&
            ((!data?.loading && data?.data?.length > 0) ||
              (Object.values(filterValues).some((value) => value !== null) &&
                data?.data?.length === 0)) && (
              <div className="row">
                <div className="offset-xl-1 col-xl-10">
                  <FilterBar
                    filters={filters}
                    filterValues={filterValues}
                    handleFilter={handleFilter}
                  />
                </div>
              </div>
            )}

          {(data?.loading || data?.data?.length > 0) && (
            <div className="row">
              <div className="offset-xl-1 col-xl-10">
                <div className="search-results__sort-row">
                  <ShimmerWrapper
                    loading={data?.loading}
                    shimmerRender={<ShimmerSectionTitle />}
                    unique
                  >
                    {data?.data?.length > 0 && (
                      <div className="search-results__result-nbr">
                        {data?.nb_results} results for{" "}
                        <strong>{searchValue}</strong>
                      </div>
                    )}
                  </ShimmerWrapper>
                  {!data?.loading &&
                    data?.data?.length > 0 &&
                    id === "courses" && (
                      <Sort active={sort} handleSort={handleSort} />
                    )}
                </div>
              </div>
            </div>
          )}

          <ShimmerWrapper
            loading={data?.loading}
            shimmerRender={ShimmerComponent(id)}
            ShimmerLayout={({ children }) => (
              <div className="row">
                <div className="offset-xl-1 col-xl-10">{children}</div>
              </div>
            )}
            unique
          >
            {data?.data?.length > 0 ? (
              <InfiniteScroll
                dataLength={data?.data?.length}
                next={dispatchData}
                hasMore={hasNextPage}
                style={{ overflowX: "hidden", overflowY: "hidden" }}
              >
                <div className="row">
                  <div className="offset-xl-1 col-xl-10">
                    {data?.data.map((props) => ChildComponent(id, props))}
                  </div>
                </div>
              </InfiniteScroll>
            ) : (
              !data?.loading && <NoResult query={searchValue} />
            )}
          </ShimmerWrapper>
        </>
      )}
    </div>
  );
};

export default TabContent;
