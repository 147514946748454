import config from "config";

const DoctorCover = ({ name, title, photo, description, country }) => {
  return (
    <div className="container">
      <div className="doctor-profile-hero">
        <img
          className="doctor-profile-hero__img"
          src="/img/img-respiratory.jpg"
          alt="courerture"
        />
        <div className="doctor-profile-info">
          <div className="doctor-profile-info__inner">
            <div className="doctor-profile-info__avatar-box">
              <img
                className="doctor-profile-info__avatar"
                src={`${config.app.cdnUrl}/${photo}`}
                alt="doctor"
              />
            </div>
            <div className="doctor-profile-info__info">
              <div className="doctor-profile-info__detail">
                <h1 className="doctor-profile-info__name">{name}</h1>
                <span className="doctor-profile-info__videos-nbr">
                  {title}. {description}, {country}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DoctorCover;
