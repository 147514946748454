import { api } from "services/apiInstances";
import parameters from "config/parameters";
const initialState = {
  courses: {
    data: [],
    error: false,
    internetError: false,
    loading: true,
    page: 1,
    maxPage: 1,
  },
};
const courses = {
  namespace: "courses",
  state: { ...initialState },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *GET_COURSES({ payload }, { select, put, call }) {
      const state = yield select((state) => state.courses);
      const { courses } = state;
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            courses: {
              ...courses,
              internetError: true,
            },
          },
        });
      } else {
        yield put({
          type: "setState",
          payload: {
            courses: {
              ...(payload.loadMore ? courses : initialState.courses),
              loading: true,
              error: false,
            },
          },
        });

        const params = {
          pageNb: payload.loadMore ? courses.page : 1,
          pageSize: parameters.pageSize,
          type: payload?.type,
          therapeutic: payload?.therapeutic,
        };

        try {
          const response = yield call(api.getCourses, params);

          if (response?.status === 200) {
            const result = payload.loadMore
              ? {
                  page: courses.page + 1,
                  data: [...courses.data, ...response.data.data],
                }
              : {
                  page: 2,
                  data: response.data.data,
                };
            yield put({
              type: "setState",
              payload: {
                courses: {
                  loading: false,
                  error: false,
                  maxPage: response.data.max_pages,
                  ...result,
                },
              },
            });
          }
        } catch (error) {
          console.log(error);
          yield put({
            type: "setState",
            payload: {
              courses: {
                ...courses,
                loading: false,
                loadingMore: false,
                error: true,
              },
            },
          });
        }
      }
    },
    *UPDATE_FAVORITE({ payload }, { select, put }) {
      const state = yield select((state) => state.courses);
      const { courses } = state;
      const { content_id, added_to_favorite } = payload;

      yield put({
        type: "setState",
        payload: {
          courses: {
            loading: false,
            error: false,
            data: courses.data.map((content) => {
              if (content.content_id === content_id)
                return { ...content, added_to_favorite };
              return content;
            }),
          },
        },
      });
    },
    *RESET(_, { put }) {
      yield put({
        type: "resetState",
      });
    },
  },
};

export default courses;
