import { useEffect } from "react";
import Swiper, { Controller, Navigation, Pagination } from "swiper";

function initSwiper(initialSlide) {
  // Swiper
  // Courses Carousel
  function coursesSwiper(containerName) {
    new Swiper(`${containerName} .swiper`, {
      initialSlide,
      modules: [Navigation],
      slidesPerView: 1.15,
      spaceBetween: 10,
      navigation: {
        nextEl: `${containerName} .swiper-button-next`,
        prevEl: `${containerName} .swiper-button-prev`,
      },
      breakpoints: {
        576: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 24,
        },
        1500: {
          slidesPerView: 4,
          spaceBetween: 24,
        },
      },
    });
  }

  function eventBannerSwiper(containerName) {
    new Swiper(`${containerName} .swiper`, {
      modules: [Navigation],
      slidesPerView: 1,
      spaceBetween: 24,
      navigation: {
        nextEl: `${containerName} .swiper-button-next`,
        prevEl: `${containerName} .swiper-button-prev`,
      },
    });
  }

  coursesSwiper('#recommandations');
  coursesSwiper('#started');
  coursesSwiper('#discover');
  coursesSwiper('#more-scientific-courses');
  coursesSwiper('#similar-courses');
  coursesSwiper('#continue-wartching');
  coursesSwiper('#categories');
  coursesSwiper('#favorites');
  coursesSwiper("#ongoing-events");
  eventBannerSwiper("#upcoming-events");
}

const SwiperComponent = ({ children, currentSlider }) => {
  useEffect(() => {
    initSwiper(currentSlider);
  }, [currentSlider]);
  return (
    <>
      <div className="swiper">
        <div className="swiper-wrapper">
          {children.map((child, i) => (
            <div className="swiper-slide" key={i}>
              {child}
            </div>
          ))}
        </div>
      </div>
      <div className="swiper-button-next"></div>
      <div className="swiper-button-prev"></div>
    </>

  );
};

export default SwiperComponent;
