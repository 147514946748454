import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useHistory } from "react-router-dom";

import config from "config";
import { displayNotificationToast } from "utils/utils";
import { addFavoriLogs } from "utils/logs";
import { actionNames } from "data/eventsLog";

const Hero = ({ id, title, thumbnail, doctor, description, isFavorite, infoContent, source, playlistId }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { from, sourcePage } = history.location.state ?? { from: '/' };

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const resetLoading = () => setLoading(false);
  const onSuccess = (content_id, isFavorite) => {
    dispatch({
      type: "home/UPDATE_FAVORITE",
      payload: {
        content_id,
        added_to_favorite: isFavorite,
      },
    });
    displayNotificationToast(
      isFavorite
        ? t("course added to favorites with success")
        : t("course removed from favorites with success"),
      "success"
    );
  };
  const addLogs = () => {
    addFavoriLogs(
      id,
      title,
      source,
      from ?? sourcePage,
      playlistId,
      null,
      actionNames.content.contentFaved,
      infoContent,
    );
  };
  const handleFavorite = (action, id) => {
    setLoading(true);
    dispatch({
      type:
        action === "add"
          ? "favorites/ADD_FAVORITE"
          : "favorites/REMOVE_FAVORITE",
      payload: {
        id,
        type: "contents",
      },
      onSuccess,
      resetLoading,
    });
    if(action === "add") {
      addLogs(from ?? sourcePage);
    }
  };
  return (
    <>
      <img
        src={`${config.app.cdnUrl}/${thumbnail?.file_path}`}
        alt="/"
        className="hero__bg"
      />
      <div className="hero">
        <div className="container">
          <div className="hero__content">
            <h1 className="hero__title">{title}</h1>
            <h2 className="hero__category">
              {doctor?.user_title?.toString()}. {doctor?.playlist_name}
              {t("shares strategies for ")} {description}
            </h2>
            <div className="hero__buttons">
              <button
                onClick={() => {
                  history.push({
                    pathname: `/courses/${id}`,
                    state: { sourcePage: history.location.pathname },
                  });
                }}
                className="hero__watch-video btn btn-outline-light rounded-pill"
              >
                <span className="hero__watch-video-icon"></span>
                <img
                  src={require("assets/img/play_arrow-24px.svg").default}
                  alt="/"
                />
                <span className="hero__watch-video-text">
                  {t("watch video")}
                </span>
              </button>
              <button
                className={`hero__add-to-list btn rounded-circle ${
                  isFavorite ? "hero__add-to-list--clicked" : ""
                } ${loading ? " btn--is-loading" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  isFavorite
                    ? handleFavorite("remove", id)
                    : handleFavorite("add", id);
                }}
              >
                <span className="hero__add-to-list-icon"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
