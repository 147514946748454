/* eslint-disable jsx-a11y/heading-has-content */
const ShimmerAreaCover = () => {
  return (
    <div className="container">
      <div className="doctor-profile-hero">
        <div className="doctor-profile-hero__img shimmer-expert-profile-hero__img"></div>
        <div className="doctor-profile-info">
          <div className="doctor-profile-info__inner">
            <div className="doctor-profile-info__info">
              <div className="doctor-profile-info__detail">
                <h1 className="doctor-profile-info__name shimmer-expert-profile-info__name"></h1>
                <span className="doctor-profile-info__videos-nbr shimmer-expert-profile-info__videos-nbr"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShimmerAreaCover;
