import { useTranslation } from "react-i18next";

const GoBackButton = ({ closePage }) => {
  const { t } = useTranslation();

  return (
    <button className="btn return-btn" onClick={() => closePage()}>
      <img
        src={require("assets/img/icons/icn_arrow_left.svg").default}
        alt=""
        className="return-btn__icon"
      />
      <span className="return-btn__text">{t("go back")}</span>
    </button>
  );
};

export default GoBackButton;
