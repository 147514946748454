import config from "config";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AddRemoveFavorite } from "components";
import { pdfDurations } from "data";
import { addFavoriLogs } from "utils/logs";
import { actionNames } from "data/eventsLog";
import { getTimeFromNow } from "utils/functions";

const DetailContentCard = ({
  from,
  content_id,
  name,
  doctorId,
  doctorName,
  isVideo,
  thumbnail,
  isPalying,
  isFavorite,
  percentageViewed,
  onFavoriteChange,
  intervalDuration,
  infoContent,
  source,
  playlistId,
  currentContentDisplayed,
  publishing_date,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const historyState = { ...history.location.state };
  if (!history.location?.state?.from) {
    historyState.from = from;
  }

  const openCourseDetails = () => {
    history.push({
      pathname: `/courses/${content_id}`,
      state: historyState,
    });
  };

  const addLogs = (from) => {
    addFavoriLogs(
      content_id,
      name,
      source,
      from,
      playlistId,
      currentContentDisplayed,
      actionNames.content.contentFaved,
      infoContent
    );
  };

  return (
    <article
      className={`course-card course-card--transparent ${
        isPalying && "course-card--is-playing"
      }`}
      onClick={openCourseDetails}
    >
      <div className="course-card__thumb-container">
        <img
          className="course-card__thumb-img"
          src={`${config.app.cdnUrl}/${thumbnail?.file_path}`}
          alt="/"
        />
        {percentageViewed !== "0%" && (
          <div className="course-card__progress-container">
            <div
              className="course-card__progress"
              style={{ width: percentageViewed }}
            ></div>
          </div>
        )}
        <div className="course-card__time">
          <img
            src={require("assets/img/icons/icn_clock.svg").default}
            alt="/"
          />
          {pdfDurations[intervalDuration]}
        </div>
        <AddRemoveFavorite
          isFavorite={isFavorite}
          id={content_id}
          onSuccess={onFavoriteChange}
          type="contents"
          addLogs={addLogs}
        />
      </div>

      <div className="course-card__body">
        <div className="course-card__info">
          <h2 className="course-card__title" title={name}>
            <NavLink to="/">{name}</NavLink>
          </h2>
          <div className="course-card__date">
            {getTimeFromNow(publishing_date)}
          </div>

          <h3 className="course-card__doctor-name">
            <a href={`/expert/${doctorId}`}>{doctorName}</a>
          </h3>
        </div>
        <div className="course-card__badges">
          {isVideo ? (
            <div className="badge badge--video rounded-pill">
              <img
                className="badge__icon"
                src="/img/icons/icn_video.svg"
                alt="/"
              />
              {t("video")}
            </div>
          ) : (
            <div className="badge badge--pdf rounded-pill">
              <img
                className="badge__icon"
                src="/img/icons/icn_pdf.svg"
                alt="/"
              />
              {t("pdf")}
            </div>
          )}
        </div>
      </div>
    </article>
  );
};

export default DetailContentCard;
