/* eslint-disable import/no-anonymous-default-export */
import { apiV1 } from "services/apiInstances";
import { updateUser } from "utils/utils";
import { logout } from "utils/login";
const initialState = {
  user: {
    data: {},
  },
  utils: {
    isFetchingEssentials: false,
    specialities: [],
    provinces: [],
  },
};
export default {
  namespace: "utils",
  state: { ...initialState },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    resetState() {
      return { ...initialState };
    },
  },
  effects: {
    *GET_CURRENT_USER(_, { put, call }) {
      try {
        const response = yield call(apiV1.getCurrentUser);
        yield put({
          type: "setState",
          payload: {
            user: {
              ...response.data.data,
            },
          },
        });
        updateUser({
          ...response.data.data,
        });
      } catch (error) {
        logout();
      }
    },
    *GET_UTILS(_, { select, put, call, all }) {
      const utils = yield select((state) => state.utils.utils);

      yield put({
        type: "setState",
        payload: {
          utils: {
            ...utils,
            isFetchingEssentials: true,
          },
        },
      });

      try {
        const [res, res2] = yield all([
          call(apiV1.getSpecialities),
          call(apiV1.getProvinces),
        ]);

        if (res && res2 && res.status === 200 && res2.status === 200) {
          yield put({
            type: "setState",
            payload: {
              utils: {
                ...utils,
                isFetchingEssentials: false,
                specialities: res.data.data,
                provinces: res2.data.data,
              },
            },
          });
        }
      } catch (e) {
        yield put({
          type: "setState",
          payload: {
            utils: {
              ...utils,
              isFetchingEssentials: false,
            },
          },
        });
      }
    },
    *UPDATE_USER({ payload }, { put }) {
      yield put({
        type: "setState",
        payload: {
          user: {
            data: payload.data,
          },
        },
      });
    },
  },
};
