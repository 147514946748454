import config from 'config';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';

const NextContent = ({ data, restart }) => {
  const history = useHistory();
  const isVideo = data.__t === 'VideoAdvent';
  return (
    <div className="video-next">
      <button
        className="video-next__rewatch btn btn-primary rounded-circle"
        type="button"
        onClick={restart}
      >
        <img
          src={require('assets/img/icons/icn_rotate.svg').default}
          alt="Rewatch"
        />
      </button>
      <NavLink
        to={{
          pathname: `/courses/${data.content_id}`,
          state: { from: history.location.pathname },
        }}
        className="video-next__inner"
      >
        <div className="video-next__thumb">
          <img
            src={`${config.app.cdnUrl}/${data.thumbnail?.file_path}`}
            alt=""
            className="video-next__thumb-img"
          />
        </div>
        <div className="video-next__info">
          <h2 className="video-next__title">{data.content_name}</h2>
          <button className="video-next__cta btn-animated">
            <span className="btn-animated__icon"></span>
            <span className="btn-animated__text">
              {isVideo ? 'Regarder la vidéo' : 'Lire le cours'}
            </span>
          </button>
        </div>
      </NavLink>
    </div>
  );
};

export default NextContent;
