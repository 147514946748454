import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "i18n";
import App from "App";
import dva from "utils/dva";
import models from "models";

import "assets/styles/core.scss";

const app = dva({
  initialState: {},
  models: [...models],
  onError(e) {
    console.log("DVA ERROR", e);
  },
});

export const Store = app._store;

const AppContainer = app.start(<App />);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <AppContainer />
      </I18nextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
