import home from "./home";
import courses from "./courses";
import therap from "./therap";
import favorites from "./favorites";
import settings from "./settings";
import utils from "./utils";
import search from "./search";
import expertProfile from "./expertProfile";
import courseDetails from "./courseDetails";
import eventDetails from "./eventDetails";
import events from "./events";
import logs from "./logs";
import referential from "./referencial";
import experts from "./experts";
import expertPanelDetails from "./expertPanelDetails";

const models = [
  home,
  courses,
  therap,
  favorites,
  expertProfile,
  courseDetails,
  eventDetails,
  events,
  logs,
  referential,
  search,
  settings,
  utils,
  experts,
  expertPanelDetails,
];

export default models;
