/* eslint-disable jsx-a11y/heading-has-content */
const ShimmerDoctorInfos = () => {
  return (
    <div className="shimmer-doctor-info">
      <div className="shimmer-doctor-info__avatar"></div>
      <h2 className="shimmer-doctor-info__name"></h2>
    </div>
  );
};

export default ShimmerDoctorInfos;
