export const addData = (item) => {
  const elem = item;
  const date = Date.now();
  elem.date = date;
  let data = [];
  if (localStorage.getItem("logs"))
    data = JSON.parse(localStorage.getItem("logs"));
  if (!Array.isArray(data)) data = [];
  data.push(elem);
  localStorage.setItem("logs", JSON.stringify(data));
};

export const clearData = (oldData) => {
  let recentData = [];
  if (localStorage.getItem("logs"))
    recentData = JSON.parse(localStorage.getItem("logs"));

  const data = recentData.filter((item) => {
    return (
      oldData
        .map((e) => {
          return e.date;
        })
        .indexOf(item.date) === -1
    );
  });
  localStorage.setItem("logs", JSON.stringify(data));
};

export const addContentLogs = (
  id,
  name,
  source,
  action,
  time = null,
  timeStarted = null,
  infosContent,
) => {
  const elem = { content_id: id, source, content_name: name };
  if (time) time === -1 ? (elem.percentage_viewed = 0) : (elem.percentage_viewed = time);
  if (timeStarted)
    timeStarted === -1
      ? (elem.time_started = 0)
      : (elem.time_started = timeStarted);
  if (infosContent) {
    const {
      speciality,
      tags,
      nbViews,
      channelName,
      areaName,
      duration,
      from,
      promomat,
      pagesNumber,
    } = infosContent;
    elem.speciality = speciality;
    elem.tags = tags;
    elem.nb_views = nbViews;
    elem.channel_name = channelName;
    elem.therapeutic_area_name = areaName;
    if(duration) {
      elem.duration = duration;
    }
    if(pagesNumber) {
      elem.pages_number = pagesNumber;
    }
    
    elem.from = from;
    if (promomat) elem.promomat = promomat;
  }
  const item = {
    action_name: action,
    data: { ...elem },
  };
  addData(item);
};

export const addFavoriLogs = (
  id,
  name,
  source,
  from,
  playlistId,
  currentContentDisplayed,
  action,
  infosContent
) => {
  // logs element
  const elem = { content_id: id, source, name };
  if (from) elem.from = from;
  if (playlistId) elem.playlist_id = playlistId;
  if (currentContentDisplayed)
    elem.current_content_displayed = currentContentDisplayed;
  if (infosContent) {
    const {
      speciality,
      tags,
      nbViews,
      channelName,
      areaName,
      duration,
      percentageViewed,
      promomat,
      pagesNumber,
    } = infosContent;
    elem.speciality = speciality;
    elem.tags = tags;
    elem.nb_views = nbViews;
    elem.channel_name = channelName;
    elem.therapeutic_area_name = areaName;
    if(duration) {
      elem.duration = duration;
    }
    
    if(pagesNumber) {
      elem.pages_number = pagesNumber;
    }
    elem.percentage_viewed = percentageViewed;
    if (promomat) elem.promomat = promomat;
  }

  const item = {
    action_name: action,
    data: { ...elem },
  };
  addData(item);
};