import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const FullScreenReader = ({
  currentPage,
  pdfDoc,
  handleClose,
  onPageChange,
  onStartReading,
  alreadyStarted,
}) => {
  // alreadyStartedRef contains already started value  to be used inside scroll listener
  const alreadyStartedRef = useRef(alreadyStarted);

  const { t } = useTranslation();
  const [pdf, setPdf] = useState({
    pageNum: 1,
    loadedPages: 0,
  });

  useEffect(() => {
    alreadyStartedRef.current = alreadyStarted;
  }, [alreadyStarted]);

  // fix the root height to avoid double scrolling
  useEffect(() => {
    const root = document.getElementById('root');
    root.classList.add('fixed-root');
    return () => root.classList.remove('fixed-root');
  }, []);

  // check if element is visible on the window
  function isScrolledIntoView(elem) {
    var rect = elem.getBoundingClientRect();
    var elemTop = rect.top;
    var elemBottom = rect.bottom;
    var isVisible =
      elemTop < window.innerHeight / 2 && elemBottom >= window.innerHeight / 2;
    return isVisible;
  }

  // render and add a page inside the scrollable container
  const renderPage = async (num) => {
    const container = document.getElementById('full-screen');
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    const page = await pdfDoc.getPage(num);
    var viewport = page.getViewport({ scale: 1.2 });
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    var renderContext = {
      canvasContext: ctx,
      viewport: viewport,
    };
    var renderTask = page.render(renderContext);
    container.appendChild(canvas);
    const scrollListener = () => {
      const isView = isScrolledIntoView(canvas);

      if (isView) {
        onPageView(num);
      }
      if (!alreadyStartedRef.current && num !== currentPage) {
        alreadyStartedRef.current = true;
        onStartReading();
      }
    };
    container.addEventListener('scroll', scrollListener);

    if (currentPage === num) {
      canvas.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    // update number of loaded pages when rendering task finished
    renderTask.promise.then(function () {
      setPdf((prevState) => ({
        ...prevState,
        loadedPages: pdf.loadedPages + 1,
      }));
    });

    return () => container.removeEventListener('scroll', scrollListener);
  };

  // load more pages when loaded pages updated and if there is more pages to load
  useEffect(() => {
    if (pdf.loadedPages < pdfDoc.numPages) {
      return renderPage(pdf.loadedPages + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdf.loadedPages]);

  const onPageView = (num) => {
    setPdf((prevState) => ({
      ...prevState,
      pageNum: num,
    }));
    onPageChange(num);
  };

  return (
    <div id="full-screen" className="pdf-player--full-screen">
      <button className="close-button" onClick={handleClose}>
        <div className="close-icon">
          <span className="close-icon__line"></span>
          <span className="close-icon__line"></span>
        </div>
      </button>
      <div className="pdf-player__pagination--full-screen">
        <div>
          {t('page')} {pdf.pageNum}/{pdfDoc?.numPages}
        </div>
      </div>
    </div>
  );
};

export default FullScreenReader;
