import moment from "moment";

const ContentDescription = ({
  title,
  description,
  start_date,
  end_date,
  isEvent,
  isPassed,
}) => {
  return (
    <div
      className="course-details__description tab-pane fade show active"
      id="description"
    >
      <h1 className="course-details__title mb-3">{title}</h1>
      {isEvent && isPassed && (
        <div className="course-details__date">{`${moment(start_date).format(
          "MMMM DD, YYYY"
        )}
            ${
              end_date ? "- " + moment(end_date).format("MMMM DD, YYYY") : ""
            }`}</div>
      )}
      <p className="course-details__text-description">{description}</p>
    </div>
  );
};

export default ContentDescription;
