/* eslint-disable import/no-anonymous-default-export */
import { t } from "i18next";

import { apiV1 } from "services/apiInstances";
import { displayNotificationToast, updateUser } from "utils/utils";
const initialState = {
  currentSettings: {
    data: {},
    loading: true,
    error: false,
  },
  personalInfo: {
    updating: false,
    error: false,
  },
  password: {
    updating: false,
    error: false,
  },
  notifications: {
    updating: false,
    error: false,
  },
};
export default {
  namespace: "settings",
  state: { ...initialState },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    resetState() {
      return { ...initialState };
    },
  },
  effects: {
    *GET_CURRENT_SETTINGS(_, { select, put, call }) {
      const currentSettings = yield select(
        (state) => state.settings.currentSettings
      );
      yield put({
        type: "setState",
        payload: {
          currentSettings: {
            ...currentSettings,
            loading: true,
          },
        },
      });

      try {
        const response = yield call(apiV1.getSettings);

        if (response.status === 200) {
          yield put({
            type: "setState",
            payload: {
              currentSettings: {
                ...currentSettings,
                data: response.data.data,
                loading: false,
                error: false,
              },
            },
            notifications: {
              preferences: response.data.data.preferences,
            },
          });
        }
      } catch (error) {
        yield put({
          type: "setState",
          payload: {
            currentSettings: {
              ...currentSettings,
              loading: false,
              error: true,
              errorMsg: t("Service indisponible, veuillez réessayer plus tard"),
            },
          },
        });
      }
    },
    *UPDATE_PERSONAL_INFO({ payload }, { select, put, call }) {
      yield put({
        type: "setState",
        payload: {
          personalInfo: {
            updating: true,
          },
        },
      });

      try {
        const response = yield call(apiV1.updateProfile, payload.data);
        if (response.status === 200) {
          const { first_name, last_name } = payload.data;
          const user = yield select((state) => state.utils.user);

          yield put({
            type: "setState",
            payload: {
              personalInfo: {
                updating: false,
                error: false,
              },
            },
          });
          displayNotificationToast(t("Mise à jour réussie."), "success");
          payload.setShowModal(true);

          const updatedUser = {
            ...user,
            first_name,
            last_name,
          };

          // Update locale storage user-medeo key
          updateUser(updatedUser);

          yield put({
            type: "utils/UPDATE_USER",
            payload: {
              user: {
                ...updatedUser,
              },
            },
          });
        }
      } catch (error) {
        yield put({
          type: "setState",
          payload: {
            personalInfo: {
              updating: false,
              error: true,
            },
          },
        });
      }
    },
    *UPDATE_PASSWORD({ payload }, { put, call }) {
      yield put({
        type: "setState",
        payload: {
          password: {
            updating: true,
          },
        },
      });

      try {
        const response = yield call(
          apiV1.updatePassword,
          payload.data,
          payload.setShowModal
        );

        if (response.status === 200) {
          yield put({
            type: "setState",
            payload: {
              password: {
                updating: false,
                error: false,
              },
            },
          });
          displayNotificationToast(t("Mise à jour réussie."), "success");
          payload.setShowModal(true);
        }
      } catch (error) {
        yield put({
          type: "setState",
          payload: {
            password: {
              updating: false,
              error: true,
            },
          },
        });

        displayNotificationToast(t("Mot de passe incorrect."), "danger");
      }
    },
    *UPDATE_PREFERENCES({ payload }, { select, put, call }) {
      const { data } = payload;
      const currentSettings = yield select(
        (state) => state.settings.currentSettings
      );

      yield put({
        type: "setState",
        payload: {
          notifications: {
            updating: true,
          },
        },
      });

      try {
        const response = yield call(apiV1.updatePreferences, data);

        if (response.status === 200) {
          yield put({
            type: "setState",
            payload: {
              currentSettings: {
                ...currentSettings,
                data: {
                  ...currentSettings.data,
                  preferences: response.data.data,
                },
              },
              notifications: {
                updating: false,
                error: false,
              },
            },
          });
        }
      } catch (error) {
        yield put({
          type: "setState",
          payload: {
            notifications: {
              updating: false,
              error: true,
            },
          },
        });
      }
    },
    *RESET(_, { put }) {
      yield put({
        type: "resetState",
      });
    },
  },
};
