/* eslint-disable jsx-a11y/heading-has-content */
const ShimmerTherapAreaSearch = () => {
  return (
    <div className="therapeutic-area-search">
      <div className="shimmer-therapeutic-area-search__thumb-container therapeutic-area-search__thumb-container"></div>
      <div className="therapeutic-area-search__info">
        <h2 className="shimmer-therapeutic-area-search__title"></h2>
        <div className="shimmer-therapeutic-area-search__videos"></div>
      </div>
    </div>
  );
};

export default ShimmerTherapAreaSearch;
