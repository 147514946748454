import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

const ServerError = () => {
  const history = useHistory();
  const currentPath = history.location.pathname;
  const currentSearch = history.location.search;

  const { t } = useTranslation();
  return (
    <div className="no-result-container">
      <div className="no-results">
        <div className="no-results__status">
          <div>500</div>
        </div>
        <div className="no-results__text">
          <p>{t("server error")}</p>
        </div>
        <div className="no-results__cta">
          <a
            href={currentPath + currentSearch}
            type="submit"
            className="btn btn-primary rounded-pill"
          >
            {t("retry")}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ServerError;
