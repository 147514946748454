/* eslint-disable jsx-a11y/heading-has-content */
const ShimmerEventCover = () => {
  return (
    <div className="hero shimmer-upcoming-events-hero">
      <div className="hero-wrapper">
        <div className="container">
          <div className="hero__content">
            <div className="shimmer-upcoming-events-hero__calendar"></div>
            <h3 className="shimmer-home-hero__category"></h3>
            <h4 className="shimmer-home-hero__title"></h4>
            <div className="shimmer-home-hero__btns"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShimmerEventCover;
