import { useHistory } from "react-router-dom";
import config from "config";

const Expert = ({ id, name, degree, speciality, photo }) => {
  const therapClassName = {
    Respiratory: "respiratory",
    Dermatology: "dermatology",
    "Type 2 Inflammation": "inflammation",
  };
  const history = useHistory();

  return (
    <div className="col-md-6 col-lg-3">
      <div
        className={`expert-item expert-item--${therapClassName[speciality]}`}
        onClick={() => {
          history.push({
            pathname: `/panel/expert/${id}`,
          });
        }}
      >
        <div className="expert-item__img-name-wrapper">
          <div className="expert-item__img-wrapper">
            <div className="expert-item__img">
              <img src={`${config.app.cdnUrl}/${photo}`} alt="" />
            </div>
          </div>
        </div>
        <h5 className="expert-item__name text-center">{name}</h5>
        <div className="expert-item__content ">
          <div className="expert-item__info">
            <p className="expert-item__degree m-0">{degree}</p>
            <div className="expert-item__point mx-3"></div>
            <p className="m-0 expert-item__specialty">{speciality}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expert;
