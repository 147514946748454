import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { displayNotificationToast } from "utils/utils";
import { ContentDetails } from "components";
import { sources } from "data/eventsLog";

const CourseDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { t } = useTranslation();
  const { courseDetails, similarCourses, internetError } = useSelector(
    (state) => state.courseDetails
  );

  const dispatchCourseDetails = useCallback(() => {
    dispatch({
      type: "courseDetails/GET_COURSE_DETAILS",
      payload: {
        id,
      },
    });
  }, [id, dispatch]);

  const dispatchSimilarCourses = useCallback(() => {
    dispatch({
      type: "courseDetails/GET_SIMILAR_COURSES",
      payload: {
        id,
      },
    });
  }, [id, dispatch]);

  useEffect(() => {
    dispatchCourseDetails();
    dispatchSimilarCourses();
    return () => {
      dispatch({ type: "courseDetails/RESET" });
    };
  }, [dispatchCourseDetails, dispatchSimilarCourses, dispatch]);

  const onFavoriteChange = (content_id, isFavorite) => {
    dispatch({
      type: "courseDetails/UPDATE_FAVORITE",
      payload: {
        content_id,
        added_to_favorite: isFavorite,
      },
    });
    displayNotificationToast(
      isFavorite
        ? t("course added to favorites with success")
        : t("course removed from favorites with success"),
      "success"
    );
  };

  return (
    <ContentDetails
      contentDetails={courseDetails}
      similarCourses={similarCourses}
      internetError={internetError}
      pageName="course-details"
      onFavoriteChange={onFavoriteChange}
      source={sources.singleContent}
    />
  );
};

export default CourseDetails;
