import { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useTranslation } from "react-i18next";

import {
  ContentCard,
  Layout,
  ServerError,
  InternetError,
  ShimmerWrapper,
} from "components";
import DoctorCover from "./components/DoctorCover";
import { parseDurationToSeconds, startWithVowel } from "utils/functions";
import {
  ShimmerSectionTitle,
  ShimmerDoctorCover,
  ShimmerContentCard,
} from "components/Shimmers";
import { sources } from "data/eventsLog";

const ExpertProfile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { playlistContents, playlistDetail, internetError } = useSelector(
    (state) => state.expertProfile
  );

  const dispatchPlaylistContents = useCallback(
    ({ loadMore }) => {
      dispatch({
        type: "expertProfile/GET_PLAYLIST_CONTENTS",
        payload: {
          id,
          loadMore,
        },
      });
    },
    [dispatch, id]
  );

  const dispatchPlaylistDetail = useCallback(() => {
    dispatch({ type: "expertProfile/GET_PLAYLIST_DETAIL", payload: { id } });
  }, [dispatch, id]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, [400]);
    dispatchPlaylistContents({ loadMore: false });
    dispatchPlaylistDetail();
    return () => {
      dispatch({ type: "expertProfile/RESET" });
    };
  }, [dispatchPlaylistContents, dispatchPlaylistDetail, dispatch]);

  const hasNextPage = playlistContents?.page <= playlistContents?.maxPage;

  const handleLoadMore = () => {
    if (hasNextPage) {
      dispatchPlaylistContents({ loadMore: true });
    }
  };
  const { playlist_name: name, user_title: title } = playlistDetail?.data;

  const error = playlistDetail?.error || playlistContents?.error;
  const loading =
    (playlistDetail?.loading || playlistContents?.loading) && !error;
  const notFound = playlistContents?.notFound || playlistDetail?.notFound;
  return notFound ? (
    <Redirect
      to={{
        pathname: "/404",
        state: { message: t("this expert profile does not exist") },
      }}
    />
  ) : (
    <Layout
      name="expert-profile"
      hasContainer
      headerComponents={[
        <ShimmerWrapper
          loading={loading}
          shimmerRender={<ShimmerDoctorCover />}
          unique
        >
          {!loading && !error && !internetError && (
            <DoctorCover name={name} title={title} />
          )}
        </ShimmerWrapper>,
      ]}
    >
      {/* !isEmpty(description) && <Biography biography={description} /> */}
      <ShimmerWrapper
        loading={loading}
        shimmerRender={<ShimmerSectionTitle />}
        unique
      >
        {internetError ? (
          <InternetError />
        ) : error ? (
          <ServerError />
        ) : (
          !loading &&
          (playlistContents?.data?.length > 0 ? (
            <h2 className="section__title">
              {t("consult content", {
                name: name ?? "",
                frPreposition: startWithVowel(name) ? "d’" : "de ",
              })}
            </h2>
          ) : (
            <h2 className="section__title">{t("no content available")}</h2>
          ))
        )}
      </ShimmerWrapper>
      <ShimmerWrapper
        loading={loading}
        shimmerRender={<ShimmerContentCard />}
        ShimmerLayout={({ children }) => (
          <div className="courses-grid">
            <div className="row">{children}</div>
          </div>
        )}
        large={playlistContents?.data?.length === 0}
      >
        {playlistContents?.data?.length > 0 && !loading && (
          <InfiniteScroll
            dataLength={playlistContents?.data?.length}
            next={handleLoadMore}
            hasMore={hasNextPage}
            style={{ overflowX: "hidden", overflowY: "hidden" }}
          >
            <div className="courses-grid">
              <div className="row">
                {playlistContents?.data?.map(
                  ({
                    content_id,
                    content_name,
                    __t,
                    interval_duration,
                    description,
                    therapeuticArea,
                    thumbnail,
                    percentage_viewed,
                    pages_number,
                    channel,
                    tags,
                    nb_views,
                    speciality,
                    duration,
                    promomat,
                    publishing_date,
                  }) => {
                    const infoContent = {
                      speciality: speciality?.name,
                      tags: tags,
                      nbViews: nb_views,
                      channelName: channel?.playlist_name,
                      areaName: therapeuticArea?.playlist_name,
                      percentageViewed: parseInt(percentage_viewed || 0),
                      promomat,
                    };
                    const isVideo = __t === "VideoAdvent";
                    if (isVideo) {
                      infoContent.duration = parseDurationToSeconds(duration);
                    } else {
                      infoContent.pagesNumber = pages_number;
                    }
                    return (
                      <div
                        key={content_id}
                        className="col-sm-6 col-lg-4 col-xl-3"
                      >
                        <ContentCard
                          content_id={content_id}
                          isVideo={__t === "VideoAdvent"}
                          intervalDuration={interval_duration}
                          name={content_name}
                          description={description}
                          therapeuticArea={therapeuticArea}
                          thumbnail={thumbnail}
                          percentageViewed={percentage_viewed}
                          publishing_date={publishing_date}
                          infoContent={infoContent}
                          source={sources.expertProfile}
                          playlistId={therapeuticArea?.playlist_id}
                        />
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          </InfiniteScroll>
        )}
      </ShimmerWrapper>
    </Layout>
  );
};

export default ExpertProfile;
