/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as qs from "query-string";

import routesList from "routes/routesList";
import { searchTypes } from "data";

const Search = ({ q }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const searchInput = useRef(null);

  let history = useHistory();
  const searchSubmit = (e) => {
    const queries = qs.parse(location.search);
    let type = searchTypes.courses;
    if (queries && queries.type) type = queries.type;
    e.preventDefault();
    const query = searchInput?.current?.value;

    history.push(`${routesList.search}?q=${query || ""}&type=${type}`);
  };
  useEffect(() => {
    if (q && searchInput.current) {
      searchInput.current.value = q;
    }
  }, []);
  return (
    <form className="search" onSubmit={searchSubmit}>
      <input
        type="search"
        className="search__input"
        placeholder={t("search")}
        ref={searchInput}
      />
      <img
        className="search__search-icon"
        src={require("assets/img/icons/icn_search.svg").default}
        alt="Recherche"
      />
      <div className="search__close-icon close-icon">
        <div className="close-icon__line"></div>
        <div className="close-icon__line"></div>
      </div>
    </form>
  );
};

export default Search;
