import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

import { Layout } from "components";
import { PersonalInfoForm, PasswordForm } from "./Components";
import ApplyChanges from "./Components/ApplyChanges";
const Settings = () => {
  const state = useSelector((state) => state.utils);
  const { utils } = state;
  const { specialities, provinces, isFetchingEssentials } = utils;

  const settings = useSelector((state) => state.settings);
  const { loading, data, errorMsg } = settings.currentSettings;

  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: "settings/GET_CURRENT_SETTINGS" });
    if (!specialities.length || !provinces.length) {
      dispatch({
        type: "utils/GET_UTILS",
      });
    }
    return () => {
      dispatch({ type: "settings/RESET" });
    };
  }, [dispatch, provinces, specialities]);
  return (
    <Layout name="settings" hasContainer>
      <PersonalInfoForm
        currentData={data}
        loading={loading}
        error={errorMsg}
        provinces={provinces}
        specialities={specialities}
        isFetchingEssentials={isFetchingEssentials}
        setShowModal={setShowModal}
      />
      <PasswordForm error={errorMsg} setShowModal={setShowModal} />
      {showModal && (
        <ApplyChanges showModal={showModal} setShowModal={setShowModal} />
      )}
    </Layout>
  );
};

export default Settings;
