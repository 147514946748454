import { NavLink } from "react-router-dom";
import routesList from "routes/routesList";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { displayNotificationToast } from "utils/utils";
import { getTimeFromNow } from "utils/functions";
import config from "config";
import { AddRemoveFavorite } from "components";
import { pdfDurations } from "data";
import { addFavoriLogs } from "utils/logs";
import { actionNames } from "data/eventsLog";

const ContentCard = ({
  isVideo,
  name,
  description,
  therapeuticArea,
  thumbnail,
  percentageViewed,
  isFavorite,
  content_id,
  intervalDuration,
  infoContent,
  source,
  playlistId,
  currentContentDisplayed,
  publishing_date,
}) => {
  const { t } = useTranslation();

  const history = useHistory();
  const isSearch = window.location.pathname === routesList.search;

  const dispatch = useDispatch();

  const onSuccess = (content_id, isFavorite) => {
    const page = window.location.pathname.split("/")[1];
    const action =
      page === "" ? "home/UPDATE_FAVORITE" : `${page}/UPDATE_FAVORITE`;
    dispatch({
      type: action,
      payload: {
        content_id,
        added_to_favorite: isFavorite,
      },
    });
    displayNotificationToast(
      isFavorite
        ? t("course added to favorites with success")
        : t("course removed from favorites with success"),
      "success"
    );
  };

  const openCourseDetails = () => {
    history.push({
      pathname: `/courses/${content_id}`,
      state: { sourcePage: history.location.pathname },
    });
  };

  const addLogs = (from) => {
    addFavoriLogs(
      content_id,
      name,
      source,
      from,
      playlistId,
      currentContentDisplayed,
      actionNames.content.contentFaved,
      infoContent
    );
  };
  return (
    <article
      className={`course-card${
        isSearch
          ? " course-card--search"
          : ` course-card--${
              therapeuticArea.playlist_name === "Type 2 Inflammation"
                ? "inflammation"
                : therapeuticArea.playlist_name?.toLowerCase()
            }`
      }`}
      onClick={openCourseDetails}
    >
      <div className="course-card__thumb-container">
        <img
          className="course-card__thumb-img"
          src={`${config.app.cdnUrl}/${thumbnail?.file_path}`}
          alt="/"
        />
        {percentageViewed !== "0%" && (
          <div className="course-card__progress-container">
            <div
              className="course-card__progress"
              style={{ width: percentageViewed }}
            ></div>
          </div>
        )}
        <div className="course-card__time">
          <img
            src={require("assets/img/icons/icn_clock.svg").default}
            alt="/"
          />
          {pdfDurations[intervalDuration]}
        </div>
        <AddRemoveFavorite
          isFavorite={isFavorite}
          id={content_id}
          onSuccess={onSuccess}
          type="contents"
          addLogs={addLogs}
        />
      </div>

      <div className="course-card__body">
        <div className="course-card__info">
          <h2 className="course-card__title" title={name}>
            <NavLink to="/">{name}</NavLink>
          </h2>
          <div className="course-card__date">
            {getTimeFromNow(publishing_date)}
          </div>

          <p className="course-card__description">{description}</p>
        </div>
        <div className="course-card__badges">
          {isVideo ? (
            <div className="badge badge--video rounded-pill">
              <img
                className="badge__icon"
                src="/img/icons/icn_video.svg"
                alt="/"
              />
              {t("video")}
            </div>
          ) : (
            <div className="badge badge--pdf rounded-pill">
              <img
                className="badge__icon"
                src="/img/icons/icn_pdf.svg"
                alt="/"
              />
              {t("pdf")}
            </div>
          )}
          <div
            className={`badge badge--${
              therapeuticArea.playlist_name === "Type 2 Inflammation"
                ? "inflammation"
                : therapeuticArea.playlist_name?.toLowerCase()
            } rounded-pill`}
          >
            {therapeuticArea?.playlist_name}
          </div>
        </div>
      </div>
    </article>
  );
};

export default ContentCard;
