import { useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";

import routesList from "routes/routesList";
import { logout } from "utils/login";
import Search from "./Search";
const Navigation = ({ transparent, q, setShowModal }) => {
  const { home, courses, events, favorites, expertsPanel, settings } =
    routesList;
  const history = useHistory();
  const state = { from: history.location.pathname }
  
  function openSearch() {
    const dropdownToggleBtns = document.querySelectorAll(
      ".nav-master .dropdown-toggle"
    );
    const pagewrap = document.querySelector(".page-wrap");
    const searchInput = document.querySelector(".search__input");
    dropdownToggleBtns.forEach((elm) => {
      elm.nextElementSibling.classList.remove("dropdown-menu--open");
    });
    pagewrap.classList.add("search--open");
    searchInput.focus();
  }

  function closeSearch() {
    const pagewrap = document.querySelector(".page-wrap");
    const search = document.querySelector(".search");
    const searchInput = document.querySelector(".search__input");
    pagewrap.classList.remove("search--open");
    searchInput.blur();
    search.reset();
  }

  const openMenu = useCallback(() => {
    const mainMenu = document.querySelector(".main-menu");
    const html = document.querySelector("html");
    const pagewrap = document.querySelector(".page-wrap");
    mainMenu.classList.add("open");
    html.classList.add("prevent-pull");
    pagewrap.classList.add("offcanvas--open");
    closeSearch();
    const filterDropdownToggleBtns = document.querySelectorAll(
      ".filters .dropdown-toggle"
    );
    filterDropdownToggleBtns.forEach((elm) => {
      if (elm.nextElementSibling.classList.contains("dropdown-menu--open")) {
        elm.nextElementSibling.classList.remove("dropdown-menu--open");
      }
    });
  }, []);

  function closeMenu() {
    const mainMenu = document.querySelector(".main-menu");
    const html = document.querySelector("html");
    const pagewrap = document.querySelector(".page-wrap");
    pagewrap.classList.remove("offcanvas--open");
    mainMenu.classList.remove("open");
    html.classList.remove("prevent-pull");
  }

  useEffect(() => {
    const menuOpen = document.querySelector('[data-toggle="offcanvas"]');
    const menuClose = document.querySelector(".main-menu__close");
    const dropdownToggleBtns = document.querySelectorAll(
      ".nav-master .dropdown-toggle"
    );
    if (menuOpen) {
      menuOpen.addEventListener("click", function () {
        openMenu();
        dropdownToggleBtns.forEach((elm) => {
          elm.nextElementSibling.classList.remove("dropdown-menu--open");
        });
      });
    }

    if (menuClose) {
      menuClose.addEventListener("click", function () {
        closeMenu();
      });
    }

    if (dropdownToggleBtns) {
      dropdownToggleBtns.forEach((elm) => {
        elm.addEventListener("click", function () {
          const currentElm = this;
          currentElm.nextElementSibling.classList.toggle("dropdown-menu--open");
          closeSearch();
        });
      });
    }

    const pagewrapOverlay = document.querySelector(".page-wrap__overlay");

    if (pagewrapOverlay) {
      pagewrapOverlay.addEventListener("click", function () {
        closeMenu();
      });
    }
  }, [openMenu]);

  useEffect(() => {
    const pagewrap = document.querySelector(".page-wrap");
    const search = document.querySelector(".search");
    const searchInput = document.querySelector(".search__input");
    const searchBoxSearchIcon = document.querySelector(".search__search-icon");
    const searchBoxCloseIcon = document.querySelector(".search__close-icon");

    if (search && searchInput && searchBoxSearchIcon && searchBoxCloseIcon) {
      searchBoxSearchIcon.addEventListener("click", function () {
        if (!pagewrap.classList.contains("search--open")) {
          openSearch();
        }
      });

      searchBoxCloseIcon.addEventListener("click", function () {
        if (pagewrap.classList.contains("search--open")) {
          closeSearch();
        }
      });
    }
    const html = document.querySelector("html");
    if (html.classList.contains("prevent-pull"))
      html.classList.remove("prevent-pull");
  }, []);

  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  const isActive = (path) => {
    return window.location.pathname === path;
  };

  return (
    <nav
      className={`nav-master ${
        transparent && "nav-master--transparent"
      } navbar navbar-expand--ipad`}
    >
      <div className="container">
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="offcanvas"
        >
          <div className="navbar-toggler__line"></div>
          <div className="navbar-toggler__line"></div>
          <div className="navbar-toggler__line"></div>
        </button>

        <NavLink className="logo" to={{ pathname: home, state }}>
          <img src={require("assets/img/logo_advent.svg").default} alt="logo" />
        </NavLink>

        <div className="main-menu navbar-collapse offcanvas-collapse">
          <ul className="navbar-nav mr-auto">
            <li className="main-menu__close">
              <div className="close-icon">
                <span className="close-icon__line"></span>
                <span className="close-icon__line"></span>
              </div>
            </li>
            <li className="nav-item">
              <Link
                className={`nav-link ${isActive(home) && "active"}`}
                to={{ pathname: home, state }}
              >
                Home
              </Link>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link ${isActive(courses) && "active"}`}
                to={{ pathname: courses, state }}
              >
                Educational resources
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link ${isActive(events) && "active"}`}
                to={{ pathname: events, state }}
              >
                Events
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link ${isActive(expertsPanel) && "active"}`}
                to={{ pathname: expertsPanel, state }}
              >
                Expert panel
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className={`nav-link ${isActive(favorites) && "active"}`}
                to={{ pathname: favorites, state }}
              >
                Favorites
              </NavLink>
            </li>
            <li
              className="nav-item nav-item--gotomedeo"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <div
                target="_blank"
                className="nav-link nav-link--gotomedeo btn-gotomedeo"
                rel="noreferrer"
              >
                <span>Go to</span>
                <img
                  src={require("assets/img/medeo-logo.svg").default}
                  alt="medeo logo"
                />
              </div>
            </li>
          </ul>
        </div>
        <div className="secondary-menu">
          <div className="nav-item search-master">
            <div className="nav-link">
              <Search q={q} />
            </div>
          </div>
          <div className="nav-item dropdown nav-item--account">
            <div className="nav-link dropdown-toggle">
              <div>
                <span>Y.B</span>
                {/* <img src={require("assets/img/Avatar.png")} alt="profil" /> */}
              </div>
            </div>
            <div className="dropdown-menu dropdown-menu-right">
              <NavLink className="dropdown-item" to={settings}>
                <img
                  src={require("assets/img/icons/icn_settings.svg").default}
                  alt="Icon settings"
                />
                Settings
              </NavLink>
              <a className="dropdown-item" href="/" onClick={handleLogout}>
                <img
                  src={require("assets/img/icons/icn_logout.svg").default}
                  alt="Icon logout"
                />
                Log out
              </a>
            </div>
          </div>
          <div
            className="nav-item"
            onClick={() => {
              setShowModal(true);
            }}
          >
            <div
              target="_blank"
              className="nav-link nav-link--gotomedeo btn-gotomedeo"
              rel="noreferrer"
            >
              <span>Go to </span>
              <img
                src={require("assets/img/medeo-logo.svg").default}
                alt="medeo logo"
              />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
