import { useEffect } from "react";

import { Layout } from "components";

const PrivacyPolicy = () => {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, [400]);
  }, []);
  return (
    <Layout
      name="cgu"
      noNav
      headerComponents={[
        <header className="auth__heading text-center">
          <a className="logo logo--center" href="/">
            <img
              src={require("assets/img/logo_advent.svg").default}
              alt="Advent"
            />
          </a>

          <p>
            Bienvenue sur Advent, la plateforme dédiée à l’information médicale
            à destination des professionnels de la santé.
          </p>
        </header>,
      ]}
    >
      <div className="cgu__content">
        <h1 className="cgu__heading-primary">Politique de confidentialité</h1>
        <p>
          Sanofi-Aventis Algérie spa (ci-après « Sanofi ») est pleinement
          attachée à la protection des données personnelles et veut vous fournir
          toute information pertinente concernant la manière dont vos données
          sont traitées par Sanofi.{" "}
        </p>
        <p>
          Vous trouverez ci-dessous toutes les informations relatives au
          traitement des données personnelles sur cette Plateforme. Veuillez
          noter que le contenu de cette Politique de confidentialité peut
          changer au fil du temps, notamment pour l’adapter aux évolutions ou
          aux changements du droit applicable ou des pratiques internes de
          Sanofi. Dans l’hypothèse où les changements sont objectivement
          substantiels avec des conséquences pour vos droits et nos obligations
          relatives à la protection des données personnelles (et dans la mesure
          où nous avons les moyens de vous contacter), vous serez notifié de
          toute mise à jour, par tout moyen adéquat en fonction des informations
          que nous aurons récoltées.
        </p>
        <p>
          Cependant, nous vous invitons à consulter régulièrement la Politique
          de confidentialité afin de vérifier les mises à jour.
        </p>
        <h2 className="cgu__heading-secondary">
          Qui est responsable du traitement des données personnelles ?
        </h2>
        <p>
          Sanofi-Aventis Algérie est responsable du traitement des Données
          personnelles de toute personne utilisant cette Plateforme, et agit
          donc en qualité de Contrôleur:{" "}
        </p>
        <p>
          SANOFI AVENTIS ALGERIE SPA <br />
          Société par actions au capital de 11 054 489 700 Dinars Algériens.
          <br />
          Siège social : Micro-Zone d’Activité Bt, B, lot 29, 30, et 31 Hydra -
          Alger.
          <br />
          <strong>RC N° 98 B 0005416 /16-00</strong>
        </p>
        <h2 className="cgu__heading-secondary">
          Que signifient les données personnelles et leur traitement ?
        </h2>
        <p>
          Aux fins de la présente Politique de confidentialité, les données
          personnelles signifient toute information relative à une personne
          physique identifiée ou identifiable, traitée par Sanofi dans le cadre
          des objectifs définis dans cette Politique de confidentialité.
        </p>
        <p>
          Plus simplement, il s’agit de toute information relative à un
          individu. Ces données personnelles peuvent, par exemple, prendre les
          formes suivantes :
        </p>
        <ul>
          <li>
            Des informations d’identification de base, tel que votre nom ou date
            de naissance.
          </li>
          <li>
            Des informations pouvant vous être attribuées de manière directe ou
            indirecte, telle qu’une publication sur les réseaux sociaux.
          </li>
          <li>
            Des informations pouvant être associées à vous ou vos appareils,
            telle qu’une adresse IP (c’est-à-dire : l’adresse réseau de votre
            appareil).
          </li>
        </ul>
        <p>
          À cet égard, la notion de “traitement” désigne toute action entreprise
          sur vos données personnelles, comme:
        </p>
        <ul>
          <li>La collecte</li>
          <li>Le stockage</li>
          <li>L’accès </li>
          <li>L’analyse</li>
          <li>La suppression </li>
        </ul>
        <h2 className="cgu__heading-secondary">
          Quelles données personnelles sont recueillies par la Plateforme ?
        </h2>
        <p>
          Lorsqu’elle est en marche, cette Plateforme peut recueillir les
          catégories de données personnelles suivantes:
        </p>
        <ul>
          <li>
            <strong>Des données d’identification:</strong> toute information qui
            peut vous identifier, d’une manière directe ou indirecte, tel que
            votre nom ou coordonnées (adresse, courriel, numéro de téléphone),
            votre type d’emploi, votre entreprise.
          </li>
          <li>
            <strong>Des messages:</strong>Vous pouvez nous envoyer des demandes
            de renseignement sur cette Plateforme.
          </li>
          <li>
            <strong>Des données d’accès: </strong>Toute information relative à
            votre connexion ou à l’accès à cette Plateforme (par exemple le type
            d’appareil ou de navigateur utilisé, l’horodatage de votre
            connexion, votre adresse IP, les pages consultées), votre historique
            de navigation.
          </li>
          <li>
            <strong>Des données de localisation</strong> Toute information
            pouvant être fournies par votre appareil et navigateur à propos de
            votre localisation,{" "}
            <strong>
              si vous autorisez le partage de ces informations avec Sanofi Toute
              donnée relative aux cookies et récoltée par des cookies.
            </strong>
          </li>
          <li>
            <strong>
              Dans certains cas, Sanofi peut traiter des informations relatives
              aux opinions philosophiques, politiques ou religieuses, à
              l’appartenance syndicale, à l’orientation sexuelle ou à la santé
              ou l’origine ethnique ou raciale:{" "}
            </strong>
            Sanofi ne traitera de telles catégories de données personnelles à
            caractère “sensible” ou des “catégories spéciales” que si Sanofi est
            dûment autorisé conformément aux règles applicables relatives à la
            protection des données. En particulier, Sanofi ne traitera de telles
            données que si votre consentement exprès et explicite pour traiter
            ces données a été au préalable donné.
          </li>
        </ul>
        <h2 className="cgu__heading-secondary">
          A quelles fins cette Plateforme récolte-t-il les données personnelles
          ?
        </h2>
        <p>
          Tout traitement de données personnelles doit être mis en œuvre pour
          des fins déterminées.{" "}
        </p>
        <p>
          À cet égard, la collecte et le traitement des données personnelles sur
          cette Plateforme sont effectués pour les fins suivantes:{" "}
        </p>
        <ul>
          <li>
            <strong>
              Afin de vous permettre de naviguer sur cette Plateforme.
            </strong>
          </li>
          <li>
            <strong>
              Afin de vous donner accès aux services en ligne, la Plateforme et
              les plateformes:{" "}
            </strong>
            pour gérer votre compte en ligne.
          </li>
          <li>
            <strong>
              Afin de personnaliser votre expérience de navigation:{" "}
            </strong>
            Lors de l’utilisation de nos services, assurer que nos services se
            présentent de la manière qui vous convient le mieux ; comprendre les
            intérêts personnels et professionnels que vous portez à notre
            contenu, nos produits et services et tout autre contenu, et adapter
            notre contenu en fonction de vos besoins et préférences ; vous
            présenter des produits et des offres adaptés.
          </li>
          <li>
            <strong>Afin d’améliorer nos produits et services:</strong>
            identifier les tendances d’utilisation et développer de nouveaux
            produits et services; comprendre comment vous et votre appareil
            interagissez avec nos services; répondre aux préoccupations en
            matière de sécurité; déterminer l’efficacité de nos campagnes
            promotionnelles, réaliser des sondages.
          </li>
          <li>
            <strong>Afin de nous permettre de communiquer avec vous: </strong>
            répondre à vos requêtes et à vos demandes de renseignements ;
            apporter une aide pour nos services et produits ; vous fournir toute
            information importante, administrative, des avis requis et tout
            matériel promotionnel ; vous envoyer des nouvelles et des
            informations relatives à nos produits, services, marques et autres
            opérations ; organiser et gérer les évènements et congrès
            professionnels, y compris votre participation à de tels évènements.
          </li>
        </ul>
        <h2 className="cgu__heading-secondary">
          Sur quelles bases s’appuie cette Plateforme traiter vos données
          personnelles ?
        </h2>
        <p>
          Pour les besoins de cette Plateforme, vos données personnelles sont
          traitées uniquement sur la base de:
        </p>
        <ul>
          <li>
            Votre Consentement préalable: dès lors que vous avez clairement
            donné votre accord à Sanofi pour le traitement de vos données
            personnelles. En pratique, cela signifie que Sanofi vous demandera
            de signer un document, ou de remplir un formulaire en ligne “opt-in”
            ou de suivre toute procédure nécessaire pour vous permettre d’être
            pleinement informé, et ensuite soit d’accepter soit de refuser
            clairement le traitement des données personnelles en question. En
            naviguant sur ce Site et, le cas échéant, en traitant vos données
            personnelles en utilisant des cookies.
          </li>
          <li>
            Une relation contractuelle entre Sanofi et vous : Dans ce cas, le
            traitement de vos données personnelles est en règle générale
            nécessaire pour l’exécution de ce contrat; ceci signifie que si vous
            ne souhaitez pas que Sanofi traite vos données personnelles dans ce
            contexte, Sanofi pourrait être obligé de refuser d’entrer dans une
            relation contractuelle avec vous ou ne sera pas en mesure de vous
            fournir les produits ou services concernés par ce contrat.
          </li>
          <li>
            “L’intérêt légitime” de Sanofi au sens des règles applicables en
            matière de protection des données. Dans cette hypothèse, Sanofi
            prendra en considération vos droits fondamentaux et intérêts pour
            déterminer si le traitement est légitime et légal.
          </li>
        </ul>
        <h2 className="cgu__heading-secondary">
          Combien de temps les données personnelles collectées sur cette
          Plateforme sont-elles conservées ?
        </h2>
        <p>
          La durée de conservation des données personnelles traitées sur cette
          Plateforme dépend de la catégorie à laquelle ces données personnelles
          appartiennent:
        </p>
        <ul>
          <li>
            Les données d’identification: votre courriel et mot de passe seront
            conservés sur nos serveurs afin de faciliter votre authentification
            et votre identification, pendant toute la durée de validité de votre
            compte. Veuillez nous contacter si vous souhaitez supprimer votre
            compte à tout moment.
          </li>
          <li>
            Les messages: Aucune donnée relative aux messages n’est conservée
            sur cette Plateforme.
          </li>
          <li>
            Les informations professionnelles Votre nom, titre, pays, institut
            et domaine d’expertise seront conservés sur nos serveurs, pendant
            toute la durée de validité de votre compte, afin d’assurer une bonne
            mise à disposition des vidéos en fonction de votre localisation
            ainsi que le domaine d’intérêts. Veuillez nous contacter si vous
            souhaitez supprimer votre compte à tout moment.
          </li>
          <li>
            Les données d’accès: Aucune donnée relative à la connexion n’est
            conservée sur la Plateforme.
          </li>
          <li>
            Les données de localisation:Aucune donnée de localisation n’est
            conservée sur la Plateforme.
          </li>
        </ul>
        <h2 className="cgu__heading-secondary">
          Qui a accès aux données personnelles ?
        </h2>
        <p>
          Sanofi peut transférer vos données personnelles en son sein
          (c’est-à-dire à d’autres entités Sanofi) et/ou à des fournisseurs de
          service tiers aux fins de:
        </p>
        <ul>
          <li>Administrer votre compte.</li>
          <li>Identifier l’accès à la Plateforme.</li>
          <li>
            Améliorer et personnaliser la Plateforme et nos produits, services
            et notre entreprise de manière générale.
          </li>
          <li>
            Communiquer avec vous afin de répondre à des questions ou des
            réclamations.
          </li>
          <li>
            Mieux comprendre vos centres d’intérêts et vos préférences afin
            d’améliorer nos méthodes de communication et d’interaction avec
            vous.
          </li>
          <li>
            Envoyer des communications ou autres informations que nous pensons
            utiles à vous ou que vous avez demandées.
          </li>
        </ul>
        <p>
          Dans l’hypothèse où le transfert de données au sein de Sanofi ou à des
          fournisseurs de service tiers implique le transfert de vos données
          personnelles à un pays garantissant un niveau moins élevé de
          protection des données personnelles par rapport à la juridiction à
          laquelle vous appartenez, Sanofi veillera à ce qu’un niveau de
          protection suffisant soit respecté dans le traitement de vos données
          en mettant en œuvre les clauses contractuelles aussi contraignantes
          que celles applicables à Sanofi ou en vous demandant votre
          consentement préalable et exprès.
        </p>
        <h2 className="cgu__heading-secondary">
          Vos droits : Sanofi s’assure que vous pouvez exercer vos droits
          relatifs à vos données personnelles.
        </h2>
        <p>
          Vous pouvez exercer vos droits conformément aux règles applicables en
          matière de protection des données.
        </p>
        <p>
          À cette fin, Sanofi vous informe que vous avez les droits suivants:
        </p>
        <ul>
          <li>
            D’accéder sur simple demande à vos données personnelles - auquel cas
            vous pouvez recevoir une copie de vos données (sur demande), à moins
            que ces données ne soient directement mises à votre disposition, par
            exemple sur votre espace personnel.
          </li>
          <li>
            D’obtenir des rectifications de vos données personnelles dans le cas
            où elles sont inexactes, incomplètes ou obsolètes.
          </li>
          <li>
            De supprimer vos données personnelles dans les cas énumérés par les
            lois applicables en matière de protection des données (“droit à
            l’oubli”).
          </li>
          <li>
            D’obtenir le retrait de votre consentement relatif au traitement des
            données personnelles sans que ne soit affectée la légalité du
            traitement et de la collecte de vos données personnelles qui ont été
            basés sur votre consentement.
          </li>
          <li>
            De vous opposer au traitement de vos informations personnelles, dans
            le cas celles-ci ont été collectées et traitées sur la base des
            intérêts légitimes de Sanofi, auquel cas vous aurez besoin de
            justifier votre demande en nous fournissant des explications quant à
            votre situation particulière. La demande d’une limitation du
            traitement des données personnelles dans les cas énumérés dans les
            lois applicables en matière de protection des données.
          </li>
          <li>
            D’obtenir vos données personnelles aux fins d’une transmission
            depuis Sanofi à un tiers ou de les avoir directement transmises de
            Sanofi au tiers de votre choix, lorsque ce transfert est
            techniquement réalisable (le droit de portabilité est autorisé
            uniquement lorsque le traitement est basé sur votre consentement).
          </li>
        </ul>
        <p>
          Si vous souhaitez exercer l’un des droits évoqués ci-dessus, veuillez
          nous contacter via la rubrique “Comment nous contacter” ci-dessous et
          nous prendrons les mesures nécessaires pour vous répondre dans les
          meilleurs délais.
        </p>
        <h2 className="cgu__heading-secondary">Comment nous contacter</h2>
        <p>
          Vous pouvez envoyer toute question ou requête relative à l’usage que
          fait Sanofi de vos données personnelles à notre Délégué à la
          protection des données au courriel suivant:{" "}
          <a
            href="mailto:Nazim.Bendaas2@sanofi.com"
            target="_blank"
            className="cgu__link"
          >
            Nazim.Bendaas2@sanofi.com
          </a>
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;
