const settings = {
  "Mot de passe incorrect.": "Mot de passe incorrect",
  "Les changements seront aussi appliqués sur Medeo.":
    "Les changements seront aussi appliqués sur Medeo.",
  "Informations personnelles": "Informations personnelles",
  "Nom doit être au bon format": "Nom doit être au bon format",
  "Champ requis": "Champ requis",
  "Prénom doit être au bon format": "Prénom doit être au bon format",
  "Prière de choisir votre secteur d'activité":
    "Prière de choisir votre secteur d’activité",
  "Mobile doit être au bon format": "Mobile doit être au bon format",
  "Modification du mot de passe": "Modification du mot de passe",
  "Mot de passe actuel": "Mot de passe actuel",
  "Les mots de passe ne correspondent pas":
    "Les mots de passe ne correspondent pas",
  "Confirmer nouveau mot de passe": "Confirmer nouveau mot de passe",
  "Le mot de passe doit contenir au moins 8 caractères, une lettre Maj et un chiffre":
    "Le mot de passe doit contenir au moins 8 caractères, une lettre Maj et un chiffre",
};

export default settings;
