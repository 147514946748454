import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import videoConfig from "config/videoConfig";
import { actionNames } from "data/eventsLog";
import { addContentLogs } from 'utils/logs';
import { parseDurationToSeconds } from "utils/functions";
import NextContent from "./NextContent";

const VideoPlayer = ({ video, source }) => {
  const history = useHistory();
  const { from, sourcePage } = history.location.state ?? { from: '/' };
  const [hasWatched, setHasWatched] = useState(false);
  const [showNext, setShowNext] = useState(false);
  const { next_content: nextVideo } = video;

  const videoRef = useRef(null);
  const showNextVideo = () => {
    if (nextVideo.has_next) {
      history.push(`/courses/${nextVideo.content_id}`);
    } else {
      setShowNext(true);
    }
  }

  const closePage = () => {
    history.push(sourcePage ?? '/');
  };

  const { percentage_viewed, brightcove_id: brightcoveId, content_id: videoId, content_name: name } = video;
  useEffect(() => {
    const script = document.createElement('script');
    const script2 = document.createElement('script');
    script.src = `https://players.brightcove.net/${videoConfig['data-account']}/default_default/index.min.js`;
    script2.src = `https://players.brightcove.net/${videoConfig['data-account']}/${videoConfig['data-player']}_default/index.min.js`;
    script.async = true;
    script2.async = true;

    document.body.appendChild(script);
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script2);
    };
  }, [source, brightcoveId, videoRef, percentage_viewed, videoId]);

  useEffect(() => {
    const { current } = videoRef;
    return () => {
      let pourcentage = 0;
      let percentageViewedInt = parseInt(percentage_viewed || 0);
      if (percentageViewedInt === 100) {
        percentageViewedInt = 0;
      }
      if (current.duration)
        pourcentage = (current.currentTime / current.duration) * 100;

      if (hasWatched) {
        const infosVideo = {
          speciality: video.speciality?.name,
          tags: video.tags,
          nbViews: video.nb_views,
          channelName: video.channel?.playlist_name,
          areaName: video.therapeuticArea?.playlist_name,
          duration: parseDurationToSeconds(video.duration),
          from: from ?? sourcePage,
          promomat: video.promomat,
        };

        if (pourcentage >= percentageViewedInt) {
          addContentLogs(
            videoId,
            name,
            source,
            actionNames.content.contentViewed,
            Number(pourcentage)?.toFixed(0) || -1,
            percentageViewedInt || -1,
            infosVideo
          );
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasWatched]);


  const onEnded = () => {
    showNextVideo();

    const infosVideo = {
      speciality: video.speciality?.name,
      tags: video.tags,
      nbViews: video.nb_views,
      channelName: video.channel?.playlist_name,
      areaName: video.therapeuticArea?.playlist_name,
      duration: parseDurationToSeconds(video.duration),
      from: from ?? sourcePage,
      promomat: video.promomat,
    };
    // save in logs
    addContentLogs(
      videoId,
      name,
      source,
      actionNames.content.contentFinished,
      100,
      0,
      infosVideo
    );
  };

  const onPlay = () => {
    setHasWatched(true);
    const { current } = videoRef;
    let pourcentage = 0;

    const percentageViewedInt = parseInt(percentage_viewed || 0);
    if (current.duration)
      pourcentage = (current.currentTime / current.duration) * 100;

    const infosVideo = {
      speciality: video.speciality?.name,
      tags: video.tags,
      nbViews: video.nb_views,
      channelName: video.channel?.playlist_name,
      areaName: video.therapeuticArea?.playlist_name,
      duration: parseDurationToSeconds(video.duration),
      from: from ?? sourcePage,
      promomat: video.promomat,
    };
    // save in logs
    addContentLogs(
      videoId,
      name,
      source,
      actionNames.content.contentStarted,
      pourcentage >= percentageViewedInt
        ? Number(pourcentage).toFixed(0) || 0
        : percentageViewedInt,
      0,
      infosVideo
    );
  };

  useEffect(() => {
    if (videoRef.current) {
      const pertentage = parseInt(percentage_viewed || 0);
      const currentTime = pertentage < 100 ? Math.round(parseDurationToSeconds(video.duration) * parseInt(percentage_viewed || 0) / 100) : 0;
      videoRef.current.currentTime = currentTime;
      
    }
  }, [percentage_viewed, video.duration]);

  const restart = () => {
    setShowNext(false);
    videoRef.current.currentTime = 0;
    videoRef.current.play();
  };

  return (
    <div className="course-details__video-player-container">
      <div className="course-details__video-player video-player ratio ratio-16x9">
        <video
          ref={videoRef}
          onEnded={onEnded}
          onPlay={onPlay}
          data-account={videoConfig["data-account"]}
          data-player={videoConfig["data-player"]}
          data-embed="default"
          data-video-id={brightcoveId}
          className="video-js"
          controls
        ></video>
        <div onClick={closePage} className="video-player__close close-icon">
          <div className="close-icon__line"></div>
          <div className="close-icon__line"></div>
        </div>
        {showNext && nextVideo && (
          <NextContent data={nextVideo} restart={restart} />
        )}
      </div>
    </div>
  )
};

export default VideoPlayer;
