import { Fragment, useState } from "react";
import routesList from "routes/routesList";
import Footer from "./Footer";
import Navigation from "./Navigation";
import GoToMedeo from "./Navigation/GoToMedeo";

const Layout = ({
  headerComponents,
  tabComponent,
  children,
  name,
  noNav,
  noFooter,
  hasContainer,
  q,
}) => {
  const isHome = window.location.pathname === routesList.home;
  const [showModal, setShowModal] = useState(false);

  return (
    <div className={`${showModal ? "modal-open" : ""}`}>
      <div className={`page-wrap ${name} ${q ? " search--open" : ""}`}>
        <header
          className={
            isHome && headerComponents?.length > 0
              ? "hero-wrapper hero-wrapper--has-overlay"
              : ""
          }
        >
          {!noNav && (
            <>
              <div className="page-wrap__overlay"></div>
              <Navigation
                transparent={isHome}
                q={q}
                setShowModal={setShowModal}
              />
            </>
          )}
          {headerComponents &&
            headerComponents.map((component, index) => (
              <Fragment key={index}>{component}</Fragment>
            ))}
        </header>
        <main className={`${name === "cgu" ? "cgu__wrapper" : "main"}`}>
          {tabComponent}
          {!hasContainer ? (
            children
          ) : (
            <div className="container">{children}</div>
          )}
        </main>
        {!noFooter && <Footer />}
        {showModal && (
          <GoToMedeo showModal={showModal} setShowModal={setShowModal} />
        )}
      </div>
    </div>
  );
};

export default Layout;
