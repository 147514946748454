import axios from "axios";
import config from "config";
import { getToken } from "utils/login";

import { logout } from "utils/login";
const url = config.app.apiUrl;

// function that create the api service
const create = (baseURL = url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const api = axios.create({
    baseURL,
    headers,
  });
  api.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      return response;
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      const { status } = error?.response ?? {};
      if (status === 401) {
        console.log("401 UNAUTHORIZED INTERCEPTOR ERROR");
        logout();
      } else {
        return Promise.reject(error);
      }
    }
  );
  api.interceptors.request.use(async (confi) => {
    const token = await getToken();
    const conf = confi;
    conf.headers.Authorization = token;
    return conf;
  });

  const getAirTherap = (params) => {
    return api.get("/aireTherapeutique", { params });
  };
  const getRecommendedCourses = (params) => {
    return api.get("/recommendations", { params });
  };
  const getLatestCourses = (params) => {
    return api.get("/contents", { params });
  };

  const getTherapAreaList = (params) => {
    return api.get("/aireTherapeutique", { params });
  };
  const getCourses = (params) => {
    return api.get("/contents", { params });
  };

  const getPlaylistContents = (id, params) => {
    return api.get(`/playlists/${id}/contents`, { params });
  };

  const getPlaylistDetail = (id, params) => {
    return api.get(`/playlists/${id}`, { params });
  };

  const getFavoriteEducationalRessources = (params) => {
    return api.get("/favorites/contents", { params });
  };

  const getFavoriteEvents = (params) => {
    return api.get("/favorites/events", { params });
  };
  const getQueryResult = (params) => {
    return api.get(`/search`, { params });
  };

  const getContentDetails = (id) => {
    return api.get(`/contents/${id}`);
  };

  const getSimilarCourses = (id) => {
    return api.get(`/contents/${id}/similars`);
  };

  const getEventDetails = (id) => {
    return api.get(`/events/${id}`);
  };

  const sendDataLogs = (data) => {
    return api.post("/logs", data);
  };

  const addFavoriteContent = (data) => {
    return api.post("/favorites/content", data);
  };
  const deleteFavoriteContent = (params) => {
    return api.delete("/favorites/content", { params });
  };
  const addFavoriteEvent = (data) => {
    return api.post("/favorites/event", data);
  };
  const deleteFavoriteEvent = (params) => {
    return api.delete("/favorites/event", { params });
  };
  const getStartedCourses = () => {
    return api.get("/history", { params: { type: "started" } });
  };

  const getTestimonials = () => {
    return api.get("/testimonials");
  };

  const getEvents = () => {
    return api.get("/events");
  };
  const getOngoingEvents = () => {
    return api.get("/events", { params: { status: 0 } });
  };
  const getUpcomingEvents = () => {
    return api.get("/events", { params: { status: 1 } });
  };
  const getPassedEvents = (params) => {
    return api.get("/events", { params: { ...params, status: 2 } });
  };

  const leaveLive = (id) => {
    return api.post(`/events/${id}/leave`);
  };
  const getSettings = () => {
    return api.get(`/settings`);
  };
  const updateProfile = (data) => {
    return api.put(`/settings/profile`, data);
  };
  const updatePassword = (data) => {
    return api.put(`/settings/password`, data);
  };
  const updatePreferences = (data) => {
    return api.put(`/settings/preferences`, {
      preferences: data,
    });
  };
  const getCurrentUser = () => {
    return api.get("/settings/current_user");
  };
  const getSpecialities = () => {
    return api.get("/referential/specialities");
  };
  const getProvinces = () => {
    return api.get("/referential/provinces");
  };
  const getExperts = (params) => {
    return api.get("/experts", { params });
  };
  const getExpertPanelDetails = (id, params) => {
    return api.get(`/experts/${id}`, { params });
  };
  return {
    getAirTherap,
    getRecommendedCourses,
    getLatestCourses,
    getTherapAreaList,
    getCourses,
    getPlaylistContents,
    getPlaylistDetail,
    getFavoriteEducationalRessources,
    getFavoriteEvents,
    getSettings,
    updateProfile,
    updatePassword,
    updatePreferences,
    getCurrentUser,
    getSpecialities,
    getProvinces,
    getQueryResult,
    getContentDetails,
    getSimilarCourses,
    getEventDetails,
    sendDataLogs,
    addFavoriteContent,
    deleteFavoriteContent,
    addFavoriteEvent,
    deleteFavoriteEvent,
    getStartedCourses,
    getTestimonials,
    getEvents,
    getOngoingEvents,
    getUpcomingEvents,
    getPassedEvents,
    leaveLive,
    getExperts,
    getExpertPanelDetails,
  };
};
const api = { create };

export default api;
