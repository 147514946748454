import { useEffect } from "react";
import { Redirect, useParams, useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";

import { eventStatus } from "data";
import {
  Layout,
  Tab,
  PdfReader,
  VideoPlayer,
  ShimmerWrapper,
  ServerError,
  InternetError,
} from "components";
import {
  ShimmerVideo,
  ShimmerDoctorInfos,
  ShimmerVideoActions,
  ShimmerCourseDetailsTab,
  ShimmerDetailContentCard,
  ShimmerEventCover,
} from "components/Shimmers";
import {
  VideoActions,
  ContentDescription,
  MoreContents,
  EventCover,
} from "./components";
import { parseDurationToSeconds, parseNameToInitials } from "utils/functions";
import config from "config";
import { pdfDurations } from "data";
import { actionNames, sources } from "data/eventsLog";
import LivePlayer from "components/LivePlayer";
import { Link } from "react-router-dom";
import { addFavoriLogs } from "utils/logs";

const ContentDetails = ({
  pageName,
  contentDetails,
  similarCourses,
  internetError,
  onFavoriteChange,
  isEvent,
  source,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { data } = contentDetails;
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, [400]);
  }, [id]);

  const {
    playlist_id: userId,
    playlist_name: name,
    user_title: userTitle,
  } = (isEvent ? data.live?.channel : data.channel) ?? {};
  const { contents: suiteContents } =
    (isEvent && data.status === eventStatus.FINISHED ? data : data.suite) ?? {};
  const isVideo = data.__t === "VideoAdvent";
  const {
    description,
    content_name: title,
    playlist_name: titleEvent,
    tags,
    testimonial,
    has_live,
    status,
    start_date,
    end_date,
  } = data;
  const initials = parseNameToInitials(name);

  const filesList = data.files?.map((file) => ({
    name: file.file_name,
    url: `${config.app.cdnUrl}/${file.file_path}`,
    type: file.file_type,
  }));

  const error = contentDetails?.error || similarCourses?.error;
  const loading =
    (contentDetails?.loading || similarCourses?.loading) && !error;
  const notFound = contentDetails?.notFound || similarCourses?.notFound;
  const tabs = loading
    ? [
        {
          id: "description",
          title: t("description"),
        },
        {
          id: "supporting-material",
          title: t("supporting material"),
        },
      ]
    : [];
  if (!isEmpty(description) && !loading) {
    tabs.push({
      id: "description",
      title: t("description"),
    });
  }
  if (data.files?.length > 0 && !loading) {
    tabs.push({
      id: "supporting-material",
      title: t("supporting material"),
    });
  }

  const closePage = () => {
    const location = history.location;
    if (location.state?.sourcePage) history.push(location.state?.sourcePage);
    else history.push("/");
  };

  const isCurrentContent = (contentId) => {
    return contentId === id;
  };

  const infoContent = {
    speciality: data.speciality?.name,
    tags: tags,
    nbViews: data.nb_views,
    channelName: data.channel?.playlist_name,
    areaName: data.therapeuticArea?.playlist_name,
    percentageViewed: parseInt(data.percentage_viewed || 0),
    promomat: data.promomat,
  };
  if (isVideo) {
    infoContent.duration = parseDurationToSeconds(data.duration);
  } else {
    infoContent.pagesNumber = data.pages_number;
  }

  const addLogs = (from) => {
    addFavoriLogs(
      id,
      name,
      source,
      from,
      data.channel.playlist_id,
      null,
      actionNames.content.contentFaved,
      infoContent
    );
  };
  return notFound ? (
    <Redirect
      to={{
        pathname: "/404",
        state: { message: t("this course does not exist") },
      }}
    />
  ) : (
    <Layout
      name={pageName}
      hasContainer
      headerComponents={[
        <ContentHeader
          loading={loading}
          error={error}
          isEvent={isEvent}
          contentDetails={contentDetails}
          closePage={closePage}
        />,
      ]}
      noNav={loading || (isVideo && !isEvent)}
    >
      {internetError ? (
        <InternetError />
      ) : error ? (
        <ServerError />
      ) : (
        <>
          <div className="d-flex flex-column flex-lg-row justify-content-lg-between mb-2 mb-md-3">
            <ShimmerWrapper
              loading={loading}
              shimmerRender={<ShimmerDoctorInfos />}
              unique
            >
              <div className="doctor-info">
                {!loading && (!isEvent || has_live) && (
                  <>
                    <div
                      className="doctor-info__avatar-box"
                      onClick={() => {
                        history.push({
                          pathname: `/expert/${userId}`,
                          state: { from: history.location.pathname },
                        });
                      }}
                    >
                      <span>{initials}</span>
                      {/* <img className="doctor-info__avatar" src="/img/doctor-avatar.png" alt="doctor" /> */}
                    </div>
                    <div className="doctor-info__details">
                      <h2 className="doctor-info__name">
                        <Link
                          to={{
                            pathname: `/expert/${userId}`,
                            state: { from: history.location.pathname },
                          }}
                        >
                          {userTitle}. {name}
                        </Link>
                      </h2>
                    </div>
                  </>
                )}
              </div>
            </ShimmerWrapper>
            <ShimmerWrapper
              loading={loading}
              shimmerRender={<ShimmerVideoActions />}
              unique
            >
              {!loading && (
                <VideoActions
                  id={isEvent ? data.playlist_id : data.content_id}
                  isFavorite={data.added_to_favorite}
                  duration={!isEvent && pdfDurations[data.interval_duration]}
                  testimonial={testimonial}
                  onFavoriteChange={onFavoriteChange}
                  type={isEvent ? "events" : "contents"}
                  addLogs={addLogs}
                />
              )}
            </ShimmerWrapper>
          </div>

          {tabs.length > 0 && (
            <Tab tabs={tabs}>
              <ShimmerWrapper
                loading={loading}
                shimmerRender={<ShimmerCourseDetailsTab />}
                unique
              >
                {!loading && (
                  <>
                    {!isEmpty(description) && (
                      <ContentDescription
                        title={title || titleEvent}
                        description={description}
                        start_date={start_date}
                        end_date={end_date}
                        isEvent={isEvent}
                        isPassed={status === eventStatus.FINISHED}
                      />
                    )}
                    {filesList?.length > 0 && (
                      <div
                        className="course-details__files tab-pane fade"
                        id="supporting-material"
                      >
                        <div className="row">
                          {filesList?.map(({ name, url, type }, i) => (
                            <div key={i} className="col-md-6 col-lg-4">
                              <a
                                href={url}
                                target="_blank"
                                className="attached-file"
                                rel="noreferrer"
                              >
                                <div className="attached-file__img-box">
                                  <img
                                    src={
                                      type === "application/pdf"
                                        ? require(`assets/img/icons/icn_pdf-file.svg`)
                                            .default
                                        : require(`assets/img/icons/icn_word.svg`)
                                            .default
                                    }
                                    alt="Fichier"
                                  />
                                </div>
                                <h4 className="attached-file__title">{name}</h4>
                              </a>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </ShimmerWrapper>
            </Tab>
          )}
          {(loading || suiteContents?.length > 0) && (
            <section className="section" id="more-scientific-courses">
              <h2 className="section__title">
                {isEvent
                  ? t("related contents")
                  : t("view the rest of this content")}
              </h2>
              <ShimmerWrapper
                loading={loading}
                shimmerRender={<ShimmerDetailContentCard />}
                ShimmerLayout={({ children }) => (
                  <div className="courses-grid">
                    <div className="row">{children}</div>
                  </div>
                )}
              >
                {suiteContents?.length > 0 && (
                  <MoreContents
                    id={id}
                    contents={suiteContents}
                    isCurrentContent={isCurrentContent}
                    onFavoriteChange={onFavoriteChange}
                    source={source}
                  />
                )}
              </ShimmerWrapper>
            </section>
          )}

          {similarCourses && (loading || similarCourses.data?.length > 0) && (
            <section className="section" id="similar-courses">
              <h2 className="section__title">
                {t("see more scientific courses")}
              </h2>
              <ShimmerWrapper
                loading={loading}
                shimmerRender={<ShimmerDetailContentCard />}
                ShimmerLayout={({ children }) => (
                  <div className="courses-grid">
                    <div className="row">{children}</div>
                  </div>
                )}
              >
                {similarCourses.data?.length > 0 && (
                  <MoreContents
                    id={id}
                    isCurrentContent={isCurrentContent}
                    contents={similarCourses.data}
                    onFavoriteChange={onFavoriteChange}
                    source={source}
                  />
                )}
              </ShimmerWrapper>
            </section>
          )}
        </>
      )}
    </Layout>
  );
};

export default ContentDetails;

const ContentCover = ({
  loading,
  error,
  contentDetails,
  isEvent,
  closePage,
}) => {
  const { data } = contentDetails;
  const isVideo = data.__t === "VideoAdvent";
  const { has_live, live, status, start_date } = data;
  if (!loading && !error) {
    if (isEvent) {
      if (has_live && status === eventStatus.IN_PROGRESS) {
        return (
          <LivePlayer
            signature={live.signature}
            user={`${live.doctorFirstName} ${live.doctorLastName}`}
            email={live.doctorEmail}
            meetingNumber={live.zoom_webinar_id}
            closePage={closePage}
          />
        );
      }
      return (
        <EventCover
          thumbnailImage={data.thumbnail_image}
          isUpcoming={status === eventStatus.UPCOMING}
          date={start_date}
        />
      );
    }
    if (isVideo) {
      return <VideoPlayer video={data} source={sources.singleContent} />;
    } else {
      return data.pdf_path ? (
        <PdfReader content={data} source={sources.singleContent} />
      ) : (
        <></>
      );
    }
  }
  return <></>;
};

const ContentHeader = ({
  loading,
  error,
  isEvent,
  contentDetails,
  closePage,
}) => (
  <ShimmerWrapper
    loading={loading}
    shimmerRender={isEvent ? <ShimmerEventCover /> : <ShimmerVideo />}
    unique
  >
    <ContentCover
      loading={loading}
      error={error}
      contentDetails={contentDetails}
      isEvent={isEvent}
      closePage={closePage}
    />
  </ShimmerWrapper>
);
