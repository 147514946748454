import React from "react";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Formik, ErrorMessage } from "formik";

import regex from "utils/regex";
const PasswordForm = ({ error, setShowModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const password = useSelector((state) => state.settings.password);
  const { updating } = password;

  return (
    <div className="settings__section">
      <h2 className="settings__section-title">
        {t("Modification du mot de passe")}
      </h2>
      <Formik
        initialValues={{
          current_password: "",
          new_password: "",
          new_password_confirm: "",
        }}
        validationSchema={Yup.object({
          current_password: Yup.string().required(t("Champ requis")),
          new_password: Yup.string()
            .matches(
              regex.password,
              t(
                "Le mot de passe doit contenir au moins 8 caractères, une lettre Maj et un chiffre"
              )
            )
            .required(t("Champ requis")),
          new_password_confirm: Yup.string().oneOf(
            [Yup.ref("new_password")],
            t("Les mots de passe ne correspondent pas")
          ),
        })}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setSubmitting(false);
          dispatch({
            type: "settings/UPDATE_PASSWORD",
            payload: { data: values, setShowModal },
          });
          resetForm({ values: "" });
        }}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="row mb-md-2">
              <div className="col-md-6">
                <div className="form-group ">
                  <input
                    type="password"
                    className={`form-control rounded-pill${
                      formik.errors.current_password &&
                      formik.touched.current_password
                        ? " is-invalid"
                        : ""
                    }`}
                    id="current_password"
                    name="current_password"
                    placeholder={t("Mot de passe actuel")}
                    disabled={updating || error}
                    {...formik.getFieldProps("current_password")}
                  />
                  <label htmlFor="current_password">
                    {t("Mot de passe actuel")}
                  </label>

                  <ErrorMessage
                    name="current_password"
                    component="div"
                    className="ml-3 invalid-feedback"
                  />
                </div>
              </div>
            </div>
            <div className="row mb-md-2">
              <div className="col-md-6">
                <div className="form-group ">
                  <input
                    type="password"
                    className={`form-control rounded-pill${
                      formik.errors.new_password && formik.touched.new_password
                        ? " is-invalid"
                        : ""
                    }`}
                    id="new_password"
                    name="new_password"
                    placeholder={t("Nouveau mot de passe")}
                    disabled={updating || error}
                    {...formik.getFieldProps("new_password")}
                  />
                  <label htmlFor="new_password">
                    {t("Nouveau mot de passe")}
                  </label>

                  <ErrorMessage
                    name="new_password"
                    component="div"
                    className="ml-3 invalid-feedback"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <input
                    type="password"
                    className={`form-control rounded-pill${
                      formik.errors.new_password_confirm &&
                      formik.touched.new_password_confirm
                        ? " is-invalid"
                        : ""
                    }`}
                    id="new_password_confirm"
                    name="new_password_confirm"
                    placeholder={t("Confirmer nouveau mot de passe")}
                    {...formik.getFieldProps("new_password_confirm")}
                    disabled={updating || error}
                  />
                  <label htmlFor="new_password_confirm">
                    {t("Confirmer nouveau mot de passe")}
                  </label>

                  <ErrorMessage
                    name="new_password_confirm"
                    component="div"
                    className="ml-3 invalid-feedback"
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="submit"
                className={`btn btn-primary cta-rounded ${
                  updating ? "btn--is-loading" : null
                }`}
                disabled={updating || error}
              >
                <span className="btn__text">{t("Modifier")}</span>
                <span className="btn__spinner">
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {t("Modifier")}
                  ...
                </span>
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PasswordForm;
