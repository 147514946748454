import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { displayNotificationToast } from "utils/utils";
import config from "config";
import routesList from "routes/routesList";
import { AddRemoveFavorite } from "components";

const EventCard = ({
  id,
  title,
  description,
  date,
  endDate,
  therapArea,
  thumbnailImage,
  isUpcoming,
  hasLive,
  inProgress,
  nbViews,
  displayType,
  isFavorite,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isEvents = window.location.pathname === routesList.events;
  const dispatch = useDispatch();

  const openEventDetails = () => {
    history.push({
      pathname: `${routesList.events}/${id}`,
      state: { sourcePage: history.location.pathname },
    });
  };

  const onSuccess = (event_id, isFavorite) => {
    const page = window.location.pathname.split("/")[1];
    const action =
      page === ""
        ? "home/UPDATE_FAVORITE_EVENT"
        : `${page}/UPDATE_FAVORITE_EVENT`;
    dispatch({
      type: action,
      payload: {
        event_id,
        added_to_favorite: isFavorite,
      },
    });
    displayNotificationToast(
      isFavorite
        ? t("event added to favorites with success")
        : t("event removed from favorites with success"),
      "success"
    );
  };
  return (
    <article
      className={`event-card ${displayType ? `event-card--${displayType}` : ""
        }`}
      onClick={openEventDetails}
    >
      <div className="event-card__thumb-container">
        <AddRemoveFavorite
          isFavorite={isFavorite}
          id={id}
          onSuccess={onSuccess}
          type="events"
        />
        <img
          className="event-card__thumb-img"
          src={`${config.app.cdnUrl}/${thumbnailImage}`}
          alt="event img"
        />
        {isUpcoming && (
          <div className="event-card__thumb-badges">
            <div className="event-card__thumb-badge badge badge--video  rounded-pill">
              {t("upcoming")}
            </div>
          </div>
        )}
        {hasLive && inProgress && (
          <div className="event-card__thumb-badges">
            {isEvents && (
              <div className="badge badge--views rounded-pill">
                <img
                  className="badge__icon"
                  src={require("assets/img/visibility-24px.svg").default}
                  alt=""
                />
                {nbViews ?? 0}
              </div>
            )}
            <div className="badge badge--live rounded-pill">
              <img
                className="badge__icon"
                src={require("assets/img/live-24px.svg").default}
                alt=""
              />
              {t("live")}
            </div>
            {!isEvents && (
              <div className="badge badge--views rounded-pill">
                <img
                  className="badge__icon"
                  src={require("assets/img/visibility-24px.svg").default}
                  alt=""
                />
                {nbViews ?? 0}
              </div>
            )}
          </div>
        )}
      </div>
      <div className="event-card__body">
        {!(isEvents && inProgress) && (
          <div className="event-card__info">
            <div className="event-card__date">
              <img src="./img/icons/icn_calendar.svg" alt="/" />
              {`${moment(date).format("MMMM DD, YYYY")}
            ${endDate ? "- " + moment(endDate).format("MMMM DD, YYYY") : ""}`}
            </div>
          </div>
        )}

        <h2 className="event-card__title" title={title}>
          {title}
        </h2>
        <p className="event-card__description">{description}</p>
        <div className="event-card__badges">
          <div
            className={`badge badge--${therapArea?.toLowerCase()} rounded-pill`}
          >
            {therapArea}
          </div>
        </div>
      </div>
    </article>
  );
};

export default EventCard;
