import { useTranslation } from "react-i18next";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import config from "config";
import routesList from "routes/routesList";
import { displayNotificationToast } from "utils/utils";
import { AddRemoveFavorite } from "components";

const MainEvent = ({
  id,
  thumbnailImage,
  date,
  endDate,
  title,
  therapeuticArea,
  description,
  isUpcoming,
  hasLive,
  inProgress,
  nbViews,
  isFavorite,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const openEventDetails = () => {
    history.push({
      pathname: `${routesList.events}/${id}`,
      state: { sourcePage: history.location.pathname },
    });
  };
  const dispatch = useDispatch();

  const onSuccess = (event_id, isFavorite) => {
    const page = window.location.pathname.split("/")[1];
    const action =
      page === ""
        ? "home/UPDATE_FAVORITE_EVENT"
        : `${page}/UPDATE_FAVORITE_EVENT`;
    dispatch({
      type: action,
      payload: {
        event_id,
        added_to_favorite: isFavorite,
      },
    });
    displayNotificationToast(
      isFavorite
        ? t("event added to favorites with success")
        : t("event removed from favorites with success"),
      "success"
    );
  };
  return (
    <article
      className="event-card event-card--premium"
      onClick={openEventDetails}
    >
      <div className="event-card__thumb-container event-card__thumb-container--premium">
        <img
          className="event-card__thumb-img event-card__thumb-img--premium"
          src={`${config.app.cdnUrl}/${thumbnailImage}`}
          alt="event img"
        />
        <div className="event-card__thumb-badges">
          {isUpcoming && (
            <div className="event-card__thumb-badge badge badge--video  rounded-pill">
              {t("upcoming")}
            </div>
          )}
          {hasLive && inProgress && (
            <div className="event-card__thumb-badges">
              <div className="badge badge--views rounded-pill">
                <img
                  className="badge__icon"
                  src={require("assets/img/visibility-24px.svg").default}
                  alt=""
                />
                {nbViews ?? 0}
              </div>
              <div className="badge badge--live rounded-pill">
                <img
                  className="badge__icon"
                  src={require("assets/img/live-24px.svg").default}
                  alt=""
                />
                {t("live")}
              </div>
            </div>
          )}
        </div>
      </div>
      <AddRemoveFavorite
        isFavorite={isFavorite}
        id={id}
        onSuccess={onSuccess}
        type="events"
      />
      <div className="event-card__body event-card__body--premium">
        <div className="event-card__info">
          <div className="event-card__date">
            <img src="./img/icons/icn_calendar.svg" alt="/" />
            {`${moment(date).format("MMMM DD, YYYY")}
            ${endDate ? "- " + moment(endDate).format("MMMM DD, YYYY") : ""}`}
          </div>
        </div>
        <h2
          className="event-card__title event-card__title--premium"
          title={title}
        >
          {title}
        </h2>
        <p className="event-card__description">{description}</p>
        <div className="event-card__badges">
          <div
            className={`badge badge--${therapeuticArea?.toLowerCase()} rounded-pill`}
          >
            {therapeuticArea}
          </div>
        </div>
      </div>
    </article>
  );
};

export default MainEvent;
