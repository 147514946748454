import videoConfig from "config/videoConfig";
import { useEffect } from "react";

const Testimonial = ({
  brightcoveId,
  testimonial,
  salutation,
  doctor,
  speciality,
}) => {
  useEffect(() => {
    const script = document.createElement("script");
    const script2 = document.createElement("script");
    script.src = `https://players.brightcove.net/${videoConfig["data-account"]}/default_default/index.min.js`;
    script2.src = `https://players.brightcove.net/${videoConfig["data-account"]}/${videoConfig["data-player"]}_default/index.min.js`;
    script.async = true;
    script2.async = true;

    document.body.appendChild(script);
    document.body.appendChild(script2);

    return () => {
      document.body.removeChild(script);
      document.body.removeChild(script2);
    };
  }, [brightcoveId]);

  return (
    <div className="swiper-slide">
      <div className="testimonial">
        <div className="testimonial__video-container video-player ratio ratio-16x9">
          <video
            data-account={videoConfig["data-account"]}
            data-player={videoConfig["data-player"]}
            data-embed="default"
            data-video-id={brightcoveId}
            className="video-js"
            controls
          ></video>
        </div>
        <div className="testimonial__info">
          <div className="testimonial__content">
            <p className="testimonial__text">{testimonial}</p>
            <div className="testimonial__expert">
              {salutation}. {doctor}
            </div>
            <div className="testimonial__speciality">{speciality}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
