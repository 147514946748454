import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import config from "config";

const Category = ({ title, id, thumbnail }) => {
  const { t } = useTranslation();

  return (
    <div className="swiper-slide">
      <article className="category">
        <Link className="category__thumbnail-container" to={`/therap/${id}`}>
          <img
            src={`${config.app.cdnUrl}/${thumbnail}`}
            alt={t("category", {
              category: title ?? "",
            })}
            className="category__thumbnail-img"
          />
          {/* <h2 className="category__title">{title}</h2> */}
        </Link>
      </article>
    </div>
  );
};

export default Category;
