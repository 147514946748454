import Swiper, { Controller, Navigation, Pagination } from "swiper";

export function initSwiper() {
  // Swiper

  // Testimonial Carousel
  function testimonialsCarousel(containerName) {
    return new Swiper(`${containerName} .swiper`, {
      modules: [Navigation, Pagination, Controller],
      allowTouchMove: false,
    });
  }

  // Testimonials Experts Swiper
  function testimonialsExpertsCarousel(containerName) {
    return new Swiper(`${containerName} .swiper`, {
      modules: [Navigation, Pagination, Controller],
      slidesPerView: 3,
      spaceBetween: 24,
      allowTouchMove: false,
      slideToClickedSlide: true,
      centeredSlides: true,
      navigation: {
        nextEl: `${containerName} .swiper-button-next`,
        prevEl: `${containerName} .swiper-button-prev`,
      },
      breakpoints: {
        576: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
        768: {
          slidesPerView: 5,
          spaceBetween: 24,
        },
        992: {
          slidesPerView: 5,
          spaceBetween: 28,
        },
        1200: {
          slidesPerView: 7,
          spaceBetween: 28,
        },
        1500: {
          slidesPerView: 7,
          spaceBetween: 28,
        },
      },
    });
  }

  const testimonials = testimonialsCarousel("#testimonials-swiper");
  const testimonialsExperts = testimonialsExpertsCarousel(
    ".testimonials-navigation"
  );

  testimonials.controller.control = testimonialsExperts;
  testimonialsExperts.controller.control = testimonials;
}
