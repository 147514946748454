import { api } from "services/apiInstances";

const initialState = {
  therapArea: {
    data: [],
    error: false,
    loading: false,
  },
};
const referential = {
  namespace: "referential",
  state: { ...initialState },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *GET_THERAP_AREA(_, { select, put, call }) {
      const state = yield select((state) => state.referential);
      const { therapArea } = state;
      yield put({
        type: "setState",
        payload: {
          therapArea: {
            ...therapArea,
            loading: true,
          },
        },
      });
      const params = {
        type: "simple",
      };
      try {
        const response = yield call(api.getTherapAreaList, params);
        if (response?.status === 200) {
          // non empty list
          yield put({
            type: "setState",
            payload: {
              therapArea: {
                ...therapArea,
                loading: false,
                data: response.data.data,
              },
            },
          });
        }
      } catch (error) {
        yield put({
          type: "setState",
          payload: {
            therapArea: {
              ...therapArea,
              loading: false,
              error: true,
            },
          },
        });
      }
    },
  },
};

export default referential;
