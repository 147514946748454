import { useTranslation } from "react-i18next";
import { useState } from "react";

import videoConfig from "config/videoConfig";

const Program = () => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  return (
    <>
      <section id="" className="section section--advent-program">
        <div className="container">
          <h2 className="section__title">{t("discover advent program")}</h2>
          <div className="advent-program">
            <div className="row">
              <div className="col-lg-6">
                <div
                  className={`${
                    loading
                      ? "shimmer-advent-program__video-box"
                      : "advent-program__video-box"
                  }`}
                >
                  <div
                    className="video-player ratio ratio-16x9"
                    style={loading ? { display: "none" } : {}}
                  >
                    <video
                      onLoadedData={() => setLoading(false)}
                      data-account={videoConfig["data-account"]}
                      data-player={videoConfig["data-player"]}
                      data-embed="default"
                      data-video-id="6317213186112"
                      className="video-js"
                      controls
                    ></video>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="advent-program__description">
                  <p
                    className={`${
                      loading
                        ? "shimmer-advent-program__description"
                        : "advent-program__description-text"
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: !loading ? t("advent program 1") : "",
                    }}
                  />
                  <p
                    className={`${
                      loading
                        ? "shimmer-advent-program__description"
                        : "advent-program__description-text"
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: !loading ? t("advent program 2") : "",
                    }}
                  />
                  {loading && (
                    <>
                      <div className="shimmer-advent-program__description"></div>
                      <div className="shimmer-advent-program__description"></div>
                      <div className="shimmer-advent-program__description"></div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Program;
