const ShimmerTestimonials = () => {
  return (
    <>
      <div className="testimonial">
        <div className="testimonial__video-container shimmer-testimonial__video-container"></div>
        <div className="shimmer-testimonial__info">
          <p className="shimmer-testimonial__text"></p>
          <p className="shimmer-testimonial__text"></p>
          <p className="shimmer-testimonial__text"></p>
          <div className="shimmer-testimonial__expert"></div>
          <div className="shimmer-testimonial__speciality"></div>
        </div>
      </div>
      <div className="shimmer-testimonial-nav">
        <div className="shimmer-testimonial-nav__expert-item"></div>
        <div className="shimmer-testimonial-nav__expert-item"></div>
        <div className="shimmer-testimonial-nav__expert-item"></div>
      </div>
    </>
  );
};

export default ShimmerTestimonials;
