const FilterItem = ({ handleFilter, namespace, name, value, isActive }) => {
  return (
    <li>
      <a
        className={`dropdown-item ${isActive ? "dropdown-item--active" : ""}`}
        href="/"
        onClick={(e) => {
          handleFilter(e, namespace, value);
        }}
      >
        {name}
      </a>
    </li>
  );
};

export default FilterItem;
