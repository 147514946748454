import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { displayNotificationToast } from "utils/utils";
import { ContentDetails } from "components";
import { eventStatus } from "data";
import { sources } from "data/eventsLog";

const EventDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();
  const { t } = useTranslation();
  const { eventDetails } = useSelector((state) => state.eventDetails);
  useEffect(() => {
    dispatch({
      type: "eventDetails/GET_EVENT_DETAILS",
      payload: {
        id,
      },
    });
  }, [id, dispatch]);

  useEffect(() => {
    return () => {
      if (
        eventDetails?.data?.has_live &&
        eventDetails?.data?.status === eventStatus.IN_PROGRESS
      ) {
        dispatch({
          type: "eventDetails/LEAVE_LIVE",
          payload: {
            id,
          },
        });
        dispatch({
          type: "eventDetails/RESET_EVENT",
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventDetails?.data?.has_live, eventDetails?.data?.status, id, dispatch]);

  const onFavoriteChange = (id, isFavorite) => {
    dispatch({
      type: "eventDetails/UPDATE_FAVORITE",
      payload: {
        id,
        added_to_favorite: isFavorite,
      },
    });
    displayNotificationToast(
      isFavorite
        ? t("event added to favorites with success")
        : t("event removed from favorites with success"),
      "success"
    );
  };

  return (
    <ContentDetails
      contentDetails={eventDetails}
      internetError={eventDetails.internetError}
      isEvent
      pageName="upcoming-events"
      onFavoriteChange={onFavoriteChange}
      source={sources.singleEvent}
    />
  );
};

export default EventDetails;
