/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import routesList from "routes/routesList";

const TabSearchItem = ({ namespace, title, isActive, handleSelected }) => {
  const [locationKeys, setLocationKeys] = useState([]);
  let history = useHistory();
  useEffect(() => {
    return history.listen(() => {
      if (history.action === "PUSH") {
      }

      if (history.action === "POP") {
        setLocationKeys(([_, ...keys]) => keys);
        if (isActive) history.replace(routesList.home);
      }
    });
  }, [locationKeys]);
  return (
    <li className={`nav-item ${isActive ? "active" : ""}`}>
      <button
        className={`nav-link ${isActive ? "active" : ""}`}
        id={namespace + "-tab"}
        data-bs-toggle="tab"
        data-bs-target={"#" + namespace}
        type="button"
        role="tab"
        aria-controls={namespace}
        aria-selected="true"
        onClick={() => handleSelected(namespace)}
      >
        {title}
      </button>
    </li>
  );
};

export default TabSearchItem;
