import { useTranslation } from "react-i18next";

import Category from "./components/Category";
import { ShimmerWrapper } from "components";
import { ShimmerTherapArea } from "components/Shimmers";
const Categories = ({ therapeuticAreas }) => {
  const { t } = useTranslation();

  return (
    <section id="categories" className="section section--therapeutic-areas">
      <div className="container">
        {(therapeuticAreas?.loading ||
          therapeuticAreas?.error ||
          therapeuticAreas?.data?.length > 0) && (
          <h2 className="section__title">{t("therapeutic areas")}</h2>
        )}
        <ShimmerWrapper
          loading={therapeuticAreas?.loading || therapeuticAreas?.error}
          shimmerRender={<ShimmerTherapArea />}
          ShimmerLayout={({ children }) => (
            <div className="courses-grid">
              <div className="row">{children}</div>
            </div>
          )}
        >
          {!therapeuticAreas?.loading && (
            <div className="categories-row">
              <div className="categories-row__inner">
                <div className="swiper">
                  <div className="swiper-wrapper">
                    {therapeuticAreas?.data.map(
                      ({ playlist_id, playlist_name, thumbnail_id }) => (
                        <Category
                          key={playlist_id}
                          title={playlist_name}
                          thumbnail={thumbnail_id}
                          id={playlist_id}
                        />
                      )
                    )}
                  </div>
                </div>
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
                {/* <!-- <div className="swiper-button-next category__swiper-button"></div>
                    <div className="swiper-button-prev category__swiper-button"></div> --> */}
              </div>
            </div>
          )}
        </ShimmerWrapper>
      </div>
    </section>
  );
};

export default Categories;
