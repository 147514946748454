import { useTranslation } from "react-i18next";

const ApplyChanges = ({ showModal, setShowModal }) => {
  const { t } = useTranslation();

  return (
    <>
      <div
        className={`${showModal ? "modal fade show" : "modal fade"}`}
        id="gomedeo"
        style={{ display: "block" }}
        tabIndex="-1"
        aria-labelledby="gomedeoLabel"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div
                className="close-icon"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <div className="close-icon__line"></div>
                <div className="close-icon__line"></div>
              </div>
            </div>
            <div className="modal-body">
              {t("Les changements seront aussi appliqués sur Medeo.")}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setShowModal(false);
                }}
              >
                {t("ok")}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`${
          showModal ? "modal-backdrop fade show" : "modal-backdrop fade"
        }`}
      ></div>
    </>
  );
};

export default ApplyChanges;
