import { Route, Switch } from "react-router-dom";
import {
  Home,
  Panel,
  ExpertPanelDetails,
  ExpertProfile,
  Courses,
  CourseDetails,
  EventDetails,
  Search,
  TherapArea,
  Favorites,
  Settings,
  Events,
  InternalServer,
  NotFound,
  PrivacyPolicy,
  TermsOfUse,
  Contact,
} from "pages";
import { AuthLayout } from "components";

import routesList from "./routesList";

const {
  home,
  expertsPanel,
  expertPanelDetails,
  expertProfile,
  courses,
  search,
  therapArea,
  favorites,
  settings,
  events,
  internalServer,
  notFound,
  privacyPolicy,
  termsOfUse,
  contact,
} = routesList;

const Routes = () => (
  <Switch>
    <Route exact path={privacyPolicy} component={PrivacyPolicy} />
    <Route exact path={termsOfUse} component={TermsOfUse} />
    <Route exact path={contact} component={Contact} />
    <AuthLayout>
      <Switch>
        <Route exact path={home} component={Home} />
        <Route exact path={expertsPanel} component={Panel} />
        <Route
          exact
          path={`${expertPanelDetails}/:id`}
          component={ExpertPanelDetails}
        />
        <Route exact path={`${expertProfile}/:id`} component={ExpertProfile} />
        <Route exact path={courses} component={Courses} />
        <Route exact path={`${courses}/:id`} component={CourseDetails} />
        <Route exact path={`${events}/:id`} component={EventDetails} />
        <Route exact path={search} component={Search} />
        <Route exact path={`${therapArea}/:id`} component={TherapArea} />
        <Route exact path={favorites} component={Favorites} />
        <Route exact path={settings} component={Settings} />
        <Route exact path={events} component={Events} />
        <Route exact path={internalServer} component={InternalServer} />
        <Route exact path={notFound} component={NotFound} />
        <Route component={NotFound} />
      </Switch>
    </AuthLayout>
  </Switch>

);

export default Routes;
