import TabSearchItem from "./TabSearchItem";

const TabSearch = ({ tabs, handleSelected, selectedItem }) => {
  return (
    <div className="tab tab--search">
      <div className="container">
        <div className="row">
          <div className="offset-xl-1 col-xl-10">
            <div className="tab__inner">
              <ul
                className="tab-custom tab-custom--dark tab-custom--search nav nav-tabs"
                id="myTab"
                role="tablist"
              >
                {tabs.map(({ namespace, title }, index) => (
                  <TabSearchItem
                    key={index}
                    namespace={namespace}
                    title={title}
                    handleSelected={handleSelected}
                    isActive={selectedItem === namespace}
                  />
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabSearch;
