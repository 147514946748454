import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Layout, ShimmerWrapper } from "components";
import DoctorCover from "./components/DoctorCover";
import Biography from "./components/Biography";

import { ShimmerDoctorCover } from "components/Shimmers";

const ExpertPanelDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { id } = useParams();

  const { expertPanelDetails, internetError } = useSelector(
    (state) => state.expertPanelDetails
  );

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, [400]);
    dispatch({
      type: "expertPanelDetails/GET_EXPERT_PANEL_DETAILS",
      payload: { id },
    });
    return () => {
      dispatch({ type: "expertPanelDetails/RESET" });
    };
  }, [dispatch, id]);

  const {
    expert_name: name,
    expert_titles: title,
    expert_bio: bio,
    expert_thumbnail: photo,
    expert_short_desc: description,
    expert_country: country,
  } = expertPanelDetails?.data;
  const error = expertPanelDetails?.error;
  const loading = expertPanelDetails?.loading && !error;
  const notFound = expertPanelDetails?.notFound;
  return notFound ? (
    <Redirect
      to={{
        pathname: "/404",
        state: { message: t("this expert profile does not exist") },
      }}
    />
  ) : (
    <Layout
      name="expert-profile"
      hasContainer
      headerComponents={[
        <ShimmerWrapper
          loading={loading}
          shimmerRender={<ShimmerDoctorCover />}
          unique
        >
          {!loading && !error && !internetError && (
            <DoctorCover
              name={name}
              title={title}
              photo={photo?.file_path}
              description={description}
              country={country?.country_name}
            />
          )}
        </ShimmerWrapper>,
      ]}
    >
      <Biography biography={bio} />
    </Layout>
  );
};

export default ExpertPanelDetails;
