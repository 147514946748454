/* eslint-disable import/no-anonymous-default-export */
import { t } from "i18next";

import { api } from "services/apiInstances";
import parameters from "config/parameters";
import { displayNotificationToast } from "utils/utils";
const FavoriteErrorHandler = (e) => {
  displayNotificationToast(t("error message: operation failed"), "danger");
};

const initialState = {
  contents: {
    data: [],
    error: false,
    internetError: false,
    loading: true,
    page: 1,
    maxPage: 1,
  },
  events: {
    data: [],
    error: false,
    internetError: false,
    loading: true,
    page: 1,
    maxPage: 1,
  },
};
const favorites = {
  namespace: "favorites",
  state: { ...initialState },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    //<<<<<<<<<<<<<<<<<<FAV EVENTS>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    *GET_FAVORITE_EVENTS({ payload }, { select, put, call }) {
      const state = yield select((state) => state.favorites);
      const { events } = state;
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            events: { ...events, internetError: true },
          },
        });
      } else {
        yield put({
          type: "setState",
          payload: {
            events: {
              ...(payload.loadMore ? events : initialState.events),
              loading: true,
              error: false,
            },
          },
        });

        const params = {
          pageNb: payload.loadMore ? events.page : 1,
          pageSize: parameters.pageSize,
          therapeutic: payload?.therapeutic,
        };

        const action = api.getFavoriteEvents;
        try {
          const response = yield call(action, params);
          if (response?.status === 200) {
            const result = payload.loadMore
              ? {
                  page: events.page + 1,
                  data: [...events.data, ...response.data.data],
                }
              : {
                  page: 2,
                  data: response.data.data,
                };

            yield put({
              type: "setState",
              payload: {
                events: {
                  loading: false,
                  error: false,
                  maxPage: response.data.max_pages,
                  ...result,
                },
              },
            });
          }
        } catch (error) {
          yield put({
            type: "setState",
            payload: {
              events: { ...events, loading: false, error: true },
            },
          });
        }
      }
    },
    //<<<<<<<<<<<<<<<<<<<<FAV EVENTS END/FAV CONTENT START>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    *GET_FAVORITE_CONTENTS({ payload }, { select, put, call }) {
      const state = yield select((state) => state.favorites);
      const { contents } = state;
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            contents: {
              ...contents,
              internetError: true,
            },
          },
        });
      } else {
        yield put({
          type: "setState",
          payload: {
            contents: {
              ...(payload.loadMore ? contents : initialState.contents),
              loading: true,
              error: false,
            },
          },
        });

        const params = {
          pageNb: payload.loadMore ? contents.page : 1,
          pageSize: parameters.pageSize,
          format: payload?.format === "" ? null : payload?.format,
          duration: payload?.duration === "" ? null : payload.duration,
          therapeutic: payload?.therapeutic,
        };

        const action = api.getFavoriteEducationalRessources;
        try {
          const response = yield call(action, params);
          if (response?.status === 200) {
            const result = payload.loadMore
              ? {
                  page: contents.page + 1,
                  data: [...contents.data, ...response.data.data],
                }
              : {
                  page: 2,
                  data: response.data.data,
                };
            yield put({
              type: "setState",
              payload: {
                contents: {
                  loading: false,
                  error: false,
                  maxPage: response.data.max_pages,
                  ...result,
                },
              },
            });
          }
        } catch (error) {
          yield put({
            type: "setState",
            payload: {
              contents: {
                ...contents,
                loading: false,
                error: true,
              },
            },
          });
        }
      }
    },
    //<<<<<<<<<<<<<<<<<FAV CONTENTS END>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    *ADD_FAVORITE({ payload, onSuccess, resetLoading }, { call }) {
      const { id, type } = payload;
      const params =
        type === "contents"
          ? {
              content_id: id,
            }
          : { event_id: id };

      try {
        const response = yield call(
          type === "contents" ? api.addFavoriteContent : api.addFavoriteEvent,
          params
        );
        if (response?.status === 201) {
          onSuccess(id, true);
        }
      } catch (error) {
        FavoriteErrorHandler(error);
      }
      resetLoading();
    },
    *REMOVE_FAVORITE(
      { payload, onSuccess, resetLoading },
      { select, put, call }
    ) {
      const { id, type } = payload;
      const { contents, events } = yield select((state) => state.favorites);
      const params =
        type === "contents"
          ? {
              content_id: id,
            }
          : { event_id: id };

      try {
        const response = yield call(
          type === "contents"
            ? api.deleteFavoriteContent
            : api.deleteFavoriteEvent,
          params
        );
        if (response?.status === 200) {
          onSuccess(id, false);
          yield put(
            type === "contents"
              ? {
                  type: "setState",
                  payload: {
                    contents: {
                      ...contents,
                      data: contents.data.filter(
                        (content) => content.content_id !== id
                      ),
                    },
                  },
                }
              : {
                  type: "setState",
                  payload: {
                    events: {
                      ...events,
                      data: events.data.filter(
                        (event) => event.playlist_id !== id
                      ),
                    },
                  },
                }
          );
        }
      } catch (error) {
        console.log(error);
        FavoriteErrorHandler(error);
      }
      resetLoading();
    },
    *RESET(_, { put }) {
      yield put({
        type: "resetState",
      });
    },
  },
};

export default favorites;
