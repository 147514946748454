/* eslint-disable import/no-anonymous-default-export */
import { api } from "services/apiInstances";
import parameters from "config/parameters";

const initialState = {
  courses: {
    data: [],
    error: false,
    internetError: false,
    loading: true,
    page: 1,
    maxPage: 0,
    notFound: false,
    nb_results: 0,
  },
  experts: {
    data: [],
    error: false,
    internetError: false,
    loading: true,
    page: 1,
    maxPage: 0,
    notFound: false,
    nb_results: 0,
  },
  therapeuticAreas: {
    data: [],
    error: false,
    internetError: false,
    loading: true,
    page: 1,
    maxPage: 0,
    notFound: false,
    nb_results: 0,
  },
  events: {
    data: [],
    error: false,
    internetError: false,
    loading: true,
    page: 1,
    maxPage: 0,
    notFound: false,
    nb_results: 0,
  },
};

const search = {
  namespace: "search",
  state: { ...initialState },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
    resetState() {
      return { ...initialState };
    },
    resetSpecificState(state, { payload: { stateToReset } }) {
      return { ...state, [stateToReset]: { ...initialState[stateToReset] } };
    },
  },
  effects: {
    *GET_RESULT_COURSES({ payload }, { select, call, put }) {
      const { q, sort, type, therapeutic, ctype, duration } = payload;
      const search = yield select((state) => state.search);
      const { courses } = search;
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            courses: {
              ...courses,
              internetError: true,
            },
          },
        });
      } else {
        yield put({
          type: "setState",
          payload: {
            courses: {
              ...courses,
              data: courses.data,
              loading: true,
              error: false,
            },
          },
        });

        const params = {
          query: q,
          type,
          therapeutic,
          ctype,
          duration,
          pageNb: courses.page,
          pageSize: parameters.pageSize,
        };
        if (sort) params.sort = sort;

        try {
          const response = yield call(api.getQueryResult, params);
          if (response && response.status === 200) {
            if (response.data.data.length) {
              yield put({
                type: "setState",
                payload: {
                  courses: {
                    ...initialState.courses,
                    loading: false,
                    data: [...courses.data, ...response.data.data],
                    maxPage: response.data.max_pages,
                    page: courses.page + 1,
                    nb_results: response.data.nb_contents,
                  },
                },
              });
            } else {
              yield put({
                type: "setState",
                payload: {
                  courses: {
                    ...initialState.courses,
                    loading: false,
                    notFound: true,
                  },
                },
              });
            }
          }
        } catch (e) {
          if (e.response) {
            const { status } = e.response;
            switch (status) {
              case 404:
                yield put({
                  type: "setState",
                  payload: {
                    courses: {
                      ...initialState.courses,
                      loading: false,
                      notFound: true,
                    },
                  },
                });
                break;

              default:
                yield put({
                  type: "setState",
                  payload: {
                    courses: {
                      ...initialState.courses,
                      loading: false,
                      error: true,
                    },
                  },
                });
                break;
            }
          } else {
            yield put({
              type: "setState",
              payload: {
                courses: {
                  ...initialState.courses,
                  loading: false,
                  error: true,
                },
              },
            });
          }
        }
      }
    },
    *GET_RESULT_EXPERTS({ payload }, { select, call, put }) {
      const { q, type } = payload;
      const search = yield select((state) => state.search);
      const { experts } = search;
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            experts: {
              ...experts,
              internetError: true,
            },
          },
        });
      } else {
        yield put({
          type: "setState",
          payload: {
            experts: {
              ...experts,
              loading: true,
              error: false,
            },
          },
        });
        const params = {
          query: q,
          type,
          pageNb: experts.page,
          pageSize: parameters.pageSize,
        };

        try {
          const response = yield call(api.getQueryResult, params);
          if (response.status === 200) {
            if (response.data.data.length) {
              yield put({
                type: "setState",
                payload: {
                  experts: {
                    ...initialState.experts,
                    loading: false,
                    data: [...experts.data, ...response.data.data],
                    maxPage: response.data.max_pages,
                    page: experts.page + 1,
                    nb_results: response.data.nb_doctors,
                  },
                },
              });
            } else {
              yield put({
                type: "setState",
                payload: {
                  experts: {
                    ...initialState.experts,
                    loading: false,
                    notFound: true,
                  },
                },
              });
            }
          }
        } catch (e) {
          if (e.response) {
            const { status } = e.response;
            switch (status) {
              case 404:
                yield put({
                  type: "setState",
                  payload: {
                    experts: {
                      ...initialState.experts,
                      loading: false,
                      notFound: true,
                    },
                  },
                });
                break;

              default:
                yield put({
                  type: "setState",
                  payload: {
                    experts: {
                      ...initialState.experts,
                      loading: false,
                      error: true,
                    },
                  },
                });
                break;
            }
          } else {
            yield put({
              type: "setState",
              payload: {
                experts: {
                  ...initialState.experts,
                  loading: false,
                  error: true,
                },
              },
            });
          }
        }
      }
    },
    *GET_RESULT_THERAPEUTICAREAS({ payload }, { select, call, put }) {
      const { q, type } = payload;
      const search = yield select((state) => state.search);
      const { therapeuticAreas } = search;
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            therapeuticAreas: {
              ...therapeuticAreas,
              internetError: true,
            },
          },
        });
      } else {
        yield put({
          type: "setState",
          payload: {
            therapeuticAreas: {
              ...therapeuticAreas,
              loading: true,
              error: false,
            },
          },
        });
        const params = {
          query: q,
          type,
          pageNb: therapeuticAreas.page,
          pageSize: parameters.pageSize,
        };

        try {
          const response = yield call(api.getQueryResult, params);
          if (response.status === 200) {
            if (response.data.data.length) {
              yield put({
                type: "setState",
                payload: {
                  therapeuticAreas: {
                    ...initialState.therapeuticAreas,
                    loading: false,
                    data: [...therapeuticAreas.data, ...response.data.data],
                    maxPage: response.data.max_pages,
                    page: therapeuticAreas.page + 1,
                    nb_results: response.data.nb_therapeuticAreas,
                  },
                },
              });
            } else {
              yield put({
                type: "setState",
                payload: {
                  therapeuticAreas: {
                    ...initialState.therapeuticAreas,
                    loading: false,
                    notFound: true,
                  },
                },
              });
            }
          }
        } catch (e) {
          if (e.response) {
            const { status } = e.response;
            switch (status) {
              case 404:
                yield put({
                  type: "setState",
                  payload: {
                    therapeuticAreas: {
                      ...initialState.therapeuticAreas,
                      loading: false,
                      notFound: true,
                    },
                  },
                });
                break;

              default:
                yield put({
                  type: "setState",
                  payload: {
                    therapeuticAreas: {
                      ...initialState.therapeuticAreas,
                      loading: false,
                      error: true,
                    },
                  },
                });
                break;
            }
          } else {
            yield put({
              type: "setState",
              payload: {
                therapeuticAreas: {
                  ...initialState.therapeuticAreas,
                  loading: false,
                  error: true,
                },
              },
            });
          }
        }
      }
    },
    *GET_RESULT_EVENTS({ payload }, { select, call, put }) {
      const { q, type, therapeutic } = payload;
      const search = yield select((state) => state.search);
      const { events } = search;
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            events: {
              ...events,
              internetError: true,
            },
          },
        });
      } else {
        yield put({
          type: "setState",
          payload: {
            events: {
              ...events,
              loading: true,
              error: false,
            },
          },
        });
        const params = {
          query: q,
          type,
          therapeutic,
          pageNb: events.page,
          pageSize: parameters.pageSize,
        };

        try {
          const response = yield call(api.getQueryResult, params);
          if (response.status === 200) {
            if (response && response.data.data.length) {
              yield put({
                type: "setState",
                payload: {
                  events: {
                    ...initialState.events,
                    loading: false,
                    data: [...events.data, ...response.data.data],
                    maxPage: response.data.max_pages,
                    page: events.page + 1,
                    nb_results: response.data.nb_events,
                  },
                },
              });
            } else {
              yield put({
                type: "setState",
                payload: {
                  events: {
                    ...initialState.events,
                    loading: false,
                    notFound: true,
                  },
                },
              });
            }
          }
        } catch (e) {
          if (e.response) {
            const { status } = e.response;
            switch (status) {
              case 404:
                yield put({
                  type: "setState",
                  payload: {
                    events: {
                      ...initialState.events,
                      loading: false,
                      notFound: true,
                    },
                  },
                });
                break;

              default:
                yield put({
                  type: "setState",
                  payload: {
                    events: {
                      ...initialState.events,
                      loading: false,
                      error: true,
                    },
                  },
                });
                break;
            }
          } else {
            yield put({
              type: "setState",
              payload: {
                events: {
                  ...initialState.events,
                  loading: false,
                  error: true,
                },
              },
            });
          }
        }
      }
    },
    *UPDATE_FAVORITE({ payload }, { select, put }) {
      const state = yield select((state) => state.search);
      const { courses } = state;
      const { content_id, added_to_favorite } = payload;

      yield put({
        type: "setState",
        payload: {
          courses: {
            loading: false,
            error: false,
            data: courses.data.map((content) => {
              if (content.content_id === content_id)
                return { ...content, added_to_favorite };
              return content;
            }),
          },
        },
      });
    },
    *UPDATE_FAVORITE_EVENT({ payload }, { select, put }) {
      const state = yield select((state) => state.search);
      const { events } = state;
      const { event_id, added_to_favorite } = payload;

      yield put({
        type: "setState",
        payload: {
          events: {
            loading: false,
            error: false,
            data: events.data.map((event) => {
              if (event.playlist_id === event_id)
                return { ...event, added_to_favorite };
              return event;
            }),
          },
        },
      });
    },
    *RESET(_, { put }) {
      yield put({
        type: "resetState",
      });
    },
  },
};
export default search;
