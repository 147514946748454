import { api } from "services/apiInstances";
import parameters from "config/parameters";
const initialState = {
  experts: {
    data: [],
    error: false,
    internetError: false,
    loading: true,
    page: 1,
    maxPage: 1,
  },
};
const experts = {
  namespace: "experts",
  state: { ...initialState },
  reducers: {
    setState(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *GET_EXPERTS({ payload }, { select, put, call }) {
      const state = yield select((state) => state.experts);
      const { experts } = state;
      if (!window.navigator.onLine) {
        yield put({
          type: "setState",
          payload: {
            experts: {
              ...experts,
              internetError: true,
            },
          },
        });
      } else {
        yield put({
          type: "setState",
          payload: {
            experts: {
              ...(payload.loadMore ? experts : initialState.experts),
              loading: true,
              error: false,
            },
          },
        });

        const params = {
          pageNb: payload.loadMore ? experts.page : 1,
          pageSize: parameters.pageSize,
          type: payload?.type,
          therapeutic: payload?.therapeutic,
        };

        try {
          const response = yield call(api.getExperts, params);

          if (response?.status === 200) {
            const result = payload.loadMore
              ? {
                  page: experts.page + 1,
                  data: [...experts.data, ...response.data.data],
                }
              : {
                  page: 2,
                  data: response.data.data,
                };
            yield put({
              type: "setState",
              payload: {
                experts: {
                  loading: false,
                  error: false,
                  maxPage: response.data.max_pages,
                  ...result,
                },
              },
            });
          }
        } catch (error) {
          console.log(error);
          yield put({
            type: "setState",
            payload: {
              experts: {
                ...experts,
                loading: false,
                loadingMore: false,
                error: true,
              },
            },
          });
        }
      }
    },
    *RESET(_, { put }) {
      yield put({
        type: "resetState",
      });
    },
  },
};

export default experts;
