import routesList from "routes/routesList";

const ShimmerContentCard = () => {
  const isSearch = window.location.pathname === routesList.search;

  return (
    <div
      className={`${
        isSearch ? " course-card course-card--search" : "shimmer-course-card"
      }`}
    >
      <div className="course-card__thumb-container">
        <div className="shimmer-course-card__thumb"></div>
      </div>
      <div className="course-card__body">
        <div
          className={`shimmer-course-card__title ${
            isSearch ? " shimmer-course-card__title--search" : ""
          }`}
        ></div>
        <div
          className={`shimmer-course-card__desc${
            isSearch ? " shimmer-course-card__desc--search" : ""
          }`}
        ></div>
        <div
          className={`shimmer-course-card__desc${
            isSearch ? " shimmer-course-card__desc--search" : ""
          }`}
        ></div>
        <div
          className={`shimmer-course-card__badges${
            isSearch ? " shimmer-course-card__badges--search" : ""
          }`}
        >
          <div className="shimmer-badge rounded-pill"></div>
          <div className="shimmer-badge rounded-pill"></div>
        </div>
      </div>
    </div>
  );
};

export default ShimmerContentCard;
