// import { useTranslation } from "react-i18next";

import { AddRemoveFavorite } from "components";

const VideoActions = ({
  id,
  isFavorite,
  duration,
  testimonial,
  onFavoriteChange,
  type,
  addLogs,
}) => {
  // const { t } = useTranslation();
  return (
    <div className="video-actions">
      <div className="video-actions__item">
        <AddRemoveFavorite
          isFavorite={isFavorite}
          id={id}
          onSuccess={onFavoriteChange}
          noBackground
          type={type}
          addLogs={addLogs}
        />
      </div>
      {duration && (
        <div className="video-actions__item video-actions__item--other-icons">
          <img
            src="/img/icons/icn_time.svg"
            alt=""
            className="video-actions__icon"
          />
          <span className="">{duration}</span>
        </div>
      )}
      {/* {testimonial && (
        <a
          href={`/testimonial/${testimonial}`}
          style={{ textDecoration: "none" }}
        >
          <div className="video-actions__item video-actions__item--other-icons">
            <img
              src="/img/icons/icn_play_button.svg"
              alt=""
              className="video-actions__icon"
            />
            <span className="">{t("expert opinion")}</span>
          </div>
        </a>
      )} */}
    </div>
  );
};

export default VideoActions;
