import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import routesList from "routes/routesList";
import { parseNameToInitials } from "utils/functions";

const ExpertCard = ({ id, name, speciality }) => {
  const initials = parseNameToInitials(name);
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <article
      className="expert-item expert-item--search"
      onClick={() => {
        history.push(`${routesList.expertProfile}/${id}`);
      }}
    >
      <div className="doctor-info__avatar-box">
        <span>{initials}</span>
      </div>
      <div className="expert-item__content mt-4">
        <h5 className="expert-item__name">
          {t("dr - ")}
          {name}
        </h5>
        <div className="expert-item__info">
          <p className="expert-item__degree m-0">{speciality}</p>
        </div>
      </div>
    </article>
  );
};

export default ExpertCard;
