import { useTranslation } from "react-i18next";

import Testimonial from "./components/Testimonial";
import Navigation from "./components/Navigation";
import { ShimmerWrapper } from "components";
import { ShimmerTestimonials } from "components/Shimmers";

const Testimonials = ({ testimonials }) => {
  const { t } = useTranslation();
  if (
    !testimonials?.loading &&
    !testimonials?.error &&
    testimonials?.data?.length === 0
  ) {
    return <></>;
  }
  return (
    <section id="testimonials" className="section section--testimonials">
      {(testimonials?.loading ||
        testimonials?.error ||
        testimonials?.data?.length > 0) && (
        <h2 className="section__title">
          {t("hear from members of the ADVENT Global expert panel and faculty")}
        </h2>
      )}
      <div className="container">
        <ShimmerWrapper
          loading={testimonials?.loading || testimonials?.error}
          shimmerRender={<ShimmerTestimonials />}
          unique
        >
          {testimonials?.data?.length > 0 && !testimonials?.loading && (
            <>
              <div id="testimonials-swiper">
                <div className="swiper">
                  <div className="swiper-wrapper">
                    {testimonials?.data?.map(
                      (
                        { brightcove_id, testimonial_description, doctor },
                        index
                      ) => (
                        <Testimonial
                          key={index}
                          brightcoveId={brightcove_id}
                          testimonial={testimonial_description}
                          salutation={doctor?.salutation}
                          doctor={`${doctor?.first_name} ${doctor?.last_name}`}
                          speciality={doctor?.speciality?.name}
                        />
                      )
                    )}
                  </div>
                </div>
              </div>
              <Navigation data={testimonials?.data} />
            </>
          )}
        </ShimmerWrapper>
      </div>
    </section>
  );
};

export default Testimonials;
